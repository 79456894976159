import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import api from "../../utils/apiClient";
import CommonConfig from "../../utils/constant";
import APIConstant from "../../utils/pathConstants";
import Toast from "../Shared/Toast/Toast";
import Loader from "../Shared/Loader/Loader";
import { Button } from "primereact/button";

const AccessMaster = (props) => {
  const [securityUserID, setSecurityUserID] = useState(null);
  const [userID, setUserID] = useState(null);

  const [accessMasterID, setAccessMasterID] = useState(null);

  const [addAccessMaster, setAddAccessMaster] = useState(false);

  const [serviceName, setServiceName] = useState("");
  const [serviceNameErr, setServiceNameErr] = useState(false);
  const [serviceNameErrText, setServiceNameErrText] = useState("");
  const [isDeleteAccessMaster, setIsDeleteAccessMaster] = useState(false);
  const [viewAccessMaster, setViewAccessMaster] = useState(false);
  const [viewServiceData, setViewServiceData] = useState({});
  const [servicesAccessList, setServicesAccessList] = useState([]);

  useEffect(() => {
    const loginData = CommonConfig.loginData();
    // setSecurityUserID(props?.location?.state?.UserId);
    setSecurityUserID(loginData?.UserId);
    GetServicesAccess(props?.location?.state?.ServiceId);
  }, []);

  const resetScreen = async () => {
    // setSecurityUserID(null);
    setAddAccessMaster(false);
    setIsDeleteAccessMaster(false);

    setAccessMasterID("");

    setServiceName("");
    setServiceNameErr(false);
    setServiceNameErrText("");
    // props.navigate("/lead");
  };

  const validate = () => {
    let formIsValid = true;
    if (CommonConfig.isEmpty(serviceName)) {
      formIsValid = false;
      setServiceNameErr(true);
      setServiceNameErrText("Service name is required");
    }

    return formIsValid;
  };

  const handleChange = (e, type) => {
    if (type === "serviceName") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setServiceName("");
        setServiceNameErr(true);
        setServiceNameErrText("Service name is required");
      } else {
        setServiceName(e.target.value);
        setServiceNameErr(false);
        setServiceNameErrText("");
      }
    }
  };

  const AddUpdateAdminServiceAccess = async (e) => {
    if (validate(e)) {
      try {
        let data = {
          ServiceId: props?.location?.state?.ServiceId,
          AccessMasterId: accessMasterID,
          ServiceName: serviceName,
        };
        Loader.show();
        await api
          .post(APIConstant.path.AddUpdateAdminServiceAccess, data)
          .then((response) => {
            let res = response;

            if (res.success) {
              Toast.success({ message: res.message });
              resetScreen();
              GetServicesAccess(props?.location?.state?.ServiceId);
              Loader.hide();
            } else {
              Toast.error({ message: res.message });
              Loader.hide();
            }
          })
          .catch((err) => {});
      } catch (err) {
        console.log(err);
      }
    }
  };

  const GetServicesAccess = async (ID) => {
    try {
      let data = {
        ServiceId: ID,
      };
      await api
        .post(APIConstant.path.GetAdminServicesAccess, data)
        .then(async (response) => {
          let res = response;
          if (res.success) {
            setServicesAccessList(res.data);
            Loader.hide();
          }
        })
        .catch((err) => {
          Loader.hide();
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const DeleteAccessMaster = async () => {
    try {
      Loader.show();
      let data = {
        UserID: securityUserID,
        ServiceId: props?.location?.state?.ServiceId,
        AccessMasterId: accessMasterID,
      };
      await api
        .post(APIConstant.path.DeleteAdminServiceAccess, data)
        .then(async (res) => {
          if (res.success) {
            handleClose("DeleteAccessMaster");
            GetServicesAccess(props?.location?.state?.ServiceId);
            Loader.hide();
          } else {
            Loader.hide();
          }
        })
        .catch((err) => {
          Loader.hide();
          Toast.error({ message: err });
        });
    } catch (err) {
      Loader.hide();
      Toast.error({ message: err });
    }
  };

  const handleOpen = (item, type) => {
    if (type === "AddAccessMaster") {
      setAddAccessMaster(true);
    } else if (type === "EditAccessMaster") {
      setAccessMasterID(item._id);
      setServiceName(item.ServiceName);
      setAddAccessMaster(true);
    } else if (type === "DeleteAccessMaster") {
      setAccessMasterID(item._id);
      setIsDeleteAccessMaster(true);
    } else if (type === "ViewAccessMaster") {
      setViewServiceData(item);
      setViewAccessMaster(item);
    }
  };

  const handleClose = async (type) => {
    if (type === "AddAccessMaster") {
      setAddAccessMaster(false);
    } else if (type === "EditAccessMaster") {
      setAddAccessMaster(false);
    } else if (type === "ViewAccessMaster") {
      setViewAccessMaster(false);
      setViewServiceData({});
    } else if (type === "DeleteAccessMaster") {
      setIsDeleteAccessMaster(false);
    }
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="table-icon">
        <Button
          className=""
          onClick={() => handleOpen(rowData, "ViewAccessMaster")}
          tooltip={"View"}
          tooltipOptions={{ className: "bluegray-tooltip", position: "top" }}
        >
          <i className="icon-view"></i>
        </Button>

        <Button
          className=""
          onClick={() => handleOpen(rowData, "EditAccessMaster")}
          tooltip={"Edit"}
          tooltipOptions={{ className: "bluegray-tooltip", position: "top" }}
        >
          <i className="icon-edit"></i>
        </Button>

        <Button
          className=""
          onClick={() => handleOpen(rowData, "DeleteAccessMaster")}
          tooltip={"Delete"}
          tooltipOptions={{ className: "bluegray-tooltip", position: "top" }}
        >
          <i className="icon-delete"></i>
        </Button>
      </div>
    );
  };

  const actionBodyTemplateSrNo = (data, props) => {
    return props.rowIndex + 1;
  };

  return (
    <div className="user-container">
      <div className="card-header">
        <div className="card-title p-d-lg-flex p-ai-center p-w-100">
          <div className="card-actions p-ml-2">
            <div className="heading-area">
              <i
                className="icon-chevron-down back-btn"
                onClick={() => props.navigate("/service")}
              ></i>
            </div>
          </div>
          <h3 className="p-mb-3 p-mb-lg-0"> View Service Access Master</h3>
          <div className="p-ml-auto p-d-lg-flex p-ai-center">
            <div className="card-actions p-ml-2">
              <Button
                type="button"
                className="add-btn add-btn p-button p-component p-jc-center p-w-100"
                onClick={(e) => handleOpen(e, "AddAccessMaster")}
              >
                <i className="icon-add"></i> Add
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <DataTable
          value={servicesAccessList}
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
        >
          <Column
            field="Index"
            header="Sr.No"
            body={actionBodyTemplateSrNo}
            style={{ maxWidth: 150 }}
          ></Column>
          <Column
            field="ServiceName"
            header="Access Name"
            style={{ width: "25%" }}
          ></Column>
          <Column header="Action" body={actionBodyTemplate}></Column>
        </DataTable>
      </div>

      {/* Add access master */}
      <Dialog
        header={accessMasterID ? "Edit Service Access" : "Add Service Access"}
        visible={addAccessMaster}
        className="dialog-popup"
        onHide={(e) => handleClose("AddAccessMaster")}
        draggable={false}
        closable={false}
        position="top"
      >
        <div>
          <div className="card-header">
            <div className="card-title p-d-lg-flex p-ai-center p-w-100">
              <h3 className="p-mb-3 p-mb-lg-0">
                {accessMasterID ? "Edit" : "Add"} Service Access
              </h3>
            </div>
          </div>
          <div className="card-body">
            <div className="form-area">
              <div className={"p-grid"}>
                <div className="p-col-12 p-md-4 p-pt-2">
                  <div className="p-w-100 ">
                    <div className="custom-inputs">
                      <label htmlFor="serviceName">
                        Service name <span style={{ color: "red" }}>*</span>:
                      </label>
                      <InputText
                        className="p-w-100 p-mt-2"
                        id="serviceName"
                        type="text"
                        placeholder="Service name"
                        value={serviceName}
                        autoFocus={true}
                        onChange={(e) => handleChange(e, "serviceName")}
                        // keyfilter={"alphanum"}
                        maxLength={30}
                      />
                    </div>
                    <span className="error-msg" style={{ color: "red" }}>
                      {serviceNameErr ? serviceNameErrText : null}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="dialog-footer">
          <button
            className="btn-dialog-cancel"
            onClick={() => handleClose("AddAccessMaster")}
          >
            {" "}
            cancel{" "}
          </button>
          <Button
            className="p-button p-component p-button-success"
            onClick={(e) => AddUpdateAdminServiceAccess(e)}
          >
            {" "}
            Submit{" "}
          </Button>
        </div>
      </Dialog>

      {/* Delete Confirm Modal */}
      <Dialog
        header="Delete Service"
        visible={isDeleteAccessMaster}
        className="dialog-popup"
        onHide={(e) => handleClose("DeleteService")}
        draggable={false}
        closable={false}
        position="top"
      >
        <span>Are you sure want to delete the Service record ?</span>
        <div className="dialog-footer">
          <button
            className="btn-dialog-cancel"
            onClick={(e) => handleClose("DeleteAccessMaster")}
          >
            cancel
          </button>
          <button
            className="btn-dialog-delete"
            onClick={() => DeleteAccessMaster()}
          >
            Delete
          </button>
        </div>
      </Dialog>

      {/* View Confirm Modal */}
      <Dialog
        header="View Service"
        visible={viewAccessMaster}
        className="dialog-popup"
        onHide={(e) => handleClose("AddService")}
        draggable={false}
        closable={false}
        position="center"
      >
        <div className="modalBody">
          <div className={"p-grid"}>
            <div className="p-col-12 p-md-12 p-pl-0 p-py-0">
              <div className={"p-grid"}>
                <div className="p-col-12 p-md-6 p-pt-2">
                  <div className="p-w-100 ">
                    <span>
                      <b>Service Name : </b>
                    </span>
                    <p>
                      {" "}
                      {!CommonConfig.isObjectEmpty(viewServiceData) &&
                      !CommonConfig.isEmpty(viewServiceData?.ServiceName)
                        ? viewServiceData?.ServiceName
                        : "-"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="dialog-footer">
          <button
            className="btn-dialog-cancel"
            onClick={(e) => handleClose("ViewAccessMaster")}
          >
            cancel
          </button>
        </div>
      </Dialog>
    </div>
  );
};

export default AccessMaster;
