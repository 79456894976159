import React, { useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { SelectButton } from "primereact/selectbutton";
import { MultiSelect } from "primereact/multiselect";
import api from "../../utils/apiClient";
import CommonConfig from "../../utils/constant";
import APIConstant from "../../utils/pathConstants";
import Toast from "../Shared/Toast/Toast";
import Loader from "../Shared/Loader/Loader";

import EntityWise from "./EntityWise";
import UserWise from "./UserWise";
import { Button } from "primereact/button";

const ManageAccess = (props) => {
  const accessTypeOptions = ["Entity Wise", "User Wise"];
  const [accessType, setAccessType] = useState(accessTypeOptions[0]);

  const [entityWiseServiceList, setEntityWiseServiceList] = useState([]);
  const [entityList, setEntityList] = useState([]);
  const [selectedEntity, setSelectedEntity] = useState(null);

  const [userWiseServiceList, setUserWiseServiceList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState(null);

  useEffect(() => {
    getEntityList();
    getUserList();

    return () => {};
  }, []);

  const resetScreen = () => {
    getEntityList();
    getUserList();
    // getEntityWiseServicesList();
  };

  const getEntityList = async () => {
    // Loader.show();
    try {
      let data = {
        // UserID: CommonConfig.loginData().SecurityUserID,
        // ProjectID: this.state.ProjectID
      };

      await api
        .get(APIConstant.path.getEntityList, data)
        .then((res) => {
          if (res.success) {
            setEntityList(res?.data);
            Loader.hide();
          } else {
            Toast.error({ message: res.Message });
            Loader.hide();
          }
        })
        .catch((err) => {
          console.log("Error: ", err);
        });
    } catch (err) {
      console.log("Error: ", err);
    }
  };

  const getUserList = async () => {
    // Loader.show();
    try {
      let data = {};

      await api
        .get(APIConstant.path.getAdminUserList, data)
        .then((res) => {
          if (res.success) {
            setUserList(res?.data);
            Loader.hide();
          } else {
            Toast.error({ message: res.Message });
            Loader.hide();
          }
        })
        .catch((err) => {
          console.log("Error: ", err);
        });
    } catch (err) {
      console.log("Error: ", err);
    }
  };

  const getEntityWiseServicesList = async (entityList) => {
    Loader.show();
    try {
      let data = {
        RoleList: entityList,
      };

      await api
        .post(APIConstant.path.getEntityWiseServicesList, data)
        .then((res) => {
          if (res.success) {
            setEntityWiseServiceList(res?.data);
            Loader.hide();
          } else {
            setEntityWiseServiceList([]);
            Toast.error({ message: res.message });
            Loader.hide();
          }
        })
        .catch((err) => {});
    } catch (err) {}
  };

  const getUserWiseServicesList = async (userList) => {
    // console.log("userList");
    Loader.show();
    try {
      let data = {
        UserList: userList,
      };
      await api
        .post(APIConstant.path.getUserWiseServicesList, data)
        .then((res) => {
          if (res.success) {
            setUserWiseServiceList(res?.data);
            Loader.hide();
          } else {
            setUserWiseServiceList([]);
            Toast.error({ message: res.Message });
            Loader.hide();
          }
        })
        .catch((err) => {});
    } catch (err) {}
  };

  const panelFooterTemplate = () => {
    return (
      <div className="p-py-2 p-px-3">
        <Button label="Submit" severity="info" />
      </div>
    );
  };

  const activeEntityWiseAccess = (indx) => {
    // debugger;
    let tempEntityWiseServicesData = entityWiseServiceList;
    tempEntityWiseServicesData[indx].IsActive =
      tempEntityWiseServicesData[indx].IsActive == "1" ? "0" : "1";
    setEntityWiseServiceList([...tempEntityWiseServicesData]);
  };

  const activeUserWiseAccess = (indx) => {
    // debugger;
    let tempUserWiseServicesData = userWiseServiceList;
    tempUserWiseServicesData[indx].IsActive =
      tempUserWiseServicesData[indx].IsActive == "1" ? "0" : "1";
    setUserWiseServiceList([...tempUserWiseServicesData]);
    console.log("userWiseServiceList", userWiseServiceList);
  };

  const handleEntityWiseParentMasterAccessChange = (e, idx, indx) => {
    var tempEntityWiseServices = entityWiseServiceList;
    tempEntityWiseServices[idx]["ServiceList"][indx].isChecked =
      e.target.checked;

    setEntityWiseServiceList([...tempEntityWiseServices]);
  };

  const handleEntityWiseParentAccessChange = (e, idx, indx, indxItem) => {
    var tempEntityWiseServices = entityWiseServiceList;
    tempEntityWiseServices[idx]["ServiceList"][indx]["ServiceAccess"][
      indxItem
    ].isChecked = e.target.checked;
    setEntityWiseServiceList([...tempEntityWiseServices]);
  };

  const handleEntityWiseChildMasterAccessChange = (
    e,
    idx,
    indx,
    indxItemChild
  ) => {
    var tempEntityWiseServices = entityWiseServiceList;
    tempEntityWiseServices[idx]["ServiceList"][indx].subServices[
      indxItemChild
    ].isChecked = e.target.checked;
    setEntityWiseServiceList([...tempEntityWiseServices]);
  };

  const handleEntityWiseChildAccessChange = (
    e,
    idx,
    indx,
    indxItemChild,
    indxMasterItemChild
  ) => {
    var tempEntityWiseServices = entityWiseServiceList;
    tempEntityWiseServices[idx]["ServiceList"][indx]["subServices"][
      indxItemChild
    ].ServiceAccess[indxMasterItemChild].isChecked = e.target.checked;
    setEntityWiseServiceList([...tempEntityWiseServices]);
  };

  const handleEntityWiseChildActive = (e, idx, indx) => {
    var tempEntityWiseServices = entityWiseServiceList;
    tempEntityWiseServices[idx]["ServiceList"][indx]["IsChildActive"] =
      tempEntityWiseServices[idx]["ServiceList"][indx]["IsChildActive"] == 1
        ? 0
        : 1;
    setEntityWiseServiceList([...tempEntityWiseServices]);
  };

  const updateAdminEntityWiseServices = async (idx) => {
    // Loader.show();
    try {
      // debugger;
      let data = {
        ServiceList: entityWiseServiceList[idx].ServiceList,
        RoleId: entityWiseServiceList[idx].RoleId,
      };

      await api
        .post(APIConstant.path.updateAdminEntityWiseServices, data)
        .then((res) => {
          if (res.success) {
            getEntityWiseServicesList(selectedEntity);
            Toast.success({ message: res.message });
            Loader.hide();
          } else {
            Toast.error({ message: res.message });
            Loader.hide();
          }
        })
        .catch((err) => {});
    } catch (err) {}
  };

  const handleUserWiseParentMasterAccessChange = (e, idx, indx) => {
    var tempUserWiseServices = userWiseServiceList;
    tempUserWiseServices[idx]["ServiceList"][indx].isChecked = e.target.checked;
    setUserWiseServiceList([...tempUserWiseServices]);
  };

  const handleUserWiseParentAccessChange = (e, idx, indx, indxItem) => {
    var tempUserWiseServices = userWiseServiceList;
    tempUserWiseServices[idx]["ServiceList"][indx]["ServiceAccess"][
      indxItem
    ].isChecked = e.target.checked;
    setUserWiseServiceList([...tempUserWiseServices]);
  };

  const handleUserWiseChildMasterAccessChange = (e, idx, indx, indxItemChild) => {
    var tempUserWiseServices = userWiseServiceList;
    tempUserWiseServices[idx]["ServiceList"][indx].subServices[
		indxItemChild
	  ].isChecked = e.target.checked;
    setUserWiseServiceList([...tempUserWiseServices]);
  };

  const handleUserWiseChildAccessChange = (e, idx, indx, indxItemChild, indxMasterItemChild) => {
    var tempUserWiseServices = userWiseServiceList;
    tempUserWiseServices[idx]["ServiceList"][indx]["subServices"][
		indxItemChild
	  ].ServiceAccess[indxMasterItemChild].isChecked = e.target.checked;
    setUserWiseServiceList([...tempUserWiseServices]);
  };

  const handleUserWiseChildActive = (e, idx, indx) => {
    var tempUserWiseServices = userWiseServiceList;
    tempUserWiseServices[idx]["ServiceList"][indx]["IsChildActive"] =
      tempUserWiseServices[idx]["ServiceList"][indx]["IsChildActive"] == 1
        ? 0
        : 1;
    setUserWiseServiceList([...tempUserWiseServices]);
  };

  const updateAdminUserWiseServices = async (idx) => {
    Loader.show();
    try {
      // debugger;
      let data = {
        ServiceList: userWiseServiceList[idx].ServiceList,
        UserId: userWiseServiceList[idx].UserId,
      };

      await api
        .post(APIConstant.path.updateAdminUserWiseServices, data)
        .then((res) => {
          if (res.success) {
            getUserWiseServicesList(selectedUsers);
            Toast.success({ message: res.message });
            Loader.hide();
          } else {
            Toast.error({ message: res.message });
            Loader.hide();
          }
        })
        .catch((err) => {});
    } catch (err) {}
  };

  return (
    <div>
      <div className="space-y-4 bg-white p-4">
        <div className="flex justify-between items-center w-full border-b border-gray-900/10 pb-4">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Manage Access
          </h2>
          <div className="flex justify-between items-center space-x-4">
            <div className="relative">
              <InputText
                type="search"
                onInput={() => {}}
                placeholder="Global Search"
                className="h-10 !py-0 items-center leading-10 placeholder:h-10 leading-10"
              />
              <i className="icon-search absolute right-2.5 top-3"></i>
            </div>

            {accessType === "Entity Wise" ? (
              <MultiSelect
                value={selectedEntity}
                onChange={(e) => {
                  setSelectedEntity(e.value);
                  getEntityWiseServicesList(e.value);
                }}
                options={entityList}
                optionLabel="name"
                filter
                placeholder="Select Entity"
                maxSelectedLabels={3}
                className="h-10 [&_.p-multiselect-label]:!py-0 items-center"
                panelFooterTemplate={panelFooterTemplate}
              />
            ) : (
              <MultiSelect
                value={selectedUsers}
                onChange={(e) => {
                  setSelectedUsers(e.value);
                  getUserWiseServicesList(e.value);
                }}
                options={userList}
                optionLabel="name"
                filter
                placeholder="Select User"
                maxSelectedLabels={3}
                className="h-10 [&_.p-multiselect-label]:!py-0 items-center"
              />
            )}
            <SelectButton
              value={accessType}
              className="[&_.p-button-label]:text-sm [&_.p-button]:h-10 [&_.p-highlight]:!border-0 [&_.p-highlight]:!bg-cyan-500 [&_.p-highlight]:hover:!bg-cyan-600"
              onChange={(e) => setAccessType(e.value)}
              options={accessTypeOptions}
            />
          </div>
        </div>
        <div className="">
          {accessType === "Entity Wise" ? (
            <EntityWise
              {...props}
              EntityWiseServiceList={entityWiseServiceList}
              ActiveEntityWiseAccess={activeEntityWiseAccess}
              HandleEntityWiseParentMasterAccessChange={
                handleEntityWiseParentMasterAccessChange
              }
              HandleEntityWiseParentAccessChange={
                handleEntityWiseParentAccessChange
              }
              HandleEntityWiseChildMasterAccessChange={
                handleEntityWiseChildMasterAccessChange
              }
              HandleEntityWiseChildAccessChange={
                handleEntityWiseChildAccessChange
              }
              UpdateAdminEntityWiseServices={updateAdminEntityWiseServices}
              handleEntityWiseChildActive={handleEntityWiseChildActive}
            />
          ) : (
            <UserWise
              {...props}
              UserWiseServiceList={userWiseServiceList}
              ActiveUserWiseAccess={activeUserWiseAccess}
              HandleUserWiseParentMasterAccessChange={
                handleUserWiseParentMasterAccessChange
              }
              HandleUserWiseParentAccessChange={
                handleUserWiseParentAccessChange
              }
              HandleUserWiseChildMasterAccessChange={
                handleUserWiseChildMasterAccessChange
              }
              HandleUserWiseChildAccessChange={handleUserWiseChildAccessChange}
              UpdateAdminUserWiseServices={updateAdminUserWiseServices}
              handleUserWiseChildActive={handleUserWiseChildActive}
            />
          )}
        </div>
      </div>
      {/* <div className='card-header'>
				<div className='card-title'>
					<span className="searchbar-area">
						<InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Global Search" size="30" style={{ marginRight: 10 }} />
						<i className="icon-search"></i>
					</span>
				</div>
				<div className='card-actions'>
					<div className="card flex justify-content-center">
						<SelectButton value={accessType} onChange={(e) => setAccessType(e.value)} options={accessTypeOptions} />
					</div>
					<div className="card flex justify-content-center">

						{accessType === 'Entity Wise' ?
							<MultiSelect value={selectedEntity} onChange={(e) => {
								setSelectedEntity(e.value);
								getEntityWiseServicesList(e.value);
							}} options={entityList} optionLabel="name"
								filter placeholder="Select Entity" maxSelectedLabels={10} className="w-full md:w-20rem" panelFooterTemplate={panelFooterTemplate} />
							:
							<MultiSelect value={selectedUsers} onChange={(e) => {
								setSelectedUsers(e.value);
								getUserWiseServicesList(e.value);
							}} options={userList} optionLabel="name"
								filter placeholder="Select User" maxSelectedLabels={10} className="w-full md:w-20rem" />}
					</div>
				</div>
			</div> */}
    </div>
  );
};

export default ManageAccess;
