import React, { useEffect, useState } from "react";
import CommonConfig from "../../utils/constant";
import logo from "../../assets/svgs/portlink-logo.svg";

const Sidebar = (props) => {
  const [sidebarMenu, setSidebarMenu] = useState([
    {
      MenuName: "Dashboard",
      Icon: "icon-dashboard",
      Path: "/dashboard",
    },
    {
      MenuName: "Manage Access",
      Icon: "icon-lock",
      Path: "/manage-access",
    },
    {
      MenuName: "Master",
      Icon: "icon-group",
      SubMenus: [
        {
          MenuName: "Location",
          Icon: "",
          ChildSubMenus: [
            {
              MenuName: "Country Master",
              Icon: "icon-log-type",
              Path: "/CountryMaster",
              IsCollapseActive: false,
            },
          ],
        },
        {
          MenuName: "Currency Master",
          Icon: "icon-log-type",
          Path: "/currency-master",
        },
        {
          MenuName: "Item & Goods",
          Icon: "icon-log-type",
          Path: "/item-and-goods",
        },
        {
          MenuName: "User Type",
          Icon: "icon-log-type",
          Path: "/usertype",
        },
        {
          MenuName: "Company Master",
          Icon: "icon-log-type",
          Path: "/company-master",
        },
      ],
    },
    {
      MenuName: "Plan",
      Icon: "icon-log-type",
      Path: "/plan",
    },
    {
      MenuName: "Service",
      Icon: "icon-hole-type",
      Path: "/service",
    },
    {
      MenuName: "User Plan Details",
      Icon: "icon-hole-type",
      Path: "/userPlanDetails",
    },
    {
      MenuName: "Users",
      Icon: "icon-group",
      Path: "/users",
    },
  ]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    setName(CommonConfig.loginData()?.Name);
    setEmail(CommonConfig.loginData()?.Username);
  }, []);

  const manageSidebarWidth = () => {
    document.getElementById("sidebar-area").classList.toggle("sidebar-mini");
  };

  const handleMenuClick = (x, idx) => {
    if (x.Path) {
      props.navigate(x.Path);
    } else {
      let tempSidebarMenu = [...sidebarMenu];
      tempSidebarMenu[idx]["IsCollapseActive"] =
        tempSidebarMenu[idx].SubMenus.filter((x) => x.IsCollapseActive == true)
          .length && tempSidebarMenu[idx]["IsCollapseActive"] == true
          ? tempSidebarMenu[idx]["IsCollapseActive"]
          : !tempSidebarMenu[idx].SubMenus.filter(
              (x) => x.IsCollapseActive == true
            ).length && tempSidebarMenu[idx]["IsCollapseActive"] == true
          ? !tempSidebarMenu[idx]["IsCollapseActive"]
          : !tempSidebarMenu[idx]["IsCollapseActive"];

      setSidebarMenu([...tempSidebarMenu]);
    }
  };

  return (
    <div className="sidebar-container" id="sidebar-area">
      <div className="p-h-100">
        <div
          onClick={manageSidebarWidth}
          className="sidebar-close cursor-pointer"
        >
          <i className="icon-back-arrow"></i>
        </div>
        <div className="sidebar-header">
          <div className="sidebar-logo">
            <img src={logo} alt="" />
          </div>
        </div>
        <div className="sidebar-body">
          <ul className="parent-sidebar-options">
            {sidebarMenu.length ? (
              sidebarMenu.map((x, idx) => {
                return (
                  <li
                    className={`parent-sidebar-option p-flex-wrap ${
                      x.IsCollapseActive ? "isParentActive" : ""
                    }`}
                    key={idx}
                    onClick={() => {
                      handleMenuClick(x, idx);
                    }}
                  >
                    <div
                      className={`p-d-flex p-ai-center p-jc-between p-w-100 parent-sidebar-content ${
                        window.location &&
                        (window.location.pathname?.includes(x.Path) ||
                          (x.SubMenus &&
                            x.SubMenus.some((child) =>
                              child.Path?.includes(window.location.pathname)
                            )))
                          ? "active"
                          : ""
                      }`}
                    >
                      <div className="p-d-flex p-ai-center">
                        <i className={`${x.Icon} sidebar-icon`}></i>
                        <span>{x.MenuName}</span>
                      </div>
                      {!x.Path && (
                        <i
                          className={`icon-chevron-down text-14 sidebar-tail-icon ${
                            x.IsCollapseActive ? "active" : ""
                          }`}
                        ></i>
                      )}
                    </div>
                    {x.IsCollapseActive && x.SubMenus && (
                      <ul className="child-sidebar-options">
                        {x.SubMenus.map((child, childIdx) => (
                          <li
                            className={`parent-sidebar-option p-flex-wrap ${
                              window.location &&
                              (window.location.pathname?.includes(child.Path) ||
                                (child.ChildSubMenus &&
                                  child.ChildSubMenus.some((subChild) =>
                                    subChild.Path?.includes(
                                      window.location.pathname
                                    )
                                  )))
                                ? "active"
                                : ""
                            }`}
                            key={childIdx}
                            onClick={() => {
                              if (child.Path) {
                                props.navigate(child.Path);
                              } else {
                                let tempSubMenus = [...x.SubMenus];
                                tempSubMenus[childIdx]["IsCollapseActive"] =
                                  !tempSubMenus[childIdx]["IsCollapseActive"];
                                let tempSidebarMenus = [...sidebarMenu];
                                tempSidebarMenus[idx].SubMenus = tempSubMenus;
                                setSidebarMenu([...tempSidebarMenus]);
                              }
                            }}
                          >
                            <div
                              className={`p-d-flex p-ai-center p-jc-between p-w-100 parent-sidebar-content ${
                                window.location &&
                                window.location.pathname.includes(child.Path)
                                  ? "active"
                                  : ""
                              }`}
                            >
                              <div className="p-d-flex p-ai-center">
                                <i className={`${child.Icon} sidebar-icon`}></i>
                                <span>{child.MenuName}</span>
                              </div>
                              {!child.Path && (
                                <i
                                  className={`icon-chevron-down text-14 sidebar-tail-icon ${
                                    child.IsCollapseActive ? "active" : ""
                                  }`}
                                ></i>
                              )}
                            </div>
                            {child.IsCollapseActive && child.ChildSubMenus && (
                              <ul className="sub-child-sidebar-options">
                                {child.ChildSubMenus.map(
                                  (subChild, subChildIdx) => (
                                    <li
                                      className={`child-sidebar-option ${
                                        window.location &&
                                        window.location.pathname.includes(
                                          subChild.Path
                                        )
                                          ? "active"
                                          : ""
                                      }`}
                                      key={subChildIdx}
                                      onClick={() => {
                                        if (subChild.Path) {
                                          props.navigate(subChild.Path);
                                        }
                                      }}
                                    >
                                      <div className="p-d-flex p-ai-center">
                                        <i
                                          className={`${subChild.Icon} sidebar-icon`}
                                        ></i>
                                        <span>{subChild.MenuName}</span>
                                      </div>
                                      {!subChild.Path && (
                                        <i
                                          className={`icon-chevron-down text-14 sidebar-tail-icon ${
                                            subChild.IsCollapseActive
                                              ? "active"
                                              : ""
                                          }`}
                                        ></i>
                                      )}
                                    </li>
                                  )
                                )}
                              </ul>
                            )}
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                );
              })
            ) : (
              <li>No menus available</li>
            )}
          </ul>
        </div>
      </div>
      <div className="sidebar-footer">
        <span className="sidebar-footer-text">
          Need help? Please contact us.
        </span>
      </div>
    </div>
  );
};

export default Sidebar;
