import React from "react";

const FallbackComponent = () => {
  return (
    <div>
      <div className="p-d-flex p-ai-center p-jc-center p-h-full">
        <div className="p-text-center">
          <div className="triple-spinner"></div>
          <label className="p-mt-3 text-20">Please wait ...</label>
        </div>
      </div>
    </div>
  );
};

export default FallbackComponent;
