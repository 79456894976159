import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import CommonConfig from "../../utils/constant";
import Toast from "../Shared/Toast/Toast";
import Loader from "../Shared/Loader/Loader";
import APIConstant from "../../utils/pathConstants";
import api from "../../utils/apiClient";
import { Button } from "primereact/button";
import { useLocation } from "react-router-dom";
import { Calendar } from "primereact/calendar";
// import moment from "moment";
import { Dropdown } from "primereact/dropdown";

const AddCompanyMaster = (props) => {
  const location = useLocation();
  const [securityUserID, setSecurityUserID] = useState(null);
  const [companyMasterId, setCompanyMasterId] = useState(null);

  const [gstNo, setGSTNo] = useState("");
  const [gstNoErr, setGSTNoErr] = useState(false);
  const [gstNoErrText, setGSTNoErrText] = useState("");

  const [panNo, setPANNo] = useState("");
  const [panNoErr, setPANNoErr] = useState(false);
  const [panNoErrText, setPANNoErrText] = useState("");

  const [country, setCountry] = useState("");
  const [countryErr, setCountryErr] = useState(false);
  const [countryErrText, setCountryErrText] = useState("");
  const [countryList, setCountryList] = useState([]);

  const [phoneNo, setPhoneNo] = useState("");
  const [phoneNoErr, setPhoneNoErr] = useState(false);
  const [phoneNoErrText, setPhoneNoErrText] = useState("");

  const [companyName, setCompanyName] = useState("");
  const [companyNameErr, setCompanyNameErr] = useState(false);
  const [companyNameErrText, setCompanyNameErrText] = useState("");

  const [address, setAddress] = useState("");
  const [addressErr, setAddressErr] = useState(false);
  const [addressErrText, setAddressErrText] = useState("");

  const [city, setCity] = useState("");
  const [cityErr, setCityErr] = useState(false);
  const [cityErrText, setCityErrText] = useState("");

  const [state, setState] = useState("");
  const [stateErr, setStateErr] = useState(false);
  const [stateErrText, setStateErrText] = useState("");

  const [pincode, setPincode] = useState("");
  const [pincodeErr, setPincodeErr] = useState(false);
  const [pincodeErrText, setPincodeErrText] = useState("");

  const [websiteURL, setWebsiteURL] = useState("");
  const [websiteURLErr, setWebsiteURLErr] = useState(false);
  const [websiteURLErrText, setWebsiteURLErrText] = useState("");

  const [companyEmail, setCompanyEmail] = useState("");
  const [companyEmailErr, setCompanyEmailErr] = useState(false);
  const [companyEmailErrText, setCompanyEmailErrText] = useState("");

  const [registerDate, setRegisterDate] = useState("");
  const [registerDateErr, setRegisterDateErr] = useState(false);
  const [registerDateErrText, setRegisterDateErrText] = useState("");

  const [stateCode, setStateCode] = useState("");

  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");

  const [status, setStatus] = useState("");

  useEffect(() => {
    const loginData = CommonConfig.loginData();
    setSecurityUserID(loginData?.UserId);
    if (location?.state?.companyMasterId) {
      getAdminCompanyMasterById(location?.state?.companyMasterId);
    }
    GetCountryMasterList();
  }, []);

  const GetCountryMasterList = async () => {
    try {
      await api
        .post(APIConstant.path.GetAdminCountryMaster)
        .then(async (response) => {
          let res = response;
          if (res.success) {
            setCountryList(
              res.data.map((data) => ({
                label: data.CountryName,
                value: data._id,
              }))
            );
            Loader.hide();
          }
        })
        .catch((err) => {
          Loader.hide();
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const getAdminCompanyMasterById = async (ID) => {
    try {
      let data = {
        UserId: ID,
      };
      await api
        .post(APIConstant.path.GetAdminCompanyMasterById, data)
        .then(async (response) => {
          let res = await response;
          if (res.success) {
            Loader.hide();
            setCompanyMasterId(res?.data._id);
            setGSTNo(res?.data.GSTNo);
            setPANNo(res?.data.PanNo);
            setCountry(res?.data.address?.Locality);
            setPhoneNo(res?.data.PhoneNo);
            setCompanyName(res?.data.CompanyName);
            setAddress(res?.data.address?.Address);
            setCity(res?.data.address?.City);
            setState(res?.data.address?.State);
            setPincode(res?.data.address?.Pincode);
            setWebsiteURL(res?.data.WebsiteURL);
            setCompanyEmail(res?.data.CompanyEmail);
            setStatus(res?.data.Status);
            setRegisterDate(new Date(res?.data.RegistrationDate));
          }
        })
        .catch((err) => {
          Loader.hide();
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const validateGstNo = () => {
    let formIsValid = true;
    if (CommonConfig.isEmpty(gstNo)) {
      formIsValid = false;
      setGSTNoErr(true);
      setGSTNoErrText("GST No. is required");
    } else {
      setGSTNoErr(false);
      setGSTNoErrText("");
    }
    return formIsValid;
  };

  const GetCompanyDetailByGST = async () => {
    try {
      if (validateGstNo()) {
        let data = {
          GSTNO: gstNo,
        };
        await api
          .post(APIConstant.path.GetCompanyDetailByGST, data)
          .then(async (response) => {
            let res = await response;
            if (res.success) {
              setStatus(res?.data?.Status);
              Loader.hide();
              setCompanyMasterId(res?.data?.CompanyMasterId);
              setGSTNo(res?.data?.GSTNo);
              setPANNo(res?.data?.PanNo);
              setCompanyName(res?.data?.CompanyName);
              setStateCode(res?.data?.StateCode);
              setCountry(res?.data?.address?.Locality);
              setLat(res?.data?.address?.Latitude);
              setLong(res?.data?.address?.Longitude);
              setCity(res?.data?.address?.City);
              setState(res?.data?.address?.State);
              setPincode(res?.data?.address?.Pincode);
              setAddress(res?.data?.address?.Address);
            } else {
              Toast.error({ message: res.message });
            }
          })
          .catch((err) => {
            Loader.hide();
            console.log(err);
          });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const validatePanNo = () => {
    let formIsValid = true;
    if (CommonConfig.isEmpty(panNo)) {
      formIsValid = false;
      setPANNoErr(true);
      setPANNoErrText("PAN No. is required");
    } else {
      setPANNoErr(false);
      setPANNoErrText("");
    }
    return formIsValid;
  };

  const GetCompanyDetailByPAN = async () => {
    try {
      if (validatePanNo()) {
        let data = {
          PANNO: panNo,
        };
        await api
          .post(APIConstant.path.GetCompanyDetailByPAN, data)
          .then(async (response) => {
            let res = await response;
            if (res.success) {
              Loader.hide();

              setPANNo(res.data.PanNo);
              setCompanyName(res?.data?.CompanyName);

              setStatus(res?.data?.Status);
            } else {
              Toast.error({ message: res.message });
            }
          })
          .catch((err) => {
            Loader.hide();
            console.log(err);
          });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const resetScreen = async () => {
    setSecurityUserID(null);

    setGSTNo("");
    setGSTNoErr(false);
    setGSTNoErrText("");

    setPANNo("");
    setPANNoErr(false);
    setPANNoErrText("");

    setCountry("");
    setCountryErr(false);
    setCountryErrText("");

    setPhoneNo("");
    setPhoneNoErr(false);
    setPhoneNoErrText("");

    setCompanyName("");
    setCompanyNameErr(false);
    setCompanyNameErrText("");

    setAddress("");
    setAddressErr(false);
    setAddressErrText("");

    setCity("");
    setCityErr(false);
    setCityErrText("");

    setState("");
    setStateErr(false);
    setStateErrText("");

    setPincode("");
    setPincodeErr(false);
    setPincodeErrText("");

    setWebsiteURL("");
    setWebsiteURLErr(false);
    setWebsiteURLErrText("");

    setCompanyEmail("");
    setCompanyEmailErr(false);
    setCompanyEmailErrText("");

    setRegisterDate("");
    setRegisterDateErr(false);
    setRegisterDateErrText("");
  };

  const handleChange = (e, type) => {
    if (type === "gstNo") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setGSTNo("");
        setGSTNoErr(true);
        setGSTNoErrText("GST No. is required");
      } else {
        setGSTNo(e.target.value);
        setGSTNoErr(false);
        setGSTNoErrText("");
      }
    } else if (type === "panNo") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setPANNo("");
        setPANNoErr(true);
        setPANNoErrText("PAN No. is required");
      } else {
        setPANNo(e.target.value);
        setPANNoErr(false);
        setPANNoErrText("");
      }
    } else if (type === "country") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setCountry("");
        setCountryErr(true);
        setCountryErrText("Country is required");
      } else {
        setCountry(e.target.value);
        setCountryErr(false);
        setCountryErrText("");
      }
    } else if (type === "phoneNo") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setPhoneNo("");
        setPhoneNoErr(true);
        setPhoneNoErrText("PhoneNo number is required");
      } else {
        setPhoneNo(e.target.value);
        setPhoneNoErr(false);
        setPhoneNoErrText("");
      }
    } else if (type === "companyName") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setCompanyName("");
        setCompanyNameErr(true);
        setCompanyNameErrText("Company Name is required");
      } else {
        setCompanyName(e.target.value);
        setCompanyNameErr(false);
        setCompanyNameErrText("");
      }
    } else if (type === "address") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setAddress("");
        setAddressErr(true);
        setAddressErrText("Address is required");
      } else {
        setAddress(e.target.value);
        setAddressErr(false);
        setAddressErrText("");
      }
    } else if (type === "city") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setCity("");
        setCityErr(true);
        setCityErrText("City is required");
      } else {
        setCity(e.target.value);
        setCityErr(false);
        setCityErrText("");
      }
    } else if (type === "state") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setState("");
        setStateErr(true);
        setStateErrText("State is required");
      } else {
        setState(e.target.value);
        setStateErr(false);
        setStateErrText("");
      }
    } else if (type === "pincode") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setPincode("");
        setPincodeErr(true);
        setPincodeErrText("Pincode is required");
      } else {
        setPincode(e.target.value);
        setPincodeErr(false);
        setPincodeErrText("");
      }
    } else if (type === "websiteURL") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setWebsiteURL("");
        setWebsiteURLErr(true);
        setWebsiteURLErrText("Website URL is required");
      } else {
        setWebsiteURL(e.target.value);
        setWebsiteURLErr(false);
        setWebsiteURLErrText("");
      }
    } else if (type === "companyEmail") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setCompanyEmail("");
        setCompanyEmailErr(true);
        setCompanyEmailErrText("Company email is required");
      } else {
        setCompanyEmail(e.target.value);
        setCompanyEmailErr(false);
        setCompanyEmailErrText("");
      }
    } else if (type === "registerDate") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setRegisterDate("");
        setRegisterDateErr(true);
        setRegisterDateErrText("Register date is required");
      } else {
        setRegisterDate(e.target.value);
        setRegisterDateErr(false);
        setRegisterDateErrText("");
      }
    }
  };

  const validate = () => {
    let formIsValid = true;
    if (CommonConfig.isEmpty(gstNo) && CommonConfig.isEmpty(panNo)) {
      formIsValid = false;
      setGSTNoErr(true);
      setGSTNoErrText("GST No. is required");
    } else {
      setGSTNoErr(false);
      setGSTNoErrText("");
    }
    if (CommonConfig.isEmpty(panNo) && CommonConfig.isEmpty(gstNo)) {
      formIsValid = false;
      setPANNoErr(true);
      setPANNoErrText("PAN No. is required");
    } else {
      setPANNoErr(false);
      setPANNoErrText("");
    }
    if (CommonConfig.isEmpty(country)) {
      formIsValid = false;
      setCountryErr(true);
      setCountryErrText("Country is required");
    } else {
      setCountryErr(false);
      setCountryErrText("");
    }
    if (CommonConfig.isEmpty(phoneNo)) {
      formIsValid = false;
      setPhoneNoErr(true);
      setPhoneNoErrText("PhoneNo number is required");
    } else {
      setPhoneNoErr(false);
      setPhoneNoErrText("");
    }
    if (CommonConfig.isEmpty(companyName)) {
      formIsValid = false;
      setCompanyNameErr(true);
      setCompanyNameErrText("Company name is required");
    } else {
      setCompanyNameErr(false);
      setCompanyNameErrText("");
    }
    if (CommonConfig.isEmpty(address)) {
      formIsValid = false;
      setAddressErr(true);
      setAddressErrText("Address is required");
    } else {
      setAddressErr(false);
      setAddressErrText("");
    }
    if (CommonConfig.isEmpty(city)) {
      formIsValid = false;
      setCityErr(true);
      setCityErrText("City is required");
    } else {
      setCityErr(false);
      setCityErrText("");
    }
    if (CommonConfig.isEmpty(state)) {
      formIsValid = false;
      setStateErr(true);
      setStateErrText("State is required");
    } else {
      setStateErr(false);
      setStateErrText("");
    }
    if (CommonConfig.isEmpty(pincode)) {
      formIsValid = false;
      setPincodeErr(true);
      setPincodeErrText("Pincode required");
    } else {
      setPincodeErr(false);
      setPincodeErrText("");
    }
    if (CommonConfig.isEmpty(websiteURL)) {
      formIsValid = false;
      setWebsiteURLErr(true);
      setWebsiteURLErrText("Website URL is required");
    } else {
      setWebsiteURLErr(false);
      setWebsiteURLErrText("");
    }
    if (CommonConfig.isEmpty(companyEmail)) {
      formIsValid = false;
      setCompanyEmailErr(true);
      setCompanyEmailErrText("Company email is required");
    } else {
      setCompanyEmailErr(false);
      setCompanyEmailErrText("");
    }
    if (CommonConfig.isEmpty(registerDate)) {
      formIsValid = false;
      setRegisterDateErr(true);
      setRegisterDateErrText("Register date is required");
    } else {
      setRegisterDateErr(false);
      setRegisterDateErrText("");
    }

    return formIsValid;
  };

  const AddUpdateCompanyMaster = async (e) => {
    if (validate(e)) {
      try {
        let data = {
          UserId: CommonConfig.loginData().UserId,
          CompanyMasterId: companyMasterId,
          GSTNo: gstNo,
          PANNo: panNo,
          Country: country,
          PhoneNo: phoneNo,
          CompanyName: companyName,
          WebsiteURL: websiteURL,
          CompanyEmail: companyEmail,
          StateCode: stateCode,
          RegistrationDate: registerDate,
          Address: address,
          Locality: country,
          Pincode: pincode,
          State: state,
          City: city,
          Latitude: lat,
          Longitude: long,
        };
        Loader.show();
        await api
          .post(APIConstant.path.AddUpdateCompanyMaster, data)
          .then((response) => {
            let res = response;
            if (res.success) {
              Toast.success({ message: res.message });
              Loader.hide();
              props.navigate("/company-master");
            } else {
              Toast.error({ message: res.message });
              Loader.hide();
            }
          })
          .catch((err) => {});
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <div className="user-container p-d-flex p-flex-column p-jc-between">
      <div>
        <div className="card-header">
          <div className="card-title p-d-lg-flex p-ai-center p-w-100">
            <div className="heading-area">
              <i
                className="icon-chevron-down back-btn"
                onClick={() => props.navigate("/company-master")}
              ></i>
            </div>
            <h3 className="p-mb-3 p-mb-lg-0">
              {companyMasterId ? "Edit" : "Add"} Company Master
            </h3>
          </div>
        </div>
        <div className="card-body">
          <div className="form-area">
            <div className={"p-grid"}>
              <div className="p-col-12 p-md-4 p-pt-2">
                <div className="p-w-100 ">
                  <div className="custom-inputs">
                    <label htmlFor="gstNo">
                      {" "}
                      GST No. <span style={{ color: "red" }}>*</span>:{" "}
                    </label>
                    <InputText
                      className="p-w-100 p-mt-2"
                      id="gstNo"
                      type="text"
                      placeholder="GST No."
                      value={gstNo}
                      autoFocus={true}
                      onChange={(e) => handleChange(e, "gstNo")}
                      keyfilter={"alphanum"}
                      maxLength={30}
                    />
                  </div>
                  <span className="error-msg" style={{ color: "red" }}>
                    {gstNoErr ? gstNoErrText : null}
                  </span>
                  <i
                    className="icon-search"
                    onClick={() => GetCompanyDetailByGST()}
                  ></i>
                </div>
              </div>
              <div className="p-col-12 p-md-4 p-pt-2">
                <div className="p-w-100 ">
                  <div className="custom-inputs">
                    <label htmlFor="panNo">
                      PAN No. <span style={{ color: "red" }}>*</span>:
                    </label>
                    <InputText
                      className="p-w-100 p-mt-2"
                      id="panNo"
                      type="text"
                      placeholder="PAN No."
                      value={panNo}
                      autoFocus={true}
                      onChange={(e) => handleChange(e, "panNo")}
                      keyfilter={"alphanum"}
                      maxLength={30}
                    />
                  </div>
                  <span className="error-msg" style={{ color: "red" }}>
                    {panNoErr ? panNoErrText : null}
                  </span>
                  <i
                    className="icon-search"
                    onClick={() => GetCompanyDetailByPAN()}
                  ></i>
                </div>
              </div>
              <div className="p-col-12 p-md-4 p-pt-2">
                <div className="p-w-100 ">
                  <div className="custom-inputs">
                    <label htmlFor="country">
                      Country <span style={{ color: "red" }}>*</span>:{" "}
                    </label>
                    <Dropdown
                      className="p-w-100 p-mt-2"
                      optionLabel="label"
                      value={country}
                      options={countryList}
                      onChange={(e) => handleChange(e, "country")}
                      filter
                      placeholder="Country"
                    />
                  </div>
                  <span className="error-msg" style={{ color: "red" }}>
                    {countryErr ? countryErrText : null}
                  </span>
                </div>
              </div>
              <div className="p-col-12 p-md-4 p-pt-2">
                <div className="p-w-100 ">
                  <div className="custom-inputs">
                    <label htmlFor="phoneNo">
                      {" "}
                      Phone No <span style={{ color: "red" }}>*</span>:{" "}
                    </label>
                    <InputText
                      className="p-w-100 p-mt-2"
                      id="phoneNo"
                      type="number"
                      placeholder="Phone no"
                      value={phoneNo}
                      autoFocus={true}
                      onChange={(e) => handleChange(e, "phoneNo")}
                      keyfilter={"num"}
                      maxLength={10}
                    />
                  </div>
                  <span className="error-msg" style={{ color: "red" }}>
                    {phoneNoErr ? phoneNoErrText : null}
                  </span>
                </div>
              </div>
              <div className="p-col-12 p-md-4 p-pt-2">
                <div className="p-w-100 ">
                  <div className="custom-inputs">
                    <label htmlFor="companyName">
                      {" "}
                      Company name <span style={{ color: "red" }}>*</span>:{" "}
                    </label>
                    <InputText
                      className="p-w-100 p-mt-2"
                      id="companyName"
                      type="text"
                      placeholder="Company name"
                      value={companyName}
                      autoFocus={true}
                      onChange={(e) => handleChange(e, "companyName")}
                      //   keyfilter={"alphanum"}
                      maxLength={50}
                    />
                  </div>
                  <span className="error-msg" style={{ color: "red" }}>
                    {companyNameErr ? companyNameErrText : null}
                  </span>
                </div>
              </div>
              <div className="p-col-12 p-md-4 p-pt-2">
                <div className="p-w-100 ">
                  <div className="custom-inputs">
                    <label htmlFor="address">
                      {" "}
                      Address <span style={{ color: "red" }}>*</span>:{" "}
                    </label>
                    <InputText
                      className="p-w-100 p-mt-2"
                      id="address"
                      type="text"
                      placeholder="Address"
                      value={address}
                      autoFocus={true}
                      onChange={(e) => handleChange(e, "address")}
                    />
                  </div>
                  <span className="error-msg" style={{ color: "red" }}>
                    {addressErr ? addressErrText : null}
                  </span>
                </div>
              </div>
              <div className="p-col-12 p-md-4 p-pt-2">
                <div className="p-w-100 ">
                  <div className="custom-inputs">
                    <label htmlFor="city">
                      {" "}
                      City <span style={{ color: "red" }}>*</span>:{" "}
                    </label>
                    <InputText
                      className="p-w-100 p-mt-2"
                      id="city"
                      type="text"
                      placeholder="City"
                      value={city}
                      autoFocus={true}
                      onChange={(e) => handleChange(e, "city")}
                    />
                  </div>
                  <span className="error-msg" style={{ color: "red" }}>
                    {cityErr ? cityErrText : null}
                  </span>
                </div>
              </div>
              <div className="p-col-12 p-md-4 p-pt-2">
                <div className="p-w-100 ">
                  <div className="custom-inputs">
                    <label htmlFor="state">
                      {" "}
                      State <span style={{ color: "red" }}>*</span>:{" "}
                    </label>
                    <InputText
                      className="p-w-100 p-mt-2"
                      id="state"
                      type="text"
                      placeholder="State"
                      value={state}
                      autoFocus={true}
                      onChange={(e) => handleChange(e, "state")}
                    />
                  </div>
                  <span className="error-msg" style={{ color: "red" }}>
                    {stateErr ? stateErrText : null}
                  </span>
                </div>
              </div>
              <div className="p-col-12 p-md-4 p-pt-2">
                <div className="p-w-100 ">
                  <div className="custom-inputs">
                    <label htmlFor="pincode">
                      {" "}
                      Pincode <span style={{ color: "red" }}>*</span>:{" "}
                    </label>
                    <InputText
                      className="p-w-100 p-mt-2"
                      id="pincode"
                      type="text"
                      placeholder="Pincode"
                      value={pincode}
                      autoFocus={true}
                      onChange={(e) => handleChange(e, "pincode")}
                    />
                  </div>
                  <span className="error-msg" style={{ color: "red" }}>
                    {pincodeErr ? pincodeErrText : null}
                  </span>
                </div>
              </div>
              <div className="p-col-12 p-md-4 p-pt-2">
                <div className="p-w-100 ">
                  <div className="custom-inputs">
                    <label htmlFor="websiteURL">
                      {" "}
                      Website URL <span style={{ color: "red" }}>*</span>:{" "}
                    </label>
                    <InputText
                      className="p-w-100 p-mt-2"
                      id="websiteURL"
                      type="text"
                      placeholder="Website URL"
                      value={websiteURL}
                      autoFocus={true}
                      onChange={(e) => handleChange(e, "websiteURL")}
                    />
                  </div>
                  <span className="error-msg" style={{ color: "red" }}>
                    {websiteURLErr ? websiteURLErrText : null}
                  </span>
                </div>
              </div>
              <div className="p-col-12 p-md-4 p-pt-2">
                <div className="p-w-100 ">
                  <div className="custom-inputs">
                    <label htmlFor="companyEmail">
                      {" "}
                      Company email <span style={{ color: "red" }}>
                        *
                      </span>:{" "}
                    </label>
                    <InputText
                      className="p-w-100 p-mt-2"
                      id="companyEmail"
                      type="text"
                      placeholder="Company email"
                      value={companyEmail}
                      autoFocus={true}
                      onChange={(e) => handleChange(e, "companyEmail")}
                      keyfilter={"email"}
                    />
                  </div>
                  <span className="error-msg" style={{ color: "red" }}>
                    {companyEmailErr ? companyEmailErrText : null}
                  </span>
                </div>
              </div>
              <div className="p-col-12 p-md-4">
                <div className="p-w-100 ">
                  <div className="custom-inputs">
                    <label htmlFor="registerDate">
                      Register date <span style={{ color: "red" }}>*</span>:{" "}
                    </label>
                    <Calendar
                      className="p-w-100 p-mt-2"
                      id="registerDate"
                      type="date"
                      placeholder="Register date "
                      value={registerDate}
                      onChange={(e) => handleChange(e, "registerDate")}
                      showIcon
                      dateFormat="dd/mm/yy"
                    />
                  </div>
                  <span className="error-msg" style={{ color: "red" }}>
                    {registerDateErr ? registerDateErrText : null}
                  </span>
                </div>
              </div>
              <div className="card-footer">
                <Button
                  label="Submit"
                  severity="secondary"
                  raised
                  onClick={(e) => AddUpdateCompanyMaster(e)}
                />
                <Button
                  label="Cancel"
                  severity="warning"
                  raised
                  onClick={() => resetScreen()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCompanyMaster;
