import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import api from "../../utils/apiClient";
import CommonConfig from "../../utils/constant";
import APIConstant from "../../utils/pathConstants";
import Toast from "../../components/Shared/Toast/Toast";
import Loader from "../../components/Shared/Loader/Loader";
import { Button } from "primereact/button";

const UserPlanDetails = (props) => {
  const [securityUserID, setSecurityUserID] = useState(null);
  const [globalFilter, setGlobalFilter] = useState("");
  const [userList, setUserList] = useState([]);
  const [isViewUser, setIsViewUser] = useState(false);
  const [viewUserData, setViewUserData] = useState({});

  useEffect(() => {
    const loginData = CommonConfig.loginData();
    setSecurityUserID(loginData?.UserId);
    getAdminUserPlan(loginData.UserId);
  }, []);

  const handleOpen = async (item, type) => {
    if (type === "EditUser") {
      props.navigate("/editUserPlan", {
        state: {
          UserID: item?.UserID,
          PlanId: item?.userPlanDetailsId,
          IsEdit: true,
        },
      });
    } else if (type === "ViewUser") {
      props.navigate("/viewUserPlan", {
        state: { UserID: item.UserID, userPlanDetailList: item },
      });
      setIsViewUser(true);
      setViewUserData(item);
    }
  };

  const getAdminUserPlan = async (ID) => {
    try {
      let data = {
        // UserID: ID,
      };
      await api
        .post(APIConstant.path.GetAdminUserPlan, data)
        .then(async (response) => {
          let res = response;
          if (res.success) {
            setUserList(res.data);
            Loader.hide();
          }
        })
        .catch((err) => {
          Loader.hide();
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="table-icon">
        <Button
          className=""
          onClick={() => handleOpen(rowData, "ViewUser")}
          tooltip={"View"}
          tooltipOptions={{ className: "bluegray-tooltip", position: "top" }}
        >
          <i className="icon-view"></i>
        </Button>
        <Button
          className=""
          onClick={() => handleOpen(rowData, "EditUser")}
          tooltip={"Edit"}
          tooltipOptions={{ className: "bluegray-tooltip", position: "top" }}
        >
          <i className="icon-edit"></i>
        </Button>
      </div>
    );
  };

  const actionBodyTemplateSrNo = (data, props) => {
    return props.rowIndex + 1;
  };

  return (
    <div className="user-container">
      <div className="card-header">
        <div className="card-title p-d-lg-flex p-ai-center p-w-100">
          <h2 className="p-mb-3 p-mb-lg-0">User Plan Details</h2>
          <div className="p-ml-auto p-d-lg-flex p-ai-center">
            <span className="searchbar-area">
              <InputText
                type="search"
                onChange={(e) => setGlobalFilter(e.target.value)}
                placeholder="Global Search"
                size="30"
              />
              <i className="icon-search"></i>
            </span>
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="report-custom-table policy-custom-table">
          <DataTable
            globalFilter={globalFilter}
            paginator
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            rows={10}
            rowsPerPageOptions={[5, 10, 25, 50]}
            className="custom-table"
            value={userList}
            scrollable
            scrollHeight="calc(100vh - 203px)"
          >
            <Column
              field="Index"
              header="Sr.No"
              body={actionBodyTemplateSrNo}
              style={{ maxWidth: 150 }}
            ></Column>
            <Column field="Name" header="Name" sortable></Column>
            <Column field="Email" header="Email" sortable></Column>
            <Column
              field="PhoneNumber"
              header="Mobile Number"
              sortable
            ></Column>
            <Column field="PlanName" header="Plan Name" sortable></Column>
            <Column
              field="StartDateValue"
              header="Start Date"
              sortable
            ></Column>
            <Column field="EndDateValue" header="End Date" sortable></Column>

            <Column field="UserStatus" header="Plan Status" sortable></Column>
            <Column header="Action" body={actionBodyTemplate}></Column>
          </DataTable>
        </div>
      </div>
    </div>
  );
};

export default UserPlanDetails;
