import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import api from "../../utils/apiClient";
import CommonConfig from "../../utils/constant";
import APIConstant from "../../utils/pathConstants";
import Toast from "../../components/Shared/Toast/Toast";
import Loader from "../../components/Shared/Loader/Loader";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";

const Service = (props) => {
  const [securityUserID, setSecurityUserID] = useState(null);

  const [serviceID, setServiceID] = useState(null);

  const [serviceName, setServiceName] = useState("");
  const [serviceNameErr, setServiceNameErr] = useState(false);
  const [serviceNameErrText, setServiceNameErrText] = useState("");

  const [serviceDescription, setServiceDescription] = useState("");
  const [serviceDescriptionErr, setServiceDescriptionErr] = useState(false);
  const [serviceDescriptionErrText, setServiceDescriptionErrText] =
    useState("");

  const [servicePrice, setServicePrice] = useState("");
  const [servicePriceErr, setServicePriceErr] = useState(false);
  const [servicePriceErrText, setServicePriceErrText] = useState("");

  const [globalFilter, setGlobalFilter] = useState("");
  const [servicesList, setServicesList] = useState([]);
  const [accessMaster, setAccessMaster] = useState(false);
  const [viewService, setViewService] = useState(false);
  const [isDeleteService, setIsDeleteService] = useState(false);
  const [viewServiceData, setViewServiceData] = useState({});

  const [addService, setAddService] = useState(false);

  useEffect(() => {
    const loginData = CommonConfig.loginData();
    setSecurityUserID(loginData?.SecurityUserID);
    GetServices(loginData.SecurityUserID);
  }, []);

  const resetScreen = async () => {
    setSecurityUserID(null);
    setAddService(false);
    setServiceID("");
    setServiceName("");
    setServiceNameErr(false);
    setServiceNameErrText("");

    setServiceDescription("");
    setServiceDescriptionErr(false);
    setServiceDescriptionErrText("");

    setServicePrice("");
    setServicePriceErr(false);
    setServicePriceErrText("");
    // props.navigate("/lead");
  };

  const handleChange = (e, type) => {
    if (type === "serviceName") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setServiceName("");
        setServiceNameErr(true);
        setServiceNameErrText("Service name is required");
      } else {
        setServiceName(e.target.value);
        setServiceNameErr(false);
        setServiceNameErrText("");
      }
    } else if (type === "serviceDescription") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setServiceDescription("");
        setServiceDescriptionErr(true);
        setServiceDescriptionErrText("Service description is required");
      } else {
        setServiceDescription(e.target.value);
        setServiceDescriptionErr(false);
        setServiceDescriptionErrText("");
      }
    } else if (type === "servicePrice") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setServicePrice("");
        setServicePriceErr(true);
        setServicePriceErrText("Service price is required");
      } else {
        setServicePrice(e.target.value);
        setServicePriceErr(false);
        setServicePriceErrText("");
      }
    }
  };

  const AddUpdateService = async (e) => {
    if (validate(e)) {
      try {
        let data = {
          ServiceId: serviceID,
          ServiceName: serviceName,
          ServiceDescription: serviceDescription,
          ServicePrice: servicePrice,
        };

        Loader.show();
        await api
          .post(APIConstant.path.AddUpdateAdminService, data)
          .then((response) => {
            let res = response;

            if (res.success) {
              Toast.success({ message: res.message });
              GetServices("");
              resetScreen();
              Loader.hide();
            } else {
              Toast.error({ message: res.message });
              Loader.hide();
            }
          })
          .catch((err) => {});
      } catch (err) {
        console.log(err);
      }
    }
  };

  const validate = () => {
    let formIsValid = true;
    if (CommonConfig.isEmpty(serviceName)) {
      formIsValid = false;
      setServiceNameErr(true);
      setServiceNameErrText("Service name is required");
    } else {
      setServiceNameErr(false);
      setServiceNameErrText("");
    }

    if (CommonConfig.isEmpty(serviceDescription)) {
      formIsValid = false;
      setServiceDescriptionErr(true);
      setServiceDescriptionErrText("Service description is required");
    } else {
      setServiceDescriptionErr(false);
      setServiceDescriptionErrText("");
    }

    if (CommonConfig.isEmpty(servicePrice)) {
      formIsValid = false;
      setServicePriceErr(true);
      setServicePriceErrText("Service price is required");
    } else {
      setServicePriceErr(false);
      setServicePriceErrText("");
    }
    return formIsValid;
  };

  const DeleteAdminService = async () => {
    try {
      Loader.show();
      let data = {
        ServiceId: serviceID,
      };
      await api
        .post(APIConstant.path.DeleteAdminService, data)
        .then(async (res) => {
          if (res.success) {
            handleClose("DeleteService");
            GetServices("");
            Loader.hide();
          } else {
            Loader.hide();
          }
        })
        .catch((err) => {
          Loader.hide();
          Toast.error({ message: err });
        });
    } catch (err) {
      Loader.hide();
      Toast.error({ message: err });
    }
  };

  const GetServices = async (ID) => {
    try {
      let data = {
        UserID: ID,
      };
      await api
        .post(APIConstant.path.GetAdminServices, data)
        .then(async (response) => {
          let res = response;
          if (res.success) {
            setServicesList(res.data);
            Loader.hide();
          }
        })
        .catch((err) => {
          Loader.hide();
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleClose = async (type) => {
    if (type === "AddService") {
      setAddService(false);
      setServiceName("");
      setServiceNameErr(false);
      setServiceNameErrText("");

      setServiceDescription("");
      setServiceDescriptionErr(false);
      setServiceDescriptionErrText("");

      setServicePrice("");
      setServicePriceErr(false);
      setServicePriceErrText("");
      setServiceID("");
    } else if (type === "EditService") {
      // setAddService(false);
    } else if (type === "DeleteService") {
      setServiceID(null);
      setIsDeleteService(false);
    } else if (type === "ViewService") {
      setViewService(false);
      setAccessMaster(false);
      setViewServiceData({});
    }
  };

  const handleOpen = (item, type) => {
    if (type === "AddService") {
      setAddService(true);
    } else if (type === "EditService") {
      setServiceID(item._id);
      setServiceName(item.ServiceName);
      setServiceDescription(item.ServiceDesc);
      setServicePrice(item.ServicePrice);
      setAddService(true);
    } else if (type === "DeleteService") {
      setServiceID(item._id);
      setIsDeleteService(true);
    } else if (type === "ViewService") {
      setViewService(true);
      setAccessMaster(true);
      setViewServiceData(item);
    } else if (type === "SubService") {
      props.navigate("/subservice", { state: { ServiceId: item._id } });
    } else if (type === "AccessMaster") {
      props.navigate("/accessMaster", { state: { ServiceId: item._id } });
    }
  };

  const actionBodyTemplateSrNo = (data, props) => {
    return props.rowIndex + 1;
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="table-icon">
        <Button
          type="button"
          tooltip={"ViewService"}
          tooltipOptions={{ className: "bluegray-tooltip", position: "top" }}
          onClick={(e) => handleOpen(rowData, "ViewService")}
        >
          <i className="icon-view"></i>
        </Button>
        <Button
          className=""
          onClick={() => handleOpen(rowData, "EditService")}
          tooltip={"Edit"}
          tooltipOptions={{ className: "bluegray-tooltip", position: "top" }}
        >
          <i className="icon-edit"></i>
        </Button>

        <Button
          className=""
          onClick={() => handleOpen(rowData, "DeleteService")}
          tooltip={"Delete"}
          tooltipOptions={{ className: "bluegray-tooltip", position: "top" }}
        >
          <i className="icon-delete"></i>
        </Button>
        <Button
          type="button"
          tooltip={"AccessMaster"}
          tooltipOptions={{ className: "bluegray-tooltip", position: "top" }}
          onClick={(e) => handleOpen(rowData, "AccessMaster")}
        >
          <i className="pi pi-database"></i>
        </Button>
        <Button
          type="button"
          onClick={(e) => handleOpen(rowData, "SubService")}
          tooltip={"SubService"}
          tooltipOptions={{ className: "bluegray-tooltip", position: "top" }}
        >
          <i className="pi pi-file"></i>
        </Button>
      </div>
    );
  };

  return (
    <div className="user-container">
      <div className="card-header">
        <div className="card-title p-d-lg-flex p-ai-center p-w-100">
          <h3 className="p-mb-3 p-mb-lg-0">Service</h3>
          <div className="p-ml-auto p-d-lg-flex p-ai-center">
            <span className="searchbar-area">
              <InputText
                type="search"
                onChange={(e) => setGlobalFilter(e.target.value)}
                placeholder="Global Search"
                size="30"
              />
              <i className="icon-search"></i>
            </span>
          </div>
          <div className="card-actions p-ml-2">
            <Button
              type="button"
              className="add-btn add-btn p-button p-component p-jc-center p-w-100"
              onClick={(e) => handleOpen(e, "AddService")}
            >
              <i className="icon-add"></i> Add Service
            </Button>
          </div>
        </div>
      </div>
      <div className="card">
        <DataTable
          className="custom-table"
          value={servicesList}
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
        >
          <Column
            field="Index"
            header="Sr.No"
            body={actionBodyTemplateSrNo}
            style={{ maxWidth: 150 }}
          ></Column>
          <Column
            field="ServiceName"
            header="Service Name"
            style={{ width: "25%" }}
          ></Column>
          {/* <Column field="ServiceDescription" header="Srvice description" style={{ width: '25%' }}></Column> */}
          <Column
            field="ServicePrice"
            header="Service Price"
            style={{ width: "25%" }}
          ></Column>
          <Column header="Action" body={actionBodyTemplate}></Column>
        </DataTable>
      </div>

      {/* Add Confirm Modal */}
      <Dialog
        header={serviceID ? "Edit Service" : "Add Service"}
        visible={addService}
        className="dialog-popup"
        // onHide={handleClose}
        onHide={(e) => handleClose("AddService")}
        draggable={false}
        closable={false}
        position="top"
      >
        <div>
          <div className="card-header">
            <div className="card-title p-d-lg-flex p-ai-center p-w-100">
              <h3 className="p-mb-3 p-mb-lg-0">
                {serviceID ? "Edit" : "Add"} Service
              </h3>
            </div>
          </div>
          <div className="card-body">
            <div className="form-area">
              <div className={"p-grid"}>
                <div className="p-col-12 p-md-4 p-pt-2">
                  <div className="p-w-100 ">
                    <div className="custom-inputs">
                      <label htmlFor="serviceName">
                        Service name <span style={{ color: "red" }}>*</span>:
                      </label>
                      <InputText
                        className="p-w-100 p-mt-2"
                        id="serviceName"
                        type="text"
                        placeholder="Service name"
                        value={serviceName}
                        autoFocus={true}
                        onChange={(e) => handleChange(e, "serviceName")}
                        maxLength={30}
                      />
                    </div>
                    <span className="error-msg" style={{ color: "red" }}>
                      {serviceNameErr ? serviceNameErrText : null}
                    </span>
                  </div>
                </div>

                <div className="p-col-12 p-md-4 p-pt-2">
                  <div className="p-w-100 ">
                    <div className="custom-inputs">
                      <label htmlFor="serviceDescription">
                        Service description{" "}
                        <span style={{ color: "red" }}>*</span>:
                      </label>
                      <InputTextarea
                        className="p-w-100 p-mt-2"
                        id="serviceDescription"
                        value={serviceDescription}
                        placeholder="Service description "
                        onChange={(e) => handleChange(e, "serviceDescription")}
                        rows={1}
                        cols={30}
                      />
                    </div>
                    <span className="error-msg" style={{ color: "red" }}>
                      {serviceDescriptionErr ? serviceDescriptionErrText : null}
                    </span>
                  </div>
                </div>

                <div className="p-col-12 p-md-4 p-pt-2">
                  <div className="p-w-100 ">
                    <div className="custom-inputs">
                      <label htmlFor="servicePrice">
                        Service price <span style={{ color: "red" }}>*</span>:{" "}
                      </label>
                      <InputText
                        className="p-w-100 p-mt-2"
                        id="servicePrice"
                        type="text"
                        placeholder="Service price"
                        value={servicePrice}
                        autoFocus={true}
                        onChange={(e) => handleChange(e, "servicePrice")}
                        maxLength={30}
                      />
                    </div>
                    <span className="error-msg" style={{ color: "red" }}>
                      {servicePriceErr ? servicePriceErrText : null}
                    </span>
                  </div>
                </div>
                <div className="card-footer">
                  <Button
                    label="Submit"
                    severity="secondary"
                    raised
                    onClick={(e) => AddUpdateService(e)}
                  />
                  <button
                    className="btn-dialog-cancel"
                    onClick={(e) => handleClose("AddService")}
                  >
                    cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>

      {/* Delete Confirm Modal */}
      <Dialog
        header="Delete Service"
        visible={isDeleteService}
        className="dialog-popup"
        onHide={(e) => handleClose("DeleteService")}
        draggable={false}
        closable={false}
        position="top"
      >
        <span>Are you sure want to delete the Service record ?</span>
        <div className="dialog-footer">
          <button
            className="btn-dialog-cancel"
            onClick={(e) => handleClose("DeleteService")}
          >
            cancel
          </button>
          <button
            className="btn-dialog-delete"
            onClick={() => DeleteAdminService()}
          >
            Delete
          </button>
        </div>
      </Dialog>

      {/* View Confirm Modal */}
      <Dialog
        header="View Service"
        visible={viewService}
        className="dialog-popup"
        onHide={(e) => handleClose("AddService")}
        draggable={false}
        closable={false}
        position="center"
      >
        <div className="modalBody">
          <div className={"p-grid"}>
            <div className="p-col-12 p-md-12 p-pl-0 p-py-0">
              <div className={"p-grid"}>
                <div className="p-col-12 p-md-6 p-pt-2">
                  <div className="p-w-100 ">
                    <span>
                      <b>Service Name : </b>
                    </span>
                    <p>
                      {" "}
                      {!CommonConfig.isObjectEmpty(viewServiceData) &&
                      !CommonConfig.isEmpty(viewServiceData?.ServiceName)
                        ? viewServiceData?.ServiceName
                        : "-"}
                    </p>
                  </div>
                </div>
                <div className="p-col-12 p-md-6 p-pt-2">
                  <div className="p-w-100 ">
                    <span>
                      <b>Service description : </b>
                    </span>
                    <p>
                      {" "}
                      {!CommonConfig.isObjectEmpty(viewServiceData) &&
                      !CommonConfig.isEmpty(viewServiceData?.ServiceDesc)
                        ? viewServiceData?.ServiceDesc
                        : "-"}
                    </p>
                  </div>
                </div>
                <div className="p-col-12 p-md-6 p-pt-2">
                  <div className="p-w-100 ">
                    <span>
                      <b>Service Price : </b>
                    </span>
                    <p>
                      {" "}
                      {!CommonConfig.isObjectEmpty(viewServiceData) &&
                      !CommonConfig.isEmpty(viewServiceData?.ServicePrice)
                        ? viewServiceData?.ServicePrice
                        : "-"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="dialog-footer">
          <button
            className="btn-dialog-cancel"
            onClick={(e) => handleClose("ViewService")}
          >
            cancel
          </button>
        </div>
      </Dialog>
    </div>
  );
};

export default Service;
