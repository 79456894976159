import { apiBase } from "./config";
const SERVER_PATH = apiBase;

export default class APIConstant {
  static path = {
    login: `${SERVER_PATH}/authentication/Login`,
    ChangePassword: `${SERVER_PATH}/authentication/ChangePassword`,

    GetStringMap: `${SERVER_PATH}/common/GetStringMap`,
    GetUserRole: `${SERVER_PATH}/userRole/GetUserRole`,
    getUserListByAccountType: `${SERVER_PATH}/userRole/getUserListByAccountType`,
    getUserPlanByAccountAndUserType: `${SERVER_PATH}/userRole/getUserPlanByAccountAndUserType`,

    // Dashboard API
    getDashboardData: `${SERVER_PATH}/dashboard/getDashboardData`,
    getAdminDashboardUser: `${SERVER_PATH}/dashboard/getAdminDashboardUser`,

    //Currency Master

    AddUpdateAdminCurrencyMaster: `${SERVER_PATH}/globalmaster/AddUpdateAdminCurrencyMaster`,
    GetAdminCurrencyMaster: `${SERVER_PATH}/globalmaster/GetAdminCurrencyMaster`,
    DeleteAdminCurrencyMaster: `${SERVER_PATH}/globalmaster/DeleteAdminCurrencyMaster`,

    //Country Master
    AddUpdateAdminCountryMaster: `${SERVER_PATH}/globalmaster/AddUpdateAdminCountryMaster`,
    GetAdminCountryMaster: `${SERVER_PATH}/globalmaster/GetAdminCountryMaster`,
    DeleteAdminCountryMaster: `${SERVER_PATH}/globalmaster/DeleteAdminCountryMaster`,

    // User Type Master
    AddUpdateAdminUserType: `${SERVER_PATH}/globalmaster/AddUpdateAdminUserType`,
    GetAdminUserType: `${SERVER_PATH}/globalmaster/GetAdminUserType`,
    EnableDisableAdminUserType: `${SERVER_PATH}/globalmaster/EnableDisableAdminUserType`,
    DeleteAdminUserType: `${SERVER_PATH}/globalmaster/DeleteAdminUserType`,

    // Add plan
    AddUpdateAdminPlan: `${SERVER_PATH}/plan/AddUpdateAdminPlan`,
    GetAdminPlan: `${SERVER_PATH}/plan/GetAdminPlan`,
    DeleteAdminPlan: `${SERVER_PATH}/plan/DeleteAdminPlan`,
    GetAdminPlanById: `${SERVER_PATH}/plan/GetAdminPlanById`,

    //  user
    AddUpdateUser: `${SERVER_PATH}/user/AddUpdateUser`,
    getUser: `${SERVER_PATH}/user/getUser`,
    getUserById: `${SERVER_PATH}/user/getUserById`,
    DeleteUser: `${SERVER_PATH}/user/DeleteUser`,
    updateUserStatus: `${SERVER_PATH}/user/updateUserStatus`,

    // User plan Details
    UpdateAdminUserPlanDetails: `${SERVER_PATH}/userplan/UpdateAdminUserPlanDetails`,
    GetAdminUserPlan: `${SERVER_PATH}/userplan/GetAdminUserPlan`,
    GetUserPlanDetailById: `${SERVER_PATH}/userplan/GetUserPlanDetailById`,

    // Company Master
    AddUpdateCompanyMaster: `${SERVER_PATH}/companymaster/AddUpdateCompanyMaster`,
    GetCompanyMaster: `${SERVER_PATH}/companymaster/GetCompanyMaster`,
    GetAdminCompanyMasterById: `${SERVER_PATH}/companymaster/GetAdminCompanyMasterById`,
    DeleteCompanyMaster: `${SERVER_PATH}/companymaster/DeleteCompanyMaster`,
    GetCompanyDetailByGST: `${SERVER_PATH}/companymaster/GetCompanyDetailByGST`,
    GetCompanyDetailByPAN: `${SERVER_PATH}/companymaster/GetCompanyDetailByPAN`,

    //Manage Access
    getEntityList: `${SERVER_PATH}/useraccess/getEntityList`,
    getEntityWiseServicesList: `${SERVER_PATH}/useraccess/getEntityWiseServicesList`,
    AddUpdateEntityWiseServiceOrder: `${SERVER_PATH}/useraccess/AddUpdateEntityWiseServiceOrder`,
    getEntityWiseServicesOrderList: `${SERVER_PATH}/useraccess/getEntityWiseServicesOrderList`,
    updateAdminEntityWiseServices: `${SERVER_PATH}/useraccess/updateAdminEntityWiseServices`,
    getAdminUserList: `${SERVER_PATH}/useraccess/getAdminUserList`,
    getUserWiseServicesList: `${SERVER_PATH}/useraccess/getUserWiseServicesList`,
    getUserWiseServicesOrderList: `${SERVER_PATH}/useraccess/getUserWiseServicesOrderList`,
    AddUpdateUserWiseServiceOrder: `${SERVER_PATH}/useraccess/AddUpdateUserWiseServiceOrder`,
    updateAdminUserWiseServices: `${SERVER_PATH}/useraccess/updateAdminUserWiseServices`,

    // Service

    AddUpdateAdminService: `${SERVER_PATH}/services/AddUpdateAdminService`,
    GetAdminServices: `${SERVER_PATH}/services/GetAdminServices`,
    DeleteAdminService: `${SERVER_PATH}/services/DeleteAdminService`,
    AddUpdateAdminSubService: `${SERVER_PATH}/services/AddUpdateAdminSubService`,
    GetAdminSubServices: `${SERVER_PATH}/services/GetAdminSubServices`,
    DeleteAdminSubService: `${SERVER_PATH}/services/DeleteAdminSubService`,

    // Service access
    AddUpdateAdminServiceAccess: `${SERVER_PATH}/services/AddUpdateAdminServiceAccess`,
    GetAdminServiceAccessMaster: `${SERVER_PATH}/services/GetAdminServiceAccessMaster`,
    GetAdminServicesAccess: `${SERVER_PATH}/services/GetAdminServicesAccess`,
    DeleteAdminServiceAccess: `${SERVER_PATH}/services/DeleteAdminServiceAccess`,

    // HSN Master
    AddUpdateAdminHSNCodeMaster: `${SERVER_PATH}/globalmaster/AddUpdateAdminHSNCodeMaster`,
    GetAdminHSNCodeMaster: `${SERVER_PATH}/globalmaster/GetAdminHSNCodeMaster`,
    GetAdminHSNCodeMasterById: `${SERVER_PATH}/globalmaster/GetAdminHSNCodeMasterById`,
    DeleteAdminHSNCodeMaster: `${SERVER_PATH}/globalmaster/DeleteAdminHSNCodeMaster`,
  };
}
