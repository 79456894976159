import React, { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import api from "../../utils/apiClient";
import CommonConfig from "../../utils/constant";
import APIConstant from "../../utils/pathConstants";
import Toast from '../../components/Shared/Toast/Toast';
import Loader from '../../components/Shared/Loader/Loader';
import { Button } from 'primereact/button';

const Plan = (props) => {

  const [securityUserID, setSecurityUserID] = useState(null);
  const [globalFilter, setGlobalFilter] = useState('');
  const [planId, setPlanId] = useState(null);
  const [planList, setPlanList] = useState([]);
  const [isDeletePlan, setIsDeletePlan] = useState(false);
  const [isViewPlan, setIsViewPlan] = useState(false);
  const [viewPlanData, setViewPlanData] = useState({});


  useEffect(() => {
    const loginData = CommonConfig.loginData();
    setSecurityUserID(loginData?.SecurityUserID);
    GetAdminPlan(loginData.SecurityUserID);
  }, []);

  const resetScreen = async () => {
    setPlanId(null);
    setSecurityUserID(null);
    setIsDeletePlan(false);
    setIsViewPlan(false);
    setViewPlanData({});

    setSecurityUserID(CommonConfig.loginData()?.SecurityUserID);
    GetAdminPlan(CommonConfig.loginData()?.SecurityUserID);
  };

  const handleOpen = async (item, type) => {
    if (type === 'AddPlan') {
      props.navigate('/create-plan')
    } 
    else if (type === 'EditPlan') {
      props.navigate('/create-plan',   { state: { PlanID: item.PlanId }})    
    } else if (type === 'DeletePlan') {
      setPlanId(item.PlanId);
      setIsDeletePlan(true);
    } else if (type === 'ViewPlan') {
      setIsViewPlan(true);
      setViewPlanData(item);
    }
  };

  const handleClose = async (type) => {
    if (type === 'DeletePlan') {
      setPlanId('');
      setIsDeletePlan(false);
    } else if (type === 'ViewPlan') {
      setIsViewPlan(false);
      setViewPlanData({});
    }
    resetScreen();
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className='table-icon'>
        <Button className='' onClick={() => handleOpen(rowData, 'ViewPlan')} tooltip={"View"} tooltipOptions={{ className: 'bluegray-tooltip', position: 'top' }}>
          <i className='icon-view'></i>
        </Button>
        <Button className='' onClick={() => handleOpen(rowData, 'EditPlan')} tooltip={"Edit"} tooltipOptions={{ className: 'bluegray-tooltip', position: 'top' }}>
          <i className='icon-edit'></i>
        </Button>
        <Button className='' onClick={() => handleOpen(rowData, "DeletePlan")} tooltip={"Delete"} tooltipOptions={{ className: 'bluegray-tooltip', position: 'top' }}>
          <i className='icon-delete'  ></i>
        </Button>
      </div>
    )
  }

  const actionBodyTemplateSrNo = (data, props) => {
    return props.rowIndex + 1;
  };

  const DeleteAdminPlan = async () => {
    try {
      Loader.show();
      let data = {
        UserID: securityUserID,
        PlanId: planId,
      };
      await api
        .post(APIConstant.path.DeleteAdminPlan, data).then(async (res) => {
          let response = await res;
          if (response.success) {
            handleClose('DeletePlan')
            resetScreen();
            Loader.hide();
          } else {
            Loader.hide();
          }
        })
        .catch((err) => {
          Loader.hide();
          Toast.error({ message: err });
        });
    } catch (err) {
      Loader.hide();
      Toast.error({ message: err });
    }
  }
  const GetAdminPlan = async () => {
    try {
      let data = {
        // UserID: ID,
      }
      await api.post(APIConstant.path.GetAdminPlan, data).then(async response => {
        let res = response;
        if (res.success) {
          setPlanList(res.data);
          Loader.hide();
        }
      }).catch(err => {
        Loader.hide();
        console.log(err);
      });
    } catch (err) { console.log(err); }

  }

  return (
    <div className='user-container'>
      <div className='card-header'>
        <div className='card-title p-d-lg-flex p-ai-center p-w-100'>
          <h3 className="p-mb-3 p-mb-lg-0">Plan</h3>
          <div className="p-ml-auto p-d-lg-flex p-ai-center">
            <span className="searchbar-area">
              <InputText type="search" onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" size="30" />
              <i className="icon-search"></i>
            </span>
          </div>
          <div className='card-actions p-ml-2'>
            <Button type="button" className='add-btn add-btn p-button p-component p-jc-center p-w-100' onClick={(e) => handleOpen(e, 'AddPlan')}>
              <i className="icon-add"></i> Add plan
            </Button>
          </div>
        </div>
      </div>
      <div className='card-body'>
        <div className='report-custom-table policy-custom-table'>
          <DataTable globalFilter={globalFilter} paginator paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[5, 10, 25, 50]} className='custom-table' value={planList} scrollable scrollHeight="calc(100vh - 203px)">
            <Column field="Index" header="Sr.No" body={actionBodyTemplateSrNo} style={{ maxWidth: 150 }}></Column>
            <Column field="PlanName" header="Plan name" sortable></Column>
            <Column field="AccountType" header="Account Type" sortable></Column>
            <Column field="PlanDiscPrice" header="Plan discount price" sortable></Column>
            <Column field="RoleName" header="User Type" sortable></Column>
            <Column field="PlanDuration" header="Plan Duration" sortable></Column>
            <Column field="PlanDesc" header="Plan description" sortable></Column>
            <Column field="PlanTotalPrice" header="Total Price" sortable></Column>
            <Column header="Action" body={actionBodyTemplate} ></Column>
          </DataTable>
        </div>
      </div>
      {/* Delete Confirm Modal */}
      <Dialog
        header="Delete Plan"
        visible={isDeletePlan}
        className='dialog-popup'
        onHide={(e) => handleClose('DeletePlan')}
        draggable={false}
        closable={false}
        position="top"
      >
        <span>Are you sure want to delete the plan record from system ?</span>
        <div className='dialog-footer'>
          <button className='btn-dialog-cancel' onClick={(e) => handleClose('DeletePlan')}>
            cancel
          </button>
          <button className='btn-dialog-delete' onClick={() => DeleteAdminPlan()}>
            Delete
          </button>
        </div>
      </Dialog>
      {/* View Confirm Modal */}
      <Dialog
        header="View plan"
        visible={isViewPlan}
        className='dialog-popup'
        onHide={(e) => handleClose('ViewPlan')}
        draggable={false}
        closable={false}
        position="center"
      >
        <div className='modalBody'>
          <div className={"p-grid"}>
            <div className="p-col-12 p-md-12 p-pl-0 p-py-0">
              <div className={"p-grid"}>
                <div className="p-col-12 p-md-6 p-pt-2">
                  <div className="p-w-100 ">
                    <span><b>Plan Name : </b></span>
                    <p> {!CommonConfig.isObjectEmpty(viewPlanData) && !CommonConfig.isEmpty(viewPlanData?.PlanName) ? viewPlanData?.PlanName : '-'}</p>
                  </div>
                </div>
                <div className="p-col-12 p-md-6 p-pt-2">
                  <div className="p-w-100 ">
                    <span><b>Plan discount Price : </b></span>
                    <p> {!CommonConfig.isObjectEmpty(viewPlanData) && !CommonConfig.isEmpty(viewPlanData?.PlanDiscPrice) ? viewPlanData?.PlanDiscPrice : '-'}</p>
                  </div>
                </div>
                <div className="p-col-12 p-md-6 p-pt-2">
                  <div className="p-w-100 ">
                    <span><b>User Type : </b></span>
                    <p> {!CommonConfig.isObjectEmpty(viewPlanData) && !CommonConfig.isEmpty(viewPlanData?.RoleName) ? viewPlanData?.RoleName : '-'}</p>
                  </div>
                </div>
                <div className="p-col-12 p-md-6 p-pt-2">
                  <div className="p-w-100 ">
                    <span><b>Account Type : </b></span>
                    <p> {!CommonConfig.isObjectEmpty(viewPlanData) && !CommonConfig.isEmpty(viewPlanData?.AccountType) ? viewPlanData?.AccountType : '-'}</p>
                  </div>
                </div>
                <div className="p-col-12 p-md-6 p-pt-2">
                  <div className="p-w-100 ">
                    <span><b>Plan Duration : </b></span>
                    <p> {!CommonConfig.isObjectEmpty(viewPlanData) && !CommonConfig.isEmpty(viewPlanData?.PlanDuration) ? viewPlanData?.PlanDuration : '-'}</p>
                  </div>
                </div>
                <div className="p-col-12 p-md-6 p-pt-2">
                  <div className="p-w-100 ">
                    <span><b>Plan description : </b></span>
                    <p> {!CommonConfig.isObjectEmpty(viewPlanData) && !CommonConfig.isEmpty(viewPlanData?.PlanDesc) ? viewPlanData?.PlanDesc : '-'}</p>
                  </div>
                </div>
                <div className="p-col-12 p-md-6 p-pt-2">
                  <div className="p-w-100 ">
                    <span><b>Plan Total Price : </b></span>
                    <p> {!CommonConfig.isObjectEmpty(viewPlanData) && !CommonConfig.isEmpty(viewPlanData?.PlanTotalPrice) ? viewPlanData?.PlanTotalPrice : '-'}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='dialog-footer'>
          <button className='btn-dialog-cancel' onClick={(e) => handleClose('ViewPlan')}>
            cancel
          </button>
        </div>
      </Dialog>
    </div>
  )
}

export default Plan