import React, { useEffect, useState } from "react";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import api from "../../utils/apiClient";
import CommonConfig from "../../utils/constant";
import APIConstant from "../../utils/pathConstants";
import Toast from "../../components/Shared/Toast/Toast";
import Loader from "../../components/Shared/Loader/Loader";
import { Button } from "primereact/button";
import Axios from "axios";
// import moment from 'moment/moment';

const EditUserPlan = (props) => {
  const [securityUserID, setSecurityUserID] = useState(null);

  const [users, setUsers] = useState("");
  const [usersErr, setUsersErr] = useState(false);
  const [usersErrText, setUsersErrText] = useState("");

  const [plan, setPlans] = useState("");
  const [planErr, setPlansErr] = useState(false);
  const [planErrText, setPlansErrText] = useState("");
  const [userPlanList, setUserPlanList] = useState([]);

  const [userList, setUserList] = useState([]);

  const [paymentStatus, setPaymentStatus] = useState("");
  const [paymentStatusErr, setPaymentStatusErr] = useState(false);
  const [paymentStatusErrText, setPaymentStatusErrText] = useState("");
  const paymentStatusList = [
    { label: "Paid", value: "Paid" },
    { label: "Unpaid", value: "Unpaid" },
  ];

  const [startDate, setStartDate] = useState("");
  const [startDateErr, setStartDateErr] = useState(false);
  const [startDateErrText, setStartDateErrText] = useState("");

  const [endDate, setEndDate] = useState("");
  const [endDateErr, setEndDateErr] = useState(false);
  const [endDateErrText, setEndDateErrText] = useState("");

  const [paymentDescription, setPaymentDescription] = useState("");
  const [paymentDescriptionErr, setPaymentDescriptionErr] = useState(false);
  const [paymentDescriptionErrText, setPaymentDescriptionErrText] =
    useState("");

  useEffect(() => {
    const loginData = CommonConfig.loginData();
    setSecurityUserID(loginData?.UserId);
    getUserPlanDetailById(props?.location?.state?.UserID);
    GetUserPlanList();
    getUser();
  }, []);

  const getUser = async (ID) => {
    try {
      let data = {
        // UserID: ID,
      };
      await api
        .post(APIConstant.path.getUser, data)
        .then(async (response) => {
          let res = response;
          if (res.success) {
            setUserList(
              res.data.map((role) => ({
                label: role.UserRole,
                value: role.UserId,
              }))
            );
            Loader.hide();
          }
        })
        .catch((err) => {
          Loader.hide();
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const GetUserPlanList = async (ID) => {
    try {
      let data = {
        PlanId: ID,
      };
      await api
        .post(APIConstant.path.GetAdminPlan)
        .then(async (response) => {
          let res = response;
          if (res.success) {
            setUserPlanList(
              res.data.map((plan) => ({
                ...plan,
                label: plan.PlanName,
                value: plan.PlanId,
              }))
            );
            Loader.hide();
          }
        })
        .catch((err) => {
          Loader.hide();
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = (e, type) => {
    if (type === "users") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setUsers("");
        setUsersErr(true);
        setUsersErrText("User Type is required");
      } else {
        setUsers(e.target.value);
        setUsersErr(false);
        setUsersErrText("");
      }
    } else if (type === "plan") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setPlans("");
        setPlansErr(true);
        setPlansErrText("First Name is required");
      } else {
        setPlans(e.target.value);
        setPlansErr(false);
        setPlansErrText("");
      }
    } else if (type === "paymentStatus") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setPaymentStatus("");
        setPaymentStatusErr(true);
        setPaymentStatusErrText("Payment status is required");
      } else {
        setPaymentStatus(e.target.value);
        setPaymentStatusErr(false);
        setPaymentStatusErrText("");
      }
    } else if (type === "startDate") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setStartDate("");
        setStartDateErr(true);
        setStartDateErrText("Start date is required");
        setEndDate("");
      } else {
        setStartDate(e.target.value);
        setStartDateErr(false);
        setStartDateErrText("");
      }
    } else if (type === "endDate") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setEndDate("");
        setEndDateErr(true);
        setEndDateErrText("End date is required");
      } else if (!startDate) {
        setEndDateErr(true);
        setEndDateErrText("Please select a start date first");
      } else if (e.target.value < startDate) {
        setEndDateErr(true);
        setEndDateErrText("End date cannot be earlier than the start date");
      } else {
        setEndDate(e.target.value);
        setEndDateErr(false);
        setEndDateErrText("");
      }
    } else if (type === "paymentDescription") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setPaymentDescription("");
        setPaymentDescriptionErr(true);
        setPaymentDescriptionErrText("Payment description is required");
      } else {
        setPaymentDescription(e.target.value);
        setPaymentDescriptionErr(false);
        setPaymentDescriptionErrText("");
      }
    }
  };

  const validate = () => {
    let formIsValid = true;
    if (CommonConfig.isEmpty(users)) {
      formIsValid = false;
      setUsersErr(true);
      setUsersErrText("Users is required");
    } else {
      setUsersErr(false);
      setUsersErrText("");
    }
    if (CommonConfig.isEmpty(plan)) {
      formIsValid = false;
      setPlansErr(true);
      setPlansErrText("Plans is required");
    } else {
      setPlansErr(false);
      setPlansErrText("");
    }

    if (CommonConfig.isEmpty(paymentStatus)) {
      formIsValid = false;
      setPaymentStatusErr(true);
      setPaymentStatusErrText("Payment status is required");
    } else {
      setPaymentStatusErr(false);
      setPaymentStatusErrText("");
    }

    if (CommonConfig.isEmpty(startDate)) {
      formIsValid = false;
      setStartDateErr(true);
      setStartDateErrText("Start date is required");
    } else {
      setStartDateErr(false);
      setStartDateErrText("");
    }

    if (CommonConfig.isEmpty(endDate)) {
      formIsValid = false;
      setEndDateErr(true);
      setEndDateErrText("End date is required");
    } else {
      setEndDateErr(false);
      setEndDateErrText("");
    }

    if (CommonConfig.isEmpty(paymentDescription)) {
      formIsValid = false;
      setPaymentDescriptionErr(true);
      setPaymentDescriptionErrText("Payment description is required");
    } else {
      setPaymentDescriptionErr(false);
      setPaymentDescriptionErrText("");
    }
    return formIsValid;
  };

  const EditUserPlan = async (e) => {
    if (validate(e)) {
      try {
        let data = {
          UserPlanId: props?.location?.state?.PlanId,
          fkuserID: users,
          FkPlanId: plan,
          // StartDate: moment(startDate).format("YYYY-MM-DD"),
          // EndDate: moment(endDate).format("YYYY-MM-DD"),
          Description: paymentDescription,
          PaymentStatus: paymentStatus,
        };
        Loader.show();
        await api
          .post(APIConstant.path.UpdateAdminUserPlanDetails, data)
          .then((response) => {
            let res = response;

            if (res.success) {
              Toast.success({ message: res.message });

              Loader.hide();
              props.navigate("/userPlanDetails");
            } else {
              Toast.error({ message: res.message });
              Loader.hide();
            }
          })
          .catch((err) => {});
      } catch (err) {
        console.log(err);
      }
    }
  };

  const getUserPlanDetailById = async (ID) => {
    try {
      let data = {
        UserPlanId: ID,
      };
      await api
        .post(APIConstant.path.GetUserPlanDetailById, data)
        .then(async (response) => {
          let res = response;
          if (res.success) {
            // setViewUserData(res.data);
            // setUserID(res.data[0]?.UserId);
            setUsers(res?.data[0]?.UserId);
            setPlans(res?.data[0]?.PlanId);
            setPaymentStatus(res?.data[0]?.PaymentStatus);
            setStartDate(
              res?.data[0]?.StartDate ? new Date(res?.data[0]?.StartDate) : ""
            );
            setEndDate(
              res?.data[0]?.EndDate ? new Date(res?.data[0]?.EndDate) : ""
            );
            setPaymentDescription(res?.data[0]?.PaymentDesc);
            Loader.hide();
          }
        })
        .catch((err) => {
          Loader.hide();
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className="user-container p-d-flex p-flex-column p-jc-between">
      <div>
        <div className="p-d-lg-flex p-jc-between p-pb-2">
          <div className="heading-area">
            {/* <i className="icon-chevron-down back-btn" onClick={() => props.navigate('/plan')}></i> */}
            <h1>
              {props?.location?.state?.IsEdit ? "Edit User Plan" : "Add Plan"}
            </h1>
          </div>
        </div>
        <div className="card-body">
          <div className="form-area">
            <div className={"p-grid"}>
              <div className="p-col-12 p-md-4 p-pt-2">
                <div className="p-w-100 ">
                  <div className="custom-inputs">
                    <label htmlFor="users">
                      Users <span style={{ color: "red" }}>*</span>:{" "}
                    </label>
                    <Dropdown
                      className="p-w-100 p-mt-2"
                      optionLabel="label"
                      value={users}
                      options={userList}
                      onChange={(e) => handleChange(e, "users")}
                      filter
                      placeholder="Users"
                    />
                  </div>
                  <span className="error-msg" style={{ color: "red" }}>
                    {usersErr ? usersErrText : null}
                  </span>
                </div>
              </div>

              <div className="p-col-12 p-md-4 p-pt-2">
                <div className="p-w-100 ">
                  <div className="custom-inputs">
                    <label htmlFor="plan">
                      Plan <span style={{ color: "red" }}>*</span>:{" "}
                    </label>
                    <Dropdown
                      className="p-w-100 p-mt-2"
                      value={plan}
                      options={userPlanList}
                      optionLabel="label"
                      onChange={(e) => handleChange(e, "plan")}
                      filter
                      placeholder="Plan"
                    />
                  </div>
                  <span className="error-msg" style={{ color: "red" }}>
                    {planErr ? planErrText : null}
                  </span>
                </div>
              </div>

              <div className="p-col-12 p-md-4 p-pt-2">
                <div className="p-w-100 ">
                  <div className="custom-inputs">
                    <label htmlFor="paymentStatus">
                      Payment status <span style={{ color: "red" }}>*</span>:{" "}
                    </label>
                    <Dropdown
                      className="p-w-100 p-mt-2"
                      value={paymentStatus}
                      options={paymentStatusList}
                      optionLabel="label"
                      onChange={(e) => handleChange(e, "paymentStatus")}
                      filter
                      placeholder="Payment status"
                    />
                  </div>
                  <span className="error-msg" style={{ color: "red" }}>
                    {paymentStatusErr ? paymentStatusErrText : null}
                  </span>
                </div>
              </div>

              <div className="p-col-12 p-md-4 p-pt-2">
                <div className="p-w-100 ">
                  <div className="custom-inputs">
                    <label htmlFor="startDate">
                      Start date <span style={{ color: "red" }}>*</span>:{" "}
                    </label>
                    <Calendar
                      className="p-w-100 p-mt-2"
                      id="startDate"
                      type="date"
                      value={startDate}
                      onChange={(e) => handleChange(e, "startDate")}
                      showIcon
                      placeholder="Start date"
                      dateFormat="dd/mm/yy"
                    />
                  </div>
                  <span className="error-msg" style={{ color: "red" }}>
                    {startDateErr ? startDateErrText : null}
                  </span>
                </div>
              </div>

              <div className="p-col-12 p-md-4 p-pt-2">
                <div className="p-w-100 ">
                  <div className="custom-inputs">
                    <label htmlFor="endDate">
                      End date <span style={{ color: "red" }}>*</span>:{" "}
                    </label>
                    <Calendar
                      className="p-w-100 p-mt-2"
                      id="endDate"
                      type="date"
                      value={endDate}
                      onChange={(e) => handleChange(e, "endDate")}
                      showIcon
                      placeholder="End date"
                      dateFormat="dd/mm/yy"
                    />
                  </div>
                  <span className="error-msg" style={{ color: "red" }}>
                    {endDateErr ? endDateErrText : null}
                  </span>
                </div>
              </div>

              <div className="p-col-12 p-md-4 p-pt-2">
                <div className="p-w-100 ">
                  <div className="custom-inputs">
                    <label htmlFor="paymentDescription">
                      Payment description{" "}
                      <span style={{ color: "red" }}>*</span>:
                    </label>
                    <InputTextarea
                      className="p-w-100 p-mt-2"
                      id="paymentDescription"
                      type="text"
                      value={paymentDescription}
                      onChange={(e) => handleChange(e, "paymentDescription")}
                      rows={5}
                      cols={30}
                      placeholder="Payment description"
                      autoFocus={true}
                      keyfilter={"alphanum"}
                      maxLength={200}
                    />
                  </div>
                  <span className="error-msg" style={{ color: "red" }}>
                    {paymentDescriptionErr ? paymentDescriptionErrText : null}
                  </span>
                </div>
              </div>

              <div className="card-footer">
                <Button
                  label="Update"
                  severity="secondary"
                  raised
                  onClick={(e) => EditUserPlan(e)}
                />
                <Button
                  label="Cancel"
                  severity="warning"
                  raised
                  onClick={() => props.navigate("/userPlanDetails")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditUserPlan;
