import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import api from "../../utils/apiClient";
import CommonConfig from "../../utils/constant";
import APIConstant from "../../utils/pathConstants";
import Toast from "../../components/Shared/Toast/Toast";
import Loader from "../../components/Shared/Loader/Loader";
import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Dropdown } from "primereact/dropdown";

const planStatusList = [
  {
    label: "AU",
    value: "AU",
  },
  {
    label: "WR",
    value: "WR",
  },
  {
    label: "IAC",
    value: "IAC",
  },
];

const Users = (props) => {
  const [securityUserID, setSecurityUserID] = useState(null);
  const [userID, setUserID] = useState(null);

  const [role, setRole] = useState(null);
  const [globalFilter, setGlobalFilter] = useState("");
  const [userList, setUserList] = useState([]);
  const [isDeleteUser, setIsDeleteUser] = useState(false);
  const [isViewUser, setIsViewUser] = useState(false);
  const [viewUserData, setViewUserData] = useState({});

  const [oldPassword, setOldPassword] = useState("");
  const [oldPasswordErr, setOldPasswordErr] = useState(false);
  const [oldPasswordErrText, setOldPasswordErrText] = useState("");

  const [newPassword, setNewPassword] = useState("");
  const [newPasswordErr, setNewPasswordErr] = useState(false);
  const [newPasswordErrText, setNewPasswordErrText] = useState("");

  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordErr, setConfirmPasswordErr] = useState(false);
  const [confirmPasswordErrText, setConfirmPasswordErrText] = useState("");
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const [accountType, setAccountType] = useState("");
  const [accountTypeList, setAccountTypeList] = useState([]);
  const [planStatus, setPlanStatus] = useState("");

  const [filteredUserList, setFilteredUserList] = useState([]);

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    FirstName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    UserRoll: { value: null, matchMode: FilterMatchMode.CONTAINS },
    PhoneNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
    Email: { value: null, matchMode: FilterMatchMode.CONTAINS },
    CompanyName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    GstNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
    PanNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
    PlanName: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  useEffect(() => {
    const loginData = CommonConfig.loginData();
    setSecurityUserID(loginData?.UserId);
    getUser(loginData.UserId);
    getAccountType();
  }, []);

  const getAccountType = async () => {
    try {
      let data = {
        type: "account_type",
      };
      await api
        .post(APIConstant.path.GetStringMap, data)
        .then(async (response) => {
          let res = response;
          if (res.success) {
            setAccountTypeList(
              res.data.map((data) => ({ label: data.name, value: data._id }))
            );

            Loader.hide();
          }
        })
        .catch((err) => {
          Loader.hide();
          console.log(err);
        });
    } catch (err) {
      console.log("err", err);
    }
  };

  const resetScreen = async () => {
    setUserID(null);
    setUserList([]);
    setRole(null);
    setIsDeleteUser(false);
    setIsViewUser(false);
    setViewUserData({});

    setSecurityUserID(CommonConfig.loginData()?.SecurityUserID);
    setRole(CommonConfig.loginData()?.Role);
  };

  const handleOpen = async (item, type) => {
    if (type === "AddUser") {
      props.navigate("/add-user", { state: { userID: null } });
    } else if (type === "EditUser") {
      props.navigate("/add-user", { state: { userID: item.UserId } });
    } else if (type === "DeleteUser") {
      setUserID(item.UserId);
      setIsDeleteUser(true);
    } else if (type === "ViewUser") {
      setIsViewUser(true);
      setViewUserData(item);
    } else if (type == "ChangePassword") {
      setUserID(item?.UserId);
      setChangePasswordModal(true);
    }
  };

  const handleClose = async (type) => {
    if (type === "AddUser") {
    } else if (type === "EditUser") {
    } else if (type === "DeleteUser") {
      setUserID(null);
      setIsDeleteUser(false);
    } else if (type === "ViewUser") {
      setIsViewUser(false);
      setViewUserData({});
    } else if (type == "ChangePassword") {
      setChangePasswordModal(false);
      setOldPassword("");
      setOldPasswordErr(false);
      setOldPasswordErrText("");

      setNewPassword("");
      setNewPasswordErr(false);
      setNewPasswordErrText("");

      setConfirmPassword("");
      setConfirmPasswordErr(false);
      setConfirmPasswordErrText("");
    }
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="table-icon">
        <Button
          className=""
          onClick={() => handleOpen(rowData, "ViewUser")}
          tooltip={"View"}
          tooltipOptions={{ className: "bluegray-tooltip", position: "top" }}
        >
          <i className="icon-view"></i>
        </Button>
        <Button
          className=""
          onClick={() => handleOpen(rowData, "ChangePassword")}
          tooltip={"Password"}
        >
          <i className="icon-password-lock"></i>
        </Button>
        <Button
          className=""
          onClick={() => handleOpen(rowData, "EditUser")}
          tooltip={"Edit"}
          tooltipOptions={{ className: "bluegray-tooltip", position: "top" }}
        >
          <i className="icon-edit"></i>
        </Button>

        <Button
          className=""
          onClick={() => handleOpen(rowData, "DeleteUser")}
          tooltip={"Delete"}
          tooltipOptions={{ className: "bluegray-tooltip", position: "top" }}
        >
          <i className="icon-delete"></i>
        </Button>
      </div>
    );
  };

  const actionBodyTemplateSrNo = (data, props) => {
    return props.rowIndex + 1;
  };

  const DeleteUser = async () => {
    try {
      Loader.show();
      console.log({ userID });
      let data = {
        UserId: userID,
      };
      await api
        .post(APIConstant.path.DeleteUser, data)
        .then(async (res) => {
          let response = await res;
          if (response.success) {
            await resetScreen();
            Loader.hide();
            getUser(securityUserID);
          } else {
            Loader.hide();
          }
        })
        .catch((err) => {
          Loader.hide();
          Toast.error({ message: err });
        });
    } catch (err) {
      Loader.hide();
      Toast.error({ message: err });
    }
  };

  const getUser = async (ID) => {
    try {
      let data = {
        // UserID: ID,
      };
      await api
        .post(APIConstant.path.getUser, data)
        .then(async (response) => {
          let res = response;
          if (res.success) {
            setUserList(res.data);
            setFilteredUserList(res.data);
            Loader.hide();
          }
        })
        .catch((err) => {
          Loader.hide();
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleChangeFilter = (e, type) => {
    if (type === "AccountType") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setAccountType("");
      } else {
        setAccountType(e.target.value);
        let filterUserList = [];

        filterUserList = userList;
        if (!CommonConfig.isEmpty(planStatus)) {
          filterUserList = filterUserList.filter(
            (x) => x.ATFilter === e.target.value && x.PlanStatus === planStatus
          );
        } else {
          filterUserList = filterUserList.filter(
            (x) => x.ATFilter === e.target.value
          );
        }
        setFilteredUserList(filterUserList);
      }
    } else {
      if (CommonConfig.isEmpty(e.target.value)) {
        setPlanStatus("");
      } else {
        setPlanStatus(e.target.value);
        let filterUserList = [];
        filterUserList = userList;
        if (!CommonConfig.isEmpty(accountType)) {
          filterUserList = filterUserList.filter(
            (x) => x.ATFilter === accountType && x.PlanStatus === e.target.value
          );
        } else {
          filterUserList = filterUserList.filter(
            (x) => x.PlanStatus === e.target.value
          );
        }
        setFilteredUserList(filterUserList);
      }
    }
  };

  const handleChange = (e, type) => {
    if (type === "oldPassword") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setOldPassword("");
        setOldPasswordErr(true);
        setOldPasswordErrText("Old password is required");
      } else {
        setOldPassword(e.target.value);
        setOldPasswordErr(true);
        setOldPasswordErrText("");
      }
    } else if (type === "newPassword") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setNewPassword("");
        setNewPasswordErr(true);
        setNewPasswordErrText("New password is required");
      } else {
        setNewPassword(e.target.value);
        setNewPasswordErr(false);
        setNewPasswordErrText("");
      }
    } else if (type === "confirmPassword") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setConfirmPassword("");
        setConfirmPasswordErr(true);
        setConfirmPasswordErrText("Confirm password is required");
      } else if (newPassword !== e.target.value) {
        setConfirmPassword(e.target.value);
        setConfirmPasswordErr(true);
        setConfirmPasswordErrText("Confirm password does not match ");
      } else {
        setConfirmPassword(e.target.value);
        setConfirmPasswordErr(false);
        setConfirmPasswordErrText("");
      }
    }
  };
  const validateChangePassword = () => {
    let isValid = true;

    if (CommonConfig.isEmpty(oldPassword)) {
      setOldPasswordErr(true);
      setOldPasswordErrText("Old password is required");
      isValid = false;
    } else {
      setOldPasswordErr(true);
      setOldPasswordErrText("");
    }

    if (CommonConfig.isEmpty(newPassword)) {
      setNewPasswordErr(true);
      setNewPasswordErrText("New password is required");
      isValid = false;
    } else {
      setNewPasswordErr(false);
      setNewPasswordErrText("");
    }

    if (CommonConfig.isEmpty(confirmPassword)) {
      setConfirmPasswordErr(true);
      setConfirmPasswordErrText("Confirm password is required");
      isValid = false;
    } else if (newPassword !== confirmPassword) {
      setConfirmPasswordErr(true);
      setConfirmPasswordErrText("Confirm password does not match ");
      isValid = false;
    } else {
      setConfirmPasswordErr(false);
      setConfirmPasswordErrText("");
    }
    return isValid;
  };

  const handlePassword = (e) => {
    e.preventDefault();
    if (validateChangePassword()) {
      try {
        let data = {
          UserID: userID,
          CurrentPassword: oldPassword,
          NewPassword: newPassword,
        };
        api
          .post(APIConstant.path.userChangePassword, data)
          .then((response) => {
            if (response.success) {
              handleClose("ChangePassword");
              Toast.success({ message: response.message });
              Loader.hide();
            } else {
              Toast.error({ message: response.message });
              handleClose("ChangePassword");
              Loader.hide();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (err) {
        console.log(err);
      }
    }
  };

  const accountTypeBodyTemplate = (rowData) => {
    return !CommonConfig.isEmpty(rowData?.AccountType)
      ? rowData?.AccountType === "NewMember"
        ? "M"
        : rowData?.AccountType === "NewCompany"
        ? "O"
        : "I"
      : "-";
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilter(value);
  };

  const companyNameBodyTemplate = (rowData) => {
    return !CommonConfig.isEmpty(rowData?.CompanyName)
      ? rowData?.CompanyName
      : "-";
  };

  const gstBodyTemplate = (rowData) => {
    return !CommonConfig.isEmpty(rowData?.GstNumber) ? rowData?.GstNumber : "-";
  };

  const panBodyTemplate = (rowData) => {
    return !CommonConfig.isEmpty(rowData?.PanNumber) ? rowData?.PanNumber : "-";
  };

  return (
    <div className="user-container">
      <div className="card-header">
        <div className="card-title p-d-lg-flex p-ai-center p-w-100">
          <h3 className="p-mb-3 p-mb-lg-0">User</h3>
          <div className="p-ml-auto p-d-lg-flex p-ai-center">
            <span className="searchbar-area">
              <InputText
                type="search"
                onChange={onGlobalFilterChange}
                placeholder="Global Search"
                size="30"
              />
              <i className="icon-search"></i>
            </span>
          </div>
          <div className="card-actions p-ml-2">
            <div className="p-w-100 ">
              <div className="custom-inputs">
                <Dropdown
                  className="p-w-100 p-mt-2"
                  optionLabel="label"
                  value={accountType}
                  options={accountTypeList}
                  onChange={(e) => handleChangeFilter(e, "AccountType")}
                  filter
                  placeholder="Select Account Type"
                />
              </div>
            </div>
          </div>
          <div className="card-actions p-ml-2">
            <div className="p-w-100 ">
              <div className="custom-inputs">
                <Dropdown
                  className="p-w-100 p-mt-2"
                  optionLabel="label"
                  value={planStatus}
                  options={planStatusList}
                  onChange={(e) => handleChangeFilter(e, "PlanStatus")}
                  filter
                  placeholder="Select Plan Status"
                />
              </div>
            </div>
          </div>
          <div className="card-actions p-ml-2">
            <Button
              type="button"
              className="add-btn add-btn p-button p-component p-jc-center p-w-100"
              onClick={(e) => handleOpen(e, "AddUser")}
            >
              <i className="icon-add"></i> Add user
            </Button>
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="report-custom-table policy-custom-table">
          <DataTable
            paginator
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            rows={10}
            rowsPerPageOptions={[5, 10, 25, 50]}
            className="custom-table"
            value={filteredUserList}
            scrollable
            scrollHeight="calc(100vh - 203px)"
            dataKey="id"
            filters={filters}
            filterDisplay="row"
            globalFilterFields={[
              "AccountType",
              "Name",
              "UserRole",
              "PhoneNumber",
              "Email",
              "CompanyName",
              "GstNumber",
              "PanNumber",
              "Plan",
              "Status",
            ]}
            emptyMessage="No users found."
          >
            <Column
              field="Index"
              header="Sr.No"
              body={actionBodyTemplateSrNo}
              style={{ maxWidth: 150 }}
            ></Column>
            <Column
              field="AccountType"
              header="Account Type"
              body={accountTypeBodyTemplate}
            ></Column>
            <Column
              field="Name"
              header="Name"
              sortable
              filter
              filterPlaceholder="Name"
              style={{ minWidth: "12rem" }}
            ></Column>
            <Column
              field="UserRole"
              header="User Type"
              sortable
              filter
              filterPlaceholder="User role"
              style={{ minWidth: "12rem" }}
            ></Column>
            <Column
              field="PhoneNumber"
              header="Mobile Number"
              sortable
              filter
              filterPlaceholder="Phone number"
              style={{ minWidth: "12rem" }}
            ></Column>
            <Column
              field="Email"
              header="Email"
              sortable
              filter
              filterPlaceholder="Email"
              style={{ minWidth: "12rem" }}
            ></Column>
            <Column
              field="CompanyName"
              header="Company Name"
              body={companyNameBodyTemplate}
              sortable
              filter
              filterPlaceholder="Company name"
              style={{ minWidth: "12rem" }}
            ></Column>
            <Column
              field="GstNumber"
              header="GST"
              body={gstBodyTemplate}
              sortable
              filter
              filterPlaceholder="Gst number"
              style={{ minWidth: "12rem" }}
            ></Column>
            <Column
              field="PanNumber"
              header="PAN"
              body={panBodyTemplate}
              sortable
              filter
              filterPlaceholder="Pan number"
              style={{ minWidth: "12rem" }}
            ></Column>
            <Column
              field="Plan"
              header="Plan Name"
              sortable
              filter
              filterPlaceholder="Plan name"
              style={{ minWidth: "12rem" }}
            ></Column>
            <Column field="PlanStatus" header="Plan Status" sortable></Column>
            <Column field="Status" header="Status" sortable></Column>
            <Column header="Action" body={actionBodyTemplate}></Column>
          </DataTable>
        </div>
      </div>
      {/* Delete Confirm Modal */}
      <Dialog
        header="Delete User"
        visible={isDeleteUser}
        className="dialog-popup"
        onHide={(e) => handleClose("DeleteUser")}
        draggable={false}
        closable={false}
        position="top"
      >
        <span>Are you sure want to delete User ?</span>
        <div className="dialog-footer">
          <button
            className="btn-dialog-cancel"
            onClick={(e) => handleClose("DeleteUser")}
          >
            cancel
          </button>
          <button className="btn-dialog-delete" onClick={() => DeleteUser()}>
            Delete
          </button>
        </div>
      </Dialog>

      {/* View Confirm Modal */}
      <Dialog
        header="View User"
        visible={isViewUser}
        className="dialog-popup"
        onHide={(e) => handleClose("ViewUser")}
        draggable={false}
        closable={true}
        position="center"
      >
        <div className="modalBody">
          <div className={"p-grid"}>
            <div className="p-col-12 p-md-12 p-pl-0 p-py-0">
              <div className={"p-grid"}>
                <div className="p-col-12 p-md-6 p-pt-2">
                  <div className="p-w-100 ">
                    <span>
                      <b>User Role : </b>
                    </span>
                    <p>
                      {" "}
                      {!CommonConfig.isObjectEmpty(viewUserData) &&
                      !CommonConfig.isEmpty(viewUserData?.UserRole)
                        ? viewUserData?.UserRole
                        : "-"}
                    </p>
                  </div>
                </div>
                <div className="p-col-12 p-md-6 p-pt-2">
                  <div className="p-w-100 ">
                    <span>
                      <b>Name :</b>
                    </span>
                    <p>
                      {" "}
                      {!CommonConfig.isObjectEmpty(viewUserData) &&
                      !CommonConfig.isEmpty(viewUserData?.Name)
                        ? viewUserData?.Name
                        : "-"}
                    </p>
                  </div>
                </div>

                <div className="p-col-12 p-md-6 p-pt-2">
                  <div className="p-w-100 ">
                    <span>
                      <b>Phone Number :</b>
                    </span>
                    <p>
                      {" "}
                      {!CommonConfig.isObjectEmpty(viewUserData) &&
                      !CommonConfig.isEmpty(viewUserData?.PhoneNumber)
                        ? viewUserData?.PhoneNumber
                        : "-"}
                    </p>
                  </div>
                </div>
                <div className="p-col-12 p-md-6 p-pt-2">
                  <div className="p-w-100 ">
                    <span>
                      <b>Email :</b>
                    </span>
                    <p>
                      {" "}
                      {!CommonConfig.isObjectEmpty(viewUserData) &&
                      !CommonConfig.isEmpty(viewUserData?.Email)
                        ? viewUserData?.Email
                        : "-"}
                    </p>
                  </div>
                </div>

                <div className="p-col-12 p-md-6 p-pt-2">
                  <div className="p-w-100 ">
                    <span>
                      <b>Plan :</b>
                    </span>
                    <p>
                      {" "}
                      {!CommonConfig.isObjectEmpty(viewUserData) &&
                      !CommonConfig.isEmpty(viewUserData?.Plan)
                        ? viewUserData?.Plan
                        : "-"}
                    </p>
                  </div>
                </div>

                <div className="p-col-12 p-md-6 p-pt-2">
                  <div className="p-w-100 ">
                    <span>
                      <b>Company Name :</b>
                    </span>
                    <p>
                      {" "}
                      {!CommonConfig.isObjectEmpty(viewUserData) &&
                      !CommonConfig.isEmpty(viewUserData?.CompanyName)
                        ? viewUserData?.CompanyName
                        : "-"}
                    </p>
                  </div>
                </div>

                <div className="p-col-12 p-md-6 p-pt-2">
                  <div className="p-w-100 ">
                    <span>
                      <b>Company Website :</b>
                    </span>
                    <p>
                      {" "}
                      {!CommonConfig.isObjectEmpty(viewUserData) &&
                      !CommonConfig.isEmpty(viewUserData?.CompanyWebsite)
                        ? viewUserData?.CompanyWebsite
                        : "-"}
                    </p>
                  </div>
                </div>

                <div className="p-col-12 p-md-6 p-pt-2">
                  <div className="p-w-100 ">
                    <span>
                      <b>Company Number : </b>
                    </span>
                    <p>
                      {" "}
                      {!CommonConfig.isObjectEmpty(viewUserData) &&
                      !CommonConfig.isEmpty(viewUserData?.CompanyPhone)
                        ? viewUserData?.CompanyPhone
                        : "-"}
                    </p>
                  </div>
                </div>

                <div className="p-col-12 p-md-6 p-pt-2">
                  <div className="p-w-100 ">
                    <span>
                      <b>Company Email : </b>
                    </span>
                    <p>
                      {" "}
                      {!CommonConfig.isObjectEmpty(viewUserData) &&
                      !CommonConfig.isEmpty(viewUserData?.CompanyEmail)
                        ? viewUserData?.CompanyEmail
                        : "-"}
                    </p>
                  </div>
                </div>

                <div className="p-col-12 p-md-6 p-pt-2">
                  <div className="p-w-100 ">
                    <span>
                      <b>Company Address : </b>
                    </span>
                    <p>
                      {" "}
                      {!CommonConfig.isObjectEmpty(viewUserData) &&
                      !CommonConfig.isEmpty(viewUserData?.CompanyAddress)
                        ? viewUserData?.CompanyAddress
                        : "-"}
                    </p>
                  </div>
                </div>

                <div className="p-col-12 p-md-6 p-pt-2">
                  <div className="p-w-100 ">
                    <span>
                      <b>Company State : </b>
                    </span>
                    <p>
                      {" "}
                      {!CommonConfig.isObjectEmpty(viewUserData) &&
                      !CommonConfig.isEmpty(viewUserData?.CompanyState)
                        ? viewUserData?.CompanyState
                        : "-"}
                    </p>
                  </div>
                </div>

                <div className="p-col-12 p-md-6 p-pt-2">
                  <div className="p-w-100 ">
                    <span>
                      <b>Company City : </b>
                    </span>
                    <p>
                      {" "}
                      {!CommonConfig.isObjectEmpty(viewUserData) &&
                      !CommonConfig.isEmpty(viewUserData?.CompanyCity)
                        ? viewUserData?.CompanyCity
                        : "-"}
                    </p>
                  </div>
                </div>

                <div className="p-col-12 p-md-6 p-pt-2">
                  <div className="p-w-100 ">
                    <span>
                      <b>Company Pincode : </b>
                    </span>
                    <p>
                      {" "}
                      {!CommonConfig.isObjectEmpty(viewUserData) &&
                      !CommonConfig.isEmpty(viewUserData?.CompanyPinCode)
                        ? viewUserData?.CompanyPinCode
                        : "-"}
                    </p>
                  </div>
                </div>

                <div className="p-col-12 p-md-6 p-pt-2">
                  <div className="p-w-100 ">
                    <span>
                      <b>Status :</b>
                    </span>
                    <p>
                      {" "}
                      {!CommonConfig.isObjectEmpty(viewUserData) &&
                      !CommonConfig.isEmpty(viewUserData?.Status)
                        ? viewUserData?.Status
                        : "-"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="dialog-footer">
          <button
            className="btn-dialog-cancel"
            onClick={(e) => handleClose("ViewUser")}
          >
            cancel
          </button>
        </div>
      </Dialog>

      <Dialog
        header="Reset Password"
        visible={changePasswordModal}
        className="dialog-popup1"
        onHide={(e) => setChangePasswordModal(false)}
        draggable={false}
        closable={false}
        position="top"
      >
        <div style={{ marginTop: "14px" }} className="floating-field">
          <InputText
            placeholder=" "
            className={"p-w-100" + (oldPasswordErr ? "p-invalid block" : null)}
            id="userName"
            type="password"
            value={oldPassword}
            onChange={(e) => handleChange(e, "oldPassword")}
          />
          <span class="bar"></span>
          <label htmlFor="emailAddress">Old Password</label>
          {oldPasswordErr ? (
            <small className="p-error block">{oldPasswordErrText}</small>
          ) : null}
          <span class="bar"></span>
        </div>
        <div style={{ marginTop: "14px" }} className="floating-field mt-3">
          <InputText
            placeholder=" "
            className={"p-w-100" + (newPasswordErr ? "p-invalid block" : null)}
            id="userName"
            type="password"
            value={newPassword}
            onChange={(e) => handleChange(e, "newPassword")}
          />
          <span class="bar"></span>
          <label htmlFor="emailAddress">New Password</label>
          {newPasswordErr ? (
            <small className="p-error block">{newPasswordErrText}</small>
          ) : null}
          <span class="bar"></span>
        </div>
        <div style={{ marginTop: "14px" }} className="floating-field mt-3">
          <InputText
            placeholder=" "
            className={
              "p-w-100" + (confirmPasswordErr ? "p-invalid block" : null)
            }
            id="userName"
            type="password"
            value={confirmPassword}
            onChange={(e) => handleChange(e, "confirmPassword")}
          />
          <span class="bar"></span>
          <label htmlFor="emailAddress">Re-type New Password</label>
          {confirmPasswordErr ? (
            <small className="p-error block">{confirmPasswordErrText}</small>
          ) : null}
          <span class="bar"></span>
        </div>
        <div className="dialog-footer">
          <button
            className="btn-dialog-cancel"
            onClick={() => {
              setChangePasswordModal(false);
            }}
          >
            cancel
          </button>
          <button
            className="btn-text-action-primary p-ml-2"
            onClick={(e) => handlePassword(e)}
          >
            submit
          </button>
        </div>
      </Dialog>
    </div>
  );
};

export default Users;
