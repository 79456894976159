import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import api from "../../utils/apiClient";
import CommonConfig from "../../utils/constant";
import APIConstant from "../../utils/pathConstants";
import Toast from '../Shared/Toast/Toast';
import Loader from '../Shared/Loader/Loader';
import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';
import { InputSwitch } from "primereact/inputswitch";

const UserType = () => {

    const [securityUserID, setSecurityUserID] = useState(null);
    const [userTypeId, setUserTypeId] = useState('');

    const [userType, setUserType] = useState('');
    const [userTypeErr, setUserTypeErr] = useState(false);
    const [userTypeErrText, setUserTypeErrText] = useState('');

    const [accountTypeList, setAccountTypeList] = useState([]);
    const [accountType, setAccountType] = useState('');
    const [accountTypeErr, setAccountTypeErr] = useState(false);
    const [accountTypeErrText, setAccountTypeErrText] = useState('');

    const [globalFilter, setGlobalFilter] = useState('');
    const [userTypeList, setUserTypeList] = useState([]);
    const [addUserTypeModal, setAddUserTypeModal] = useState(false);
    const [isDeleteUserTypeModal, setDeleteUserTypeModal] = useState(false);

    useEffect(() => {
        const loginData = CommonConfig.loginData();
        setSecurityUserID(loginData?.UserId);
        getAccountType();
        getAdminUserType(loginData.UserId);

    }, []);

    const resetScreen = async () => {
        setSecurityUserID(null);
        setAddUserTypeModal(false);

        setUserType('');
        setUserTypeErr(false);
        setUserTypeErrText('');

        setAccountType('');
        setAccountTypeErr(false);
        setAccountTypeErrText('');
    };

    const handleChange = (e, type) => {
        if (type === "userType") {
            if (CommonConfig.isEmpty(e.target.value)) {
                setUserType("");
                setUserTypeErr(true);
                setUserTypeErrText("User Type is required");
            } else {
                setUserType(e.target.value);
                setUserTypeErr(false);
                setUserTypeErrText("");
            }
        }
        else if (type === "accountType") {
            if (CommonConfig.isEmpty(e.target.value)) {
                setAccountType("");
                setAccountTypeErr(true);
                setAccountTypeErrText("Account Type is required");
            } else {
                setAccountType(e.target.value);
                setAccountTypeErr(false);
                setAccountTypeErrText("");
            }
        }
    };

    const validate = () => {
        let formIsValid = true;
        if (CommonConfig.isEmpty(userType)) {
            formIsValid = false;
            setUserTypeErr(true);
            setUserTypeErrText('User Type is required');
        }
        else {
            setUserTypeErr(false);
            setUserTypeErrText('');
        }

        if (!accountType.length) {
            formIsValid = false;
            setAccountTypeErr(true);
            setAccountTypeErrText('Select atleast 1 account type');
        }
        else {
            setAccountTypeErr(false);
            setAccountTypeErrText('');
        }
        return formIsValid;
    }

    const AddUpdateAdminUserType = async (e) => {
        if (validate(e)) {
            try {
                let data = {
                    UserTypeID: userTypeId,
                    Name: userType,
                    AccountType: accountType
                };
                Loader.show();
                await api.post(APIConstant.path.AddUpdateAdminUserType, data).then(response => {
                    let res = response;
                    if (res.success) {
                        Toast.success({ message: res.message });
                        resetScreen();
                        handleClose('AddUserType');
                        getAdminUserType();
                        Loader.hide();
                    } else {
                        Toast.error({ message: res.message });
                        Loader.hide();
                    }
                }).catch(err => { })
            } catch (err) {
                console.log(err);
            }
        }
    }

    const getAccountType = async (ID) => {
        try {
            let data = {
                type: 'account_type'
            }
            await api.post(APIConstant.path.GetStringMap, data).then(async response => {
                let res = response;
                if (res.success) {
                    setAccountTypeList(res.data.map(data => ({...data, value: data._id})));

                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) {
            console.log("err", err);
        }
    }

    const getAdminUserType = async (ID) => {
        try {
            let data = {
            }
            await api.post(APIConstant.path.GetAdminUserType, data).then(async response => {
                let res = response;
                if (res.success) {
                    setUserTypeList(res.data);
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }

    const enableDisableAdminUserType = async (e, value) => {
        try {
            Loader.show();
            let data = {
                UserTypeID: value?.value,
                IsEnable: e.value ? 1 : 0,
            };
            await api
                .post(APIConstant.path.EnableDisableAdminUserType, data).then(async (res) => {
                    if (res.success) {
                        getAdminUserType();
                        Loader.hide();
                    } else {
                        Loader.hide();
                    }
                })
                .catch((err) => {
                    Loader.hide();
                    Toast.error({ message: err });
                });
        } catch (err) {
            Loader.hide();
            Toast.error({ message: err });
        }
    }

    const DeleteAdminUserType = async () => {
        try {
            Loader.show();
            let data = {
                UserTypeID: userTypeId,
                UserID: securityUserID,
            };
            await api
                .post(APIConstant.path.DeleteAdminUserType, data).then(async (res) => {
                    if (res.success) {
                        handleClose('DeleteUserType');
                        getAdminUserType();
                        setUserTypeId();
                        Loader.hide();
                    } else {
                        Loader.hide();
                    }
                })
                .catch((err) => {
                    Loader.hide();
                    Toast.error({ message: err });
                });
        } catch (err) {
            Loader.hide();
            Toast.error({ message: err });
        }
    }

    const handleClose = async (type) => {
        if (type === 'AddUserType') {
            setAddUserTypeModal(false);
            setUserTypeId('');

            setUserType('');
            setUserTypeErr(false);
            setUserTypeErrText('');
            setAccountType([]);
            setAccountTypeErr(false);
            setAccountTypeErrText('');
        }
        else if (type === 'EditUserType') {
            setAddUserTypeModal(false);
        } else if (type === 'DeleteUserType') {
            setUserTypeId('');
            
            setDeleteUserTypeModal(false);
        }
    };

    const handleOpen = (item, type) => {
        if (type === 'AddUserType') {
            setAddUserTypeModal(true);
        } else if (type === 'EditUserType') {
            console.log(item)
            setUserTypeId(item.value);
            setUserType(item.name);
            const accountTypeData = !CommonConfig.isEmpty(item.AccountTypeIds) ? item.AccountTypeIds.split(',').map(id => id.trim()) : []
            console.log("accountTypeData", accountTypeData);
            setAccountType(accountTypeData);
            setAddUserTypeModal(true);
        } else if (type === 'DeleteUserType') {
            setUserTypeId(item.value);
            setDeleteUserTypeModal(true);
        }
    };

    const actionBodyTemplateSrNo = (data, props) => {
        return props.rowIndex + 1;
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className='table-icon'>
                <Button className='' onClick={() => handleOpen(rowData, 'EditUserType')} tooltip={"Edit"} tooltipOptions={{ className: 'bluegray-tooltip', position: 'top' }}>
                    <i className='icon-edit'></i>
                </Button>
                <Button className='' onClick={() => handleOpen(rowData, "DeleteUserType")} tooltip={"Delete"} tooltipOptions={{ className: 'bluegray-tooltip', position: 'top' }}>
                    <i className='icon-delete'  ></i>
                </Button>
                <div className='table-icon'>
                    <InputSwitch checked={rowData?.IsEnable === 1 ? true : false} onChange={(e) => enableDisableAdminUserType(e, rowData)} />
                </div>
            </div>
        )
    }



    return (
        <div className='user-container'>
            <div className='card-header'>
                <div className='card-title p-d-lg-flex p-ai-center p-w-100'>
                    <h3 className="p-mb-3 p-mb-lg-0">View User Type</h3>
                    <div className="p-ml-auto p-d-lg-flex p-ai-center">
                        <span className="searchbar-area">
                            <InputText type="search" onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" size="30" />
                            <i className="icon-search"></i>
                        </span>
                    </div>
                    <div className='card-actions p-ml-2'>
                        <Button type="button" className='add-btn add-btn p-button p-component p-jc-center p-w-100' onClick={(e) => handleOpen(e, 'AddUserType')}>
                            <i className="icon-add"></i>Add User Type
                        </Button>
                    </div>
                </div>
            </div>
            <div className="card">
                <DataTable globalFilter={globalFilter} paginator paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[5, 10, 25, 50]} className='custom-table' value={userTypeList} scrollable scrollHeight="calc(100vh - 203px)">
                    <Column field="Index" header="Sr.No" body={actionBodyTemplateSrNo} style={{ maxWidth: 150 }}></Column>
                    <Column field="name" header="User Type" style={{ width: "25%" }} ></Column>
                    <Column field="AccountType" header="Account Type" style={{ width: "25%" }} ></Column>
                    {/* <Column field="Status" header="Status" style={{ width: "25%" }} ></Column> */}
                    <Column header="Action" body={actionBodyTemplate}></Column>
                </DataTable>
            </div>

            {/* Add Confirm Modal */}
            <Dialog
                header={userTypeId ? "Edit User Type" : "Add User Type"}
                visible={addUserTypeModal}
                className='dialog-popup'
                onHide={(e) => handleClose('AddUserType')}
                draggable={false}
                closable={false}
                position="top"
            >
                <div>
                    <div className='card-body'>
                        <div className="form-area">
                            <div className={"p-grid"}>
                                <div className="p-col-12 p-md-12 p-pt-2">
                                    <div className="p-w-100 ">
                                        <div className="custom-inputs">
                                            <label htmlFor="userType">User Type <span style={{ color: 'red' }}>*</span>: </label>
                                            <InputText
                                                className="p-w-100 p-mt-2"
                                                id="userType"
                                                type="text"
                                                placeholder="User Type"
                                                value={userType}
                                                autoFocus={true}
                                                onChange={(e) => handleChange(e, "userType")}
                                                maxLength={30}
                                            />
                                        </div>
                                        <span className="error-msg" style={{ color: 'red' }}>
                                            {userTypeErr ? userTypeErrText : null}
                                        </span>
                                    </div>
                                </div>
                                <div className="p-col-12 p-md-12 p-pt-2">
                                    <div className="p-w-100 ">
                                        <div className="custom-inputs">
                                            <label htmlFor="accountType">Account Type <span style={{ color: 'red' }}>*</span>: </label>
                                            <MultiSelect
                                                className="p-w-100 p-mt-2"
                                                optionLabel="name"
                                                value={accountType}
                                                options={accountTypeList}
                                                onChange={(e) => handleChange(e, "accountType")}
                                                filter
                                                placeholder="Select Account Type"
                                                display="chip"
                                            />
                                        </div>
                                        <span className="error-msg" style={{ color: 'red' }}>
                                            {accountTypeErr ? accountTypeErrText : null}
                                        </span>
                                    </div>
                                </div>
                                {/* <div className="card-footer">
                                    <Button label="Submit" severity="secondary" raised onClick={(e) => AddUpdateAdminUserType(e)} />
                                    <Button className='btn-dialog-cancel' onClick={(e) => handleClose('AddUserType')}> cancel </Button>
                                </div> */}
                                <div className='dialog-footer'>
                                    <button type="button" className='btn-dialog-cancel' onClick={(e) => handleClose('AddUserType')}>
                                        cancel
                                    </button>

                                    <button type="button" className='btn-text-action-primary p-ml-2' onClick={(e) => AddUpdateAdminUserType(e)}>
                                        SUBMIT
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>

            {/* Delete Confirm Modal */}
            <Dialog
                header="Delete  User Type"
                visible={isDeleteUserTypeModal}
                className='dialog-popup'
                onHide={(e) => handleClose('DeleteUserType')}
                draggable={false}
                closable={false}
                position="top"
            >
                <span>Are you sure want to delete the User Type ?</span>
                <div className='dialog-footer'>
                    <button className='btn-dialog-cancel' onClick={(e) => handleClose('DeleteUserType')}> cancel </button>
                    <button className='btn-dialog-delete' onClick={() => DeleteAdminUserType()} > Delete </button>
                </div>
            </Dialog>
        </div>
    )
};

export default UserType