import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import api from "../../utils/apiClient";
import CommonConfig from "../../utils/constant";
import APIConstant from "../../utils/pathConstants";
import Toast from '../Shared/Toast/Toast';
import Loader from '../Shared/Loader/Loader';
import { Button } from 'primereact/button';
import { Dropdown } from "primereact/dropdown";

const CountryMaster = () => {

  const [securityUserID, setSecurityUserID] = useState(null);
  const [countryMasterId, setCountryMasterId] = useState(null);

  const [countryName, setCountryName] = useState('');
  const [countryNameErr, setCountryNameErr] = useState(false);
  const [countryNameErrText, setCountryNameErrText] = useState('');

  const [countryCode, setCountryCode] = useState('');
  const [countryCodeErr, setCountryCodeErr] = useState(false);
  const [countryCodeErrText, setCountryCodeErrText] = useState('');

  const [currency, setCurrency] = useState('');
  const [currencyErr, setCurrencyErr] = useState(false);
  const [currencyErrText, setCurrencyErrText] = useState('');
  const [currencyWithSymbolList, setCurrencyWithSymbolList] = useState([]);

  const [mobileCode, setMobileCode] = useState('');
  const [mobileCodeErr, setMobileCodeErr] = useState(false);
  const [mobileCodeErrText, setMobileCodeErrText] = useState('');

  const [globalFilter, setGlobalFilter] = useState('');
  const [countryMasterList, setCountryMasterList] = useState([]);
  const [addCountryMasterModal, setAddCountryMasterModal] = useState(false);
  const [isDeleteCountryMaster, setDeleteCountryMaster] = useState(false);

  useEffect(() => {
    const loginData = CommonConfig.loginData();
    setSecurityUserID(loginData?.UserId);
    getAdminCountryMaster(loginData?.UserId);
    getCurrencyMasterList();

  }, []);

  const getCurrencyMasterList = async () => {
    try {
      let data = {

      }
      await api.post(APIConstant.path.GetAdminCurrencyMaster, data).then(async response => {
        let res = response;
        if (res.success) {
          const currencyOptions = res.data.map((currency) => ({
            label: currency.CurrencySymbol,
            value: currency._id,
          }));
          setCurrencyWithSymbolList(currencyOptions);
          Loader.hide();
        }
      }).catch(err => {
        Loader.hide();
        console.log(err);
      });
    } catch (err) { console.log(err); }
  };

  const resetScreen = async () => {
    setSecurityUserID(null);
    setAddCountryMasterModal(false);

    setCountryName('');
    setCountryNameErr(false);
    setCountryNameErrText('');

    setCountryCode('');
    setCountryCodeErr(false);
    setCountryCodeErrText('');

    setCurrency('');
    setCurrencyErr(false);
    setCurrencyErrText('');

    setMobileCode('');
    setMobileCodeErr(false);
    setMobileCodeErrText('');
  };

  const handleChange = (e, type) => {
    if (type === "countryName") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setCountryName("");
        setCountryNameErr(true);
        setCountryNameErrText("Country name is required");
      } else {
        setCountryName(e.target.value);
        setCountryNameErr(false);
        setCountryNameErrText("");
      }
    } if (type === "mobileCode") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setMobileCode("");
        setMobileCodeErr(true);
        setMobileCodeErrText("Mobile number Code is required");
      } else {
        setMobileCode(e.target.value);
        setMobileCodeErr(false);
        setMobileCodeErrText("");
      }
    } if (type === "countryCode") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setCountryCode("");
        setCountryCodeErr(true);
        setCountryCodeErrText("Country code is required");
      } else {
        setCountryCode(e.target.value);
        setCountryCodeErr(false);
        setCountryCodeErrText("");
      }
    } if (type === "currency") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setCurrency("");
        setCurrencyErr(true);
        setCurrencyErrText("Currency is required");
      } else {
        setCurrency(e.target.value);
        setCurrencyErr(false);
        setCurrencyErrText("");
      }
    }
  };

  const validate = () => {
    let formIsValid = true;
    if (CommonConfig.isEmpty(countryName)) {
      formIsValid = false;
      setCountryNameErr(true);
      setCountryNameErrText('Country name is required');
    }
    else {
      setCountryNameErr(false);
      setCountryNameErrText('');
    }
    if (CommonConfig.isEmpty(countryCode)) {
      formIsValid = false;
      setCountryCodeErr(true);
      setCountryCodeErrText('Country code is required');
    }
    else {
      setCountryCodeErr(false);
      setCountryCodeErrText('');
    }
    if (CommonConfig.isEmpty(currency)) {
      formIsValid = false;
      setCurrencyErr(true);
      setCurrencyErrText('Currency is required');
    }
    else {
      setCurrencyErr(false);
      setCurrencyErrText('');
    }
    if (CommonConfig.isEmpty(mobileCode)) {
      formIsValid = false;
      setMobileCodeErr(true);
      setMobileCodeErrText('Mobile number code is required');
    }
    else {
      setMobileCodeErr(false);
      setMobileCodeErrText('');
    }
    return formIsValid;
  }

  const AddUpdateCountryMaster = async (e) => {
    if (validate(e)) {
      try {
        let data = {
          CountryMasterId: countryMasterId,
          CountryName: countryName,
          CountryCode: countryCode,
          Currency: currency,
          MobileCode: mobileCode,
          UserId: CommonConfig.loginData().UserId,
        };
        Loader.show();
        await api.post(APIConstant.path.AddUpdateAdminCountryMaster, data).then(response => {
          let res = response;
          if (res.success) {
            Toast.success({ message: res.message });
            resetScreen();
            handleClose('AddCountryMaster');
            getAdminCountryMaster();
            Loader.hide();
          } else {
            Toast.error({ message: res.message });
            Loader.hide();
          }
        }).catch(err => { })
      } catch (err) {
        console.log(err);
      }
    }
  }

  const getAdminCountryMaster = async (ID) => {
    try {
      let data = {
      }
      await api.post(APIConstant.path.GetAdminCountryMaster, data).then(async response => {
        let res = response;
        if (res.success) {
          setCountryMasterList(res.data);
          Loader.hide();
        }
      }).catch(err => {
        Loader.hide();
        console.log(err);
      });
    } catch (err) { console.log(err); }
  }

  const DeleteAdminCountryMaster = async () => {
    try {
      Loader.show();
      let data = {
        CountryMasterId: countryMasterId,
      };
      await api
        .post(APIConstant.path.DeleteAdminCountryMaster, data).then(async (res) => {
          if (res.success) {
            handleClose('DeleteCountryMaster');
            getAdminCountryMaster();
            setCountryMasterId(null);
            Loader.hide();
          } else {
            Loader.hide();
          }
        })
        .catch((err) => {
          Loader.hide();
          Toast.error({ message: err });
        });
    } catch (err) {
      Loader.hide();
      Toast.error({ message: err });
    }
  }

  const handleClose = async (type) => {
    if (type === 'AddCountryMaster') {
      setAddCountryMasterModal(false);
      setCountryMasterId('');

      setCountryName('');
      setCountryNameErr(false);
      setCountryNameErrText('');

      setCountryCode('');
      setCountryCodeErr(false);
      setCountryCodeErrText('');

      setCurrency('');
      setCurrencyErr(false);
      setCurrencyErrText('');

      setMobileCode('');
      setMobileCodeErr(false);
      setMobileCodeErrText('');
    } else if (type === 'DeleteCountryMaster') {
      setCountryMasterId(null);
      setDeleteCountryMaster(false);
    }
  };

  const handleOpen = (item, type) => {
    if (type === 'AddCountryMaster') {
      setAddCountryMasterModal(true);
    } else if (type === 'EditCountryMaster') {
      setCountryMasterId(item._id);
      setCountryName(item.CountryName);
      setCountryCode(item.CountryCode);
      setCurrency(item.Currency);
      setMobileCode(item.MobileCode);
      setAddCountryMasterModal(true);
    } else if (type === 'DeleteCountryMaster') {
      setCountryMasterId(item._id);
      setDeleteCountryMaster(true);
    }
  };
  const actionBodyTemplateSrNo = (data, props) => {
    return props.rowIndex + 1;
  };
  const actionBodyTemplate = (rowData) => {
    return (
      <div className='table-icon'>
        <Button className='' onClick={() => handleOpen(rowData, 'EditCountryMaster')} tooltip={"Edit"} tooltipOptions={{ className: 'bluegray-tooltip', position: 'top' }}>
          <i className='icon-edit'></i>
        </Button>
        <Button className='' onClick={() => handleOpen(rowData, "DeleteCountryMaster")} tooltip={"Delete"} tooltipOptions={{ className: 'bluegray-tooltip', position: 'top' }}>
          <i className='icon-delete'  ></i>
        </Button>
      </div>
    )
  }
  return (
    <div className='user-container'>
      <div className='card-header'>
        <div className='card-title p-d-lg-flex p-ai-center p-w-100'>
          <h3 className="p-mb-3 p-mb-lg-0">View Country Master</h3>
          <div className="p-ml-auto p-d-lg-flex p-ai-center">
            <span className="searchbar-area">
              <InputText type="search" onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" size="30" />
              <i className="icon-search"></i>
            </span>
          </div>
          <div className='card-actions p-ml-2'>
            <Button type="button" className='add-btn add-btn p-button p-component p-jc-center p-w-100' onClick={(e) => handleOpen(e, 'AddCountryMaster')}>
              <i className="icon-add"></i>Add Country Master
            </Button>
          </div>
        </div>
      </div>
      <div className="card">
        <DataTable globalFilter={globalFilter} paginator paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[5, 10, 25, 50]} className='custom-table' value={countryMasterList} scrollable scrollHeight="calc(100vh - 203px)">
          <Column field="Index" header="Sr.No" body={actionBodyTemplateSrNo} style={{ maxWidth: 150 }}></Column>
          <Column field="CountryName" header="Country name" style={{ width: "25%" }} ></Column>
          <Column field="CountryCode" header="Country code" style={{ width: "25%" }} ></Column>
          <Column field="CurrencyWithSymbol" header="Currency" style={{ width: "25%" }} ></Column>
          <Column field="MobileCode" header="Mobile code" style={{ width: "25%" }} ></Column>
          <Column header="Action" body={actionBodyTemplate}></Column>
        </DataTable>
      </div>

      {/* Add Confirm Modal */}
      <Dialog
        header={countryMasterId ? "Edit Country Master" : "Add Country Master"}
        visible={addCountryMasterModal}
        className='dialog-popup'
        onHide={(e) => handleClose('AddCountryMaster')}
        draggable={false}
        closable={false}
        position="top"
      >
        <div>
          <div className='card-body'>
            <div className="form-area">
              <div className={"p-grid"}>
                <div className="p-col-12 p-md-4 p-pt-2">
                  <div className="p-w-100 ">
                    <div className="custom-inputs">
                      <label htmlFor="countryName">Country name <span style={{ color: 'red' }}>*</span>: </label>
                      <InputText
                        className="p-w-100 p-mt-2"
                        id="countryName"
                        type="text"
                        placeholder="Country name"
                        value={countryName}
                        autoFocus={true}
                        onChange={(e) => handleChange(e, "countryName")}
                        maxLength={30}
                      />
                    </div>
                    <span className="error-msg" style={{ color: 'red' }}>
                      {countryNameErr ? countryNameErrText : null}
                    </span>
                  </div>
                </div>
                <div className="p-col-12 p-md-4 p-pt-2">
                  <div className="p-w-100 ">
                    <div className="custom-inputs">
                      <label htmlFor="countryCode">Country code <span style={{ color: 'red' }}>*</span>: </label>
                      <InputText
                        className="p-w-100 p-mt-2"
                        id="countryCode"
                        type="text"
                        placeholder="Country code"
                        value={countryCode}
                        autoFocus={true}
                        onChange={(e) => handleChange(e, "countryCode")}
                        maxLength={30}
                      />
                    </div>
                    <span className="error-msg" style={{ color: 'red' }}>
                      {countryCodeErr ? countryCodeErrText : null}
                    </span>
                  </div>
                </div>
                <div className="p-col-12 p-md-4 p-pt-2">
                  <div className="p-w-100 ">
                    <div className="custom-inputs">
                      <label htmlFor="currency">Currency <span style={{ color: 'red' }}>*</span>: </label>
                      <Dropdown
                        className="p-w-100 p-mt-2"
                        optionLabel="label"
                        value={currency}
                        options={currencyWithSymbolList}
                        onChange={(e) => handleChange(e, "currency")}
                        filter
                        placeholder="Currency"
                      />
                    </div>
                    <span className="error-msg" style={{ color: 'red' }}>
                      {currencyErr ? currencyErrText : null}
                    </span>
                  </div>
                </div>
                <div className="p-col-12 p-md-4 p-pt-2">
                  <div className="p-w-100 ">
                    <div className="custom-inputs">
                      <label htmlFor="mobileCode">Mobile number code <span style={{ color: 'red' }}>*</span>: </label>
                      <InputText
                        className="p-w-100 p-mt-2"
                        id="mobileCode"
                        type="text"
                        placeholder="Mobile number code"
                        value={mobileCode}
                        autoFocus={true}
                        onChange={(e) => handleChange(e, "mobileCode")}
                        maxLength={30}
                      />
                    </div>
                    <span className="error-msg" style={{ color: 'red' }}>
                      {mobileCodeErr ? mobileCodeErrText : null}
                    </span>
                  </div>
                </div>
                <div className="card-footer">
                  <Button label="Submit" severity="secondary" raised onClick={(e) => AddUpdateCountryMaster(e)} />
                  <button className='btn-dialog-cancel' onClick={(e) => handleClose('AddCountryMaster')}> cancel </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>

      {/* Delete Confirm Modal */}
      <Dialog
        header="Delete  Country Master"
        visible={isDeleteCountryMaster}
        className='dialog-popup'
        onHide={(e) => handleClose('DeleteCountryMaster')}
        draggable={false}
        closable={false}
        position="top"
      >
        <span>Are you sure want to delete the Country Master ?</span>
        <div className='dialog-footer'>
          <button className='btn-dialog-cancel' onClick={(e) => handleClose('DeleteCountryMaster')}> cancel </button>
          <button className='btn-dialog-delete' onClick={() => DeleteAdminCountryMaster()} > Delete </button>
        </div>
      </Dialog>
    </div>
  )
};
export default CountryMaster