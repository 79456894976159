import React, { useState, useEffect } from "react";
import CommonConfig from "../../utils/constant";
import APIConstant from "../../utils/pathConstants";
import api from "../../utils/apiClient";
import Loader from "../../components/Shared/Loader/Loader";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { FilterMatchMode, FilterOperator } from "primereact/api";

const Dashboard = (props) => {
  const [securityUserID, setSecurityUserID] = useState(null);
  const [userID, setUserID] = useState(null);
  const [dashboardList, setDashboardList] = useState([
    {
      Name: "Total Active Users",
      Count: 0,
      Icon: "",
    },
    {
      Name: "Total Renew Users",
      Count: 0,
      Icon: "",
    },
    {
      Name: "Total Expire Users",
      Count: 0,
      Icon: "",
    },
  ]);

  const [role, setRole] = useState(null);
  const [globalFilter, setGlobalFilter] = useState("");
  const [dateRange, setDateRange] = useState([null, null]);
  const [userList, setUserList] = useState([]);
  const [filteredUserList, setFilteredUserList] = useState([]);
  const [isViewUser, setIsViewUser] = useState(false);
  const [viewUserData, setViewUserData] = useState({});

  const [userType, setUserType] = useState("All Users");
  const [userTypeList, setUserTypeList] = useState([]);

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    FirstName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    UserRole: { value: null, matchMode: FilterMatchMode.CONTAINS },
    PhoneNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
    Email: { value: null, matchMode: FilterMatchMode.CONTAINS },
    CompanyName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    GstNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
    PanNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
    PlanName: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  useEffect(() => {
    const loginData = CommonConfig.loginData();
    setSecurityUserID(loginData?.UserId);
    getDashboardData();
    getAdminDashboardUser("Total Active Users");
    GetUserRoleList();
  }, []);

  const getDashboardData = async () => {
    try {
      await api
        .post(APIConstant.path.getDashboardData)
        .then(async (response) => {
          let res = response;
          console.log("res", res);
          if (res.success) {
            setDashboardList([
              {
                Name: "Total Active Users",
                Count: response.data[0]?.ActiveUserCount,
                Icon: "",
              },
              {
                Name: "Total Renew Users",
                Count: response.data[0]?.RenewUserCount,
                Icon: "",
              },
              {
                Name: "Total Expire Users",
                Count: response.data[0]?.ExpireUserCount,
                Icon: "",
              },
            ]);
            Loader.hide();
          }
        })
        .catch((err) => {
          Loader.hide();
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const GetUserRoleList = async () => {
    try {
      await api
        .post(APIConstant.path.GetUserRole)
        .then(async (response) => {
          let res = response;
          if (res.success) {
            let tempList = [{ value: "All Users", label: "All Users" }];
            res.data?.forEach((element) => {
              tempList.push(element);
            });
            setUserTypeList(tempList);
            Loader.hide();
          }
        })
        .catch((err) => {
          Loader.hide();
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const getAdminDashboardUser = async (type) => {
    try {
      const payloads = {
        Type: type,
      };
      await api
        .post(APIConstant.path.getAdminDashboardUser, payloads)
        .then(async (response) => {
          let res = response;
          if (res.success) {
            setUserList(res.data);
            setFilteredUserList(res.data);
            Loader.hide();
          }
        })
        .catch((err) => {
          Loader.hide();
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleOpen = async (item, type) => {
    if (type === "ViewUser") {
      setIsViewUser(true);
      setViewUserData(item);
      console.log("viewUserData", item);
    }
  };

  const handleClose = async (type) => {
    if (type === "ViewUser") {
      setIsViewUser(false);
      setViewUserData({});
    }
  };

  const handleChange = (e, type) => {
    if (type === "userType") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setUserType("");
      } else {
        setUserType(e.target.value);
        let filterUserList = [];
        if (e.target.value === "All Users") {
          filterUserList = userList;
        } else {
          filterUserList = userList;
          const filterItem = userTypeList.find(
            (x) => x.value === e.target.value
          );
          if (filterUserList.length && filterItem) {
            filterUserList = filterUserList.filter(
              (x) => x.UserRole === filterItem?.label
            );
          }
        }
        setFilteredUserList(filterUserList);
      }
    }
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <Button
        className="!bg-sky-600 !text-white !w-7 !h-7 !p-0 flex justify-center items-center !rounded-full hover:!border-sky-600 hover:!bg-white hover:!text-sky-600"
        onClick={() => handleOpen(rowData, "ViewUser")}
        tooltip={"View"}
        tooltipOptions={{ className: "bluegray-tooltip", position: "top" }}
      >
        <i className="pi pi-info"></i>
      </Button>
    );
  };

  const actionBodyTemplateSrNo = (data, props) => {
    return props.rowIndex + 1;
  };

  const companyNameBodyTemplate = (rowData) => {
    // return !CommonConfig.isEmpty(rowData?.CompanyName) ? rowData?.CompanyName : '-';
    return (
      <div className="space-y-2">
        <p className="font-semibold text-gray-900">
          {!CommonConfig.isEmpty(rowData?.CompanyName)
            ? rowData?.CompanyName
            : "-"}
        </p>
        <div className="flex gap-3 items-center">
          <div className="flex items-center space-x-2">
            <i className="pi pi-phone !text-xs text-sky-600"></i>
            <p className="text-sm font-medium">{rowData.PhoneNumber}</p>
          </div>
          <div className="flex items-center space-x-2">
            <i className="pi pi-envelope !text-xs text-sky-600"></i>
            <p className="text-sm font-medium">{rowData.Email}</p>
          </div>
        </div>
      </div>
    );
  };

  const gstBodyTemplate = (rowData) => {
    // return !CommonConfig.isEmpty(rowData?.GstNumber) ? rowData?.GstNumber : '-';
    return (
      <div className="space-y-2">
        <p class="gap-1 flex items-center rounded-md bg-orange-50 px-2 py-1 text-xs font-medium text-orange-700 ring-1 ring-inset ring-orange-600/20">
          <b>GST</b>{" "}
          {!CommonConfig.isEmpty(rowData?.GstNumber) ? rowData?.GstNumber : "-"}
        </p>
        <p class="gap-1 flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-700 ring-1 ring-inset ring-gray-600/20">
          <b>PAN</b>{" "}
          {!CommonConfig.isEmpty(rowData?.PanNumber) ? rowData?.PanNumber : "-"}
        </p>
      </div>
    );
  };

  const handleViewData = async (type) => {
    console.log("Type", type);
    // setType(type);
    await getAdminDashboardUser(type);
  };

  const accountTypeBodyTemplate = (rowData) => {
    return (
      <div>
        <div className="relative flex items-start gap-x-4">
          <img
            className="h-10 w-10 mt-0.5 rounded-full bg-gray-50"
            src="https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
            alt=""
          />
          <div className="text-sm leading-6">
            <p className="font-semibold text-gray-900">
              <a href="#">{rowData.FirstName}</a>
            </p>
            <p className="text-gray-600">{rowData.UserRole}</p>
            <p className="text-gray-600 mt-1">
              {" "}
              <a
                href="#"
                class="gap-1 inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-700 ring-1 ring-inset ring-gray-600/10"
              >
                {!CommonConfig.isEmpty(rowData?.AccountType)
                  ? rowData?.AccountType === "NewMember"
                    ? "Member"
                    : rowData?.AccountType === "NewCompany"
                    ? "Owner"
                    : "Individual"
                  : "-"}
              </a>{" "}
              <a
                href="#"
                class="gap-1 inline-flex items-center rounded-md bg-teal-50 px-2 py-1 text-xs font-medium text-teal-700 ring-1 ring-inset ring-teal-600/20 "
              >
                <b>Plan</b> {rowData.PlanName}
              </a>{" "}
            </p>
          </div>
        </div>
      </div>
    );
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilter(value);
  };

  return (
    <div className="rounded-xl bg-white mt-1 pt-4 h-[99%] !overflow-hidden">
      <h3 className="text-xl font-bold text-teal-900 border-b border-gray-200 px-5 pb-3">
        Dashboard
      </h3>
      <div className="!h-[94%] !overflow-auto">
        <div className="grid grid-cols-3 gap-4 p-4">
          {dashboardList?.length
            ? dashboardList?.map((item, idx) => {
                return (
                  <dl
                    key={idx}
                    className="rounded-xl bg-gray-50 text-center ring-1 ring-inset ring-gray-900/5 p-4 hover:bg-white hover:ring-slate-300 cursor-pointer"
                    onClick={() => handleViewData(item.Name)}
                  >
                    <div className="flex flex-col-reverse">
                      <dt className="text-base leading-7 text-gray-500">
                        {item.Name}
                      </dt>
                      <dd className="text-2xl font-bold leading-9 tracking-tight text-teal-500">
                        {item.Count}
                      </dd>
                    </div>
                  </dl>
                );
              })
            : null}
        </div>
        <div className="border-t border-gray-200 rounded">
          <div className="flex items-center justify-between p-4">
            <h3 className="text-xl font-bold">{"User List"}</h3>
            <div className="flex items-center justify-between space-x-4">
              <div className="relative">
                <InputText
                  type="search"
                  onChange={onGlobalFilterChange}
                  placeholder="Global Search"
                  className="h-10 !py-0 items-center leading-10 placeholder:h-10 placeholder:text-sm leading-10"
                />
                {!globalFilter ? (
                  <i className="pi pi-search absolute right-2.5 top-3"></i>
                ) : null}
              </div>
              <Dropdown
                className="[&_.p-dropdown-label]:text-sm [&_.p-dropdown-label]:leading-4 [&_.p-dropdown-label]:h-10 [&_.p-highlight]:!border-0 [&_.p-highlight]:!bg-cyan-500 [&_.p-highlight]:hover:!bg-cyan-600"
                optionLabel="label"
                value={userType}
                options={userTypeList}
                onChange={(e) => handleChange(e, "userType")}
                filter
                placeholder="Select User Type"
              />
            </div>
          </div>
          <div className="">
            <DataTable
              globalFilter={globalFilter}
              filterDisplay="row"
              globalFilterFields={[
                "AccountType",
                "FirstName",
                "UserRole",
                "PhoneNumber",
                "Email",
                "CompanyName",
                "GstNumber",
                "PanNumber",
                "PlanName",
                "Status",
              ]}
              emptyMessage="No users found."
              paginator
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
              rows={10}
              rowsPerPageOptions={[5, 10, 25, 50]}
              className="custom-table [&_.p-datatable-wrapper]:!h-full [&_.p-datatable-wrapper]:!rounded-none [&_.p-paginator]:!px-5 [&_.p-paginator]:!py-3 [&_.p-paginator]:!-mt-0.5 [&_.p-inputtext]:h-10 [&_.p-inputtext]:leading-10 [&_.p-inputtext]:text-sm [&_.p-inputtext]:placeholder:text-sm [&_.p-datatable-thead]:pb-3 [&_.p-datatable-thead]:pt-0.5 [&_.p-datatable-thead]:!bg-gray-50 [&_tr]:!h-24 [&_tr]:!px-2 [&_td]:!px-2 [&_td]:!border-b [&_td]:!border-gray-200"
              value={filteredUserList}
              scrollable
            >
              {/* <Column field="Index" header="Sr.No" body={actionBodyTemplateSrNo} className="max-w-16"></Column> */}
              <Column
                field="FirstName"
                header="User"
                body={accountTypeBodyTemplate}
                sortable
                filter
              ></Column>
              <Column
                field="CompanyName"
                header="Company"
                body={companyNameBodyTemplate}
                sortable
                filter
                filterPlaceholder="Search"
              ></Column>
              <Column
                field="GstNumber"
                header="GST/PAN"
                body={gstBodyTemplate}
                sortable
                filter
                filterPlaceholder="Search"
                className="w-max"
              ></Column>
              <Column
                header="Action"
                body={actionBodyTemplate}
                className="max-w-16"
              ></Column>
            </DataTable>
          </div>
        </div>
      </div>

      {/* View Confirm Modal */}
      <Dialog
        header="View User"
        visible={isViewUser}
        className="dialog-popup"
        onHide={(e) => handleClose("ViewUser")}
        draggable={false}
        closable={true}
        position="center"
      >
        <div className="modalBody">
          <div className={"p-grid"}>
            <div className="p-col-12 p-md-12 p-pl-0 p-py-0">
              <div className={"p-grid"}>
                <div className="p-col-12 p-md-6 p-pt-2">
                  <div className="p-w-100 ">
                    <span>
                      <b>User Role : </b>
                    </span>
                    <p>
                      {" "}
                      {!CommonConfig.isObjectEmpty(viewUserData) &&
                      !CommonConfig.isEmpty(viewUserData?.UserRole)
                        ? viewUserData?.UserRole
                        : "-"}
                    </p>
                  </div>
                </div>
                <div className="p-col-12 p-md-6 p-pt-2">
                  <div className="p-w-100 ">
                    <span>
                      <b>Name :</b>
                    </span>
                    <p>
                      {" "}
                      {!CommonConfig.isObjectEmpty(viewUserData) &&
                      !CommonConfig.isEmpty(viewUserData?.FirstName)
                        ? viewUserData?.FirstName
                        : "-"}
                    </p>
                  </div>
                </div>
                <div className="p-col-12 p-md-6 p-pt-2">
                  <div className="p-w-100 ">
                    <span>
                      <b>Phone Number :</b>
                    </span>
                    <p>
                      {" "}
                      {!CommonConfig.isObjectEmpty(viewUserData) &&
                      !CommonConfig.isEmpty(viewUserData?.PhoneNumber)
                        ? viewUserData?.PhoneNumber
                        : "-"}
                    </p>
                  </div>
                </div>
                <div className="p-col-12 p-md-6 p-pt-2">
                  <div className="p-w-100 ">
                    <span>
                      <b>Email :</b>
                    </span>
                    <p>
                      {" "}
                      {!CommonConfig.isObjectEmpty(viewUserData) &&
                      !CommonConfig.isEmpty(viewUserData?.Email)
                        ? viewUserData?.Email
                        : "-"}
                    </p>
                  </div>
                </div>

                <div className="p-col-12 p-md-6 p-pt-2">
                  <div className="p-w-100 ">
                    <span>
                      <b>Plan :</b>
                    </span>
                    <p>
                      {" "}
                      {!CommonConfig.isObjectEmpty(viewUserData) &&
                      !CommonConfig.isEmpty(viewUserData?.PlanName)
                        ? viewUserData?.PlanName
                        : "-"}
                    </p>
                  </div>
                </div>

                <div className="p-col-12 p-md-6 p-pt-2">
                  <div className="p-w-100 ">
                    <span>
                      <b>Payment Method :</b>
                    </span>
                    <p>
                      {" "}
                      {!CommonConfig.isObjectEmpty(viewUserData) &&
                      !CommonConfig.isEmpty(viewUserData?.PaymentMethod)
                        ? viewUserData?.PaymentMethod
                        : "-"}
                    </p>
                  </div>
                </div>

                <div className="p-col-12 p-md-6 p-pt-2">
                  <div className="p-w-100 ">
                    <span>
                      <b>Payment Status :</b>
                    </span>
                    <p>
                      {" "}
                      {!CommonConfig.isObjectEmpty(viewUserData) &&
                      !CommonConfig.isEmpty(viewUserData?.PaymentStatus)
                        ? viewUserData?.PaymentStatus
                        : "-"}
                    </p>
                  </div>
                </div>
                <div className="p-col-12 p-md-6 p-pt-2">
                  <div className="p-w-100 ">
                    <span>
                      <b>Company Name :</b>
                    </span>
                    <p>
                      {" "}
                      {!CommonConfig.isObjectEmpty(viewUserData) &&
                      !CommonConfig.isEmpty(viewUserData?.CompanyName)
                        ? viewUserData?.CompanyName
                        : "-"}
                    </p>
                  </div>
                </div>

                <div className="p-col-12 p-md-6 p-pt-2">
                  <div className="p-w-100 ">
                    <span>
                      <b>Company Website :</b>
                    </span>
                    <p>
                      {" "}
                      {!CommonConfig.isObjectEmpty(viewUserData) &&
                      !CommonConfig.isEmpty(viewUserData?.CompanyWebsite)
                        ? viewUserData?.CompanyWebsite
                        : "-"}
                    </p>
                  </div>
                </div>

                <div className="p-col-12 p-md-6 p-pt-2">
                  <div className="p-w-100 ">
                    <span>
                      <b>Company Number : </b>
                    </span>
                    <p>
                      {" "}
                      {!CommonConfig.isObjectEmpty(viewUserData) &&
                      !CommonConfig.isEmpty(viewUserData?.CompanyPhone)
                        ? viewUserData?.CompanyPhone
                        : "-"}
                    </p>
                  </div>
                </div>

                <div className="p-col-12 p-md-6 p-pt-2">
                  <div className="p-w-100 ">
                    <span>
                      <b>Company Email : </b>
                    </span>
                    <p>
                      {" "}
                      {!CommonConfig.isObjectEmpty(viewUserData) &&
                      !CommonConfig.isEmpty(viewUserData?.CompanyEmail)
                        ? viewUserData?.CompanyEmail
                        : "-"}
                    </p>
                  </div>
                </div>

                <div className="p-col-12 p-md-6 p-pt-2">
                  <div className="p-w-100 ">
                    <span>
                      <b>Company Address : </b>
                    </span>
                    <p>
                      {" "}
                      {!CommonConfig.isObjectEmpty(viewUserData) &&
                      !CommonConfig.isEmpty(viewUserData?.CompanyAddress)
                        ? viewUserData?.CompanyAddress
                        : "-"}
                    </p>
                  </div>
                </div>

                <div className="p-col-12 p-md-6 p-pt-2">
                  <div className="p-w-100 ">
                    <span>
                      <b>Company State : </b>
                    </span>
                    <p>
                      {" "}
                      {!CommonConfig.isObjectEmpty(viewUserData) &&
                      !CommonConfig.isEmpty(viewUserData?.CompanyState)
                        ? viewUserData?.CompanyState
                        : "-"}
                    </p>
                  </div>
                </div>

                <div className="p-col-12 p-md-6 p-pt-2">
                  <div className="p-w-100 ">
                    <span>
                      <b>Company City : </b>
                    </span>
                    <p>
                      {" "}
                      {!CommonConfig.isObjectEmpty(viewUserData) &&
                      !CommonConfig.isEmpty(viewUserData?.CompanyCity)
                        ? viewUserData?.CompanyCity
                        : "-"}
                    </p>
                  </div>
                </div>

                <div className="p-col-12 p-md-6 p-pt-2">
                  <div className="p-w-100 ">
                    <span>
                      <b>Company Pincode : </b>
                    </span>
                    <p>
                      {" "}
                      {!CommonConfig.isObjectEmpty(viewUserData) &&
                      !CommonConfig.isEmpty(viewUserData?.CompanyPinCode)
                        ? viewUserData?.CompanyPinCode
                        : "-"}
                    </p>
                  </div>
                </div>
                <div className="p-col-12 p-md-6 p-pt-2">
                  <div className="p-w-100 ">
                    <span>
                      <b>Status :</b>
                    </span>
                    <p>
                      {" "}
                      {!CommonConfig.isObjectEmpty(viewUserData) &&
                      !CommonConfig.isEmpty(viewUserData?.Status)
                        ? viewUserData?.Status
                        : "-"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default Dashboard;
