import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import api from "../../utils/apiClient";
import CommonConfig from "../../utils/constant";
import APIConstant from "../../utils/pathConstants";
import Toast from "../Shared/Toast/Toast";
import Loader from "../Shared/Loader/Loader";
import { Button } from "primereact/button";

const Item = () => {
  const [securityUserID, setSecurityUserID] = useState(null);
  const [serviceMasterId, setServiceMasterId] = useState(null);

  const [hsnCode, setHSNCode] = useState("");
  const [hsnCodeErr, setHSNCodeErr] = useState(false);
  const [hsnCodeErrText, setHSNCodeErrText] = useState("");

  const [name, setName] = useState("");
  const [nameErr, setNameErr] = useState(false);
  const [nameErrText, setNameErrText] = useState("");

  const [customName, setCustomName] = useState("");
  const [customNameErr, setCustomNameErr] = useState(false);
  const [customNameErrText, setCustomNameErrText] = useState("");

  const [gst, setGST] = useState("");
  const [gstErr, setGSTErr] = useState(false);
  const [gstErrText, setGSTErrText] = useState("");

  const [globalFilter, setGlobalFilter] = useState("");
  const [serviceMasterList, setServiceMasterList] = useState([]);
  const [addServiceMasterModal, setAddServiceMasterModal] = useState(false);
  const [isDeleteServiceMasterModal, setIsDeleteServiceMasterModal] =
    useState(false);

  useEffect(() => {
    const loginData = CommonConfig.loginData();
    setSecurityUserID(loginData?.UserId);
    getAdminServiceMaster(loginData.UserId);
  }, []);

  const resetScreen = async () => {
    setSecurityUserID(null);
    setAddServiceMasterModal(false);

    setHSNCode("");
    setHSNCodeErr(false);
    setHSNCodeErrText("");

    setName("");
    setNameErr(false);
    setNameErrText("");

    setCustomName("");
    setCustomNameErr(false);
    setCustomNameErrText("");

    setGST("");
    setGSTErr(false);
    setGSTErrText("");
  };

  const handleChange = (e, type) => {
    if (type === "hsnCode") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setHSNCode("");
        setHSNCodeErr(true);
        setHSNCodeErrText("HSN code is required");
      } else {
        setHSNCode(e.target.value);
        setHSNCodeErr(false);
        setHSNCodeErrText("");
      }
    }
    if (type === "name") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setName("");
        setNameErr(true);
        setNameErrText("Name is required");
      } else {
        setName(e.target.value);
        setNameErr(false);
        setNameErrText("");
      }
    }
    if (type === "customName") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setCustomName("");
        setCustomNameErr(true);
        setCustomNameErrText("Custom name is required");
      } else {
        setCustomName(e.target.value);
        setCustomNameErr(false);
        setCustomNameErrText("");
      }
    }
    if (type === "gst") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setGST("");
        setGSTErr(true);
        setGSTErrText("GST is required");
      } else {
        setGST(e.target.value);
        setGSTErr(false);
        setGSTErrText("");
      }
    }
  };

  const validate = () => {
    let formIsValid = true;
    if (CommonConfig.isEmpty(hsnCode)) {
      formIsValid = false;
      setHSNCodeErr(true);
      setHSNCodeErrText("HSN code is required");
    } else {
      setHSNCodeErr(false);
      setHSNCodeErrText("");
    }
    if (CommonConfig.isEmpty(name)) {
      formIsValid = false;
      setNameErr(true);
      setNameErrText("Name is required");
    } else {
      setNameErr(false);
      setNameErrText("");
    }
    if (CommonConfig.isEmpty(customName)) {
      formIsValid = false;
      setCustomNameErr(true);
      setCustomNameErrText("Custom name is required");
    } else {
      setCustomNameErr(false);
      setCustomNameErrText("");
    }
    if (CommonConfig.isEmpty(gst)) {
      formIsValid = false;
      setGSTErr(true);
      setGSTErrText("GST is required");
    } else {
      setGSTErr(false);
      setGSTErrText("");
    }
    return formIsValid;
  };

  const AddUpdateAdminServiceMaster = async (e) => {
    if (validate(e)) {
      try {
        let data = {
          TarrifMasterID: serviceMasterId,
          HSNCode: hsnCode,
          Name: name,
          Unit: customName,
          IGST: gst,
          Type: "Active",
        };
        Loader.show();
        await api
          .post(APIConstant.path.AddUpdateAdminHSNCodeMaster, data)
          .then((response) => {
            let res = response;
            if (res.success) {
              Toast.success({ message: res.message });
              resetScreen();
              handleClose("AddServiceMaster");
              getAdminServiceMaster();
              Loader.hide();
            } else {
              Toast.error({ message: res.message });
              Loader.hide();
            }
          })
          .catch((err) => {});
      } catch (err) {
        console.log(err);
      }
    }
  };

  const getAdminServiceMaster = async (ID) => {
    try {
      let data = {
        // UserID: securityUserID,
      };
      await api
        .post(APIConstant.path.GetAdminHSNCodeMaster, data)
        .then(async (response) => {
          let res = response;
          if (res.success) {
            setServiceMasterList(res.data);
            Loader.hide();
          }
        })
        .catch((err) => {
          Loader.hide();
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const DeleteAdminServiceMaster = async (ID) => {
    try {
      Loader.show();
      let data = {
        ID: serviceMasterId,
      };
      await api
        .post(APIConstant.path.DeleteAdminHSNCodeMaster, data)
        .then(async (res) => {
          if (res.success) {
            handleClose("DeleteServiceMaster");
            getAdminServiceMaster();
            setServiceMasterId();
            Loader.hide();
          } else {
            Loader.hide();
          }
        })
        .catch((err) => {
          Loader.hide();
          Toast.error({ message: err });
        });
    } catch (err) {
      Loader.hide();
      Toast.error({ message: err });
    }
  };

  const handleClose = async (type) => {
    if (type === "AddServiceMaster") {
      setSecurityUserID(null);
      setAddServiceMasterModal(false);

      setHSNCode("");
      setHSNCodeErr(false);
      setHSNCodeErrText("");

      setName("");
      setNameErr(false);
      setNameErrText("");

      setCustomName("");
      setCustomNameErr(false);
      setCustomNameErrText("");

      setGST("");
      setGSTErr(false);
      setGSTErrText("");
    } else if (type === "DeleteServiceMaster") {
      setServiceMasterId(null);
      setIsDeleteServiceMasterModal(false);
    }
  };

  const handleOpen = (item, type) => {
    if (type === "AddServiceMaster") {
      setAddServiceMasterModal(true);
    } else if (type === "EditServiceMaster") {
      setServiceMasterId(item._id);
      setHSNCode(item.TarrifCode);
      setName(item.Name);
      setCustomName(item.Unit);
      setGST(item.IGST);
      setAddServiceMasterModal(true);
    } else if (type === "DeleteServiceMaster") {
      setServiceMasterId(item._id);
      setIsDeleteServiceMasterModal(true);
    }
  };

  const actionBodyTemplateSrNo = (data, props) => {
    return props.rowIndex + 1;
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="table-icon">
        <Button
          className=""
          onClick={() => handleOpen(rowData, "EditServiceMaster")}
          tooltip={"Edit"}
          tooltipOptions={{ className: "bluegray-tooltip", position: "top" }}
        >
          <i className="icon-edit"></i>
        </Button>
        <Button
          className=""
          onClick={() => handleOpen(rowData, "DeleteServiceMaster")}
          tooltip={"Delete"}
          tooltipOptions={{ className: "bluegray-tooltip", position: "top" }}
        >
          <i className="icon-delete"></i>
        </Button>
      </div>
    );
  };

  return (
    <div className="user-container">
      <div className="card-header">
        <div className="card-title p-d-lg-flex p-ai-center p-w-100">
          <h3 className="p-mb-3 p-mb-lg-0">HSN Code</h3>
          <div className="p-ml-auto p-d-lg-flex p-ai-center">
            <span className="searchbar-area">
              <InputText
                type="search"
                onChange={(e) => setGlobalFilter(e.target.value)}
                placeholder="Global Search"
                size="30"
              />
              <i className="icon-search"></i>
            </span>
          </div>
          <div className="card-actions p-ml-2">
            <Button
              type="button"
              className="add-btn add-btn p-button p-component p-jc-center p-w-100"
              onClick={(e) => handleOpen(e, "AddServiceMaster")}
            >
              <i className="icon-add"></i>Add HSN Code
            </Button>
          </div>
        </div>
      </div>
      <div className="card">
        <DataTable
          globalFilter={globalFilter}
          paginator
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          className="custom-table"
          value={serviceMasterList}
          scrollable
          scrollHeight="calc(100vh - 203px)"
        >
          <Column
            field="Index"
            header="Sr.No"
            body={actionBodyTemplateSrNo}
            style={{ maxWidth: 150 }}
          ></Column>
          <Column
            field="TarrifCode"
            header="HSN Code"
            style={{ width: "25%" }}
          ></Column>
          <Column field="Name" header="Name" style={{ width: "50%" }}></Column>
          <Column field="Unit" header="Unit" style={{ width: "13%" }}></Column>
          <Column field="IGST" header="GST" style={{ width: "12%" }}></Column>
          <Column header="Action" body={actionBodyTemplate}></Column>
        </DataTable>
      </div>

      {/* Add Confirm Modal */}
      <Dialog
        header={serviceMasterId ? "Edit HSN Code" : "Add HSN Code"}
        visible={addServiceMasterModal}
        className="dialog-popup"
        onHide={(e) => handleClose("AddServiceMaster")}
        draggable={false}
        closable={false}
        position="top"
        style={{ width: "900px", height: "300px" }}
      >
        <div>
          <div className="card-body">
            <div className="form-area">
              <div className={"p-grid"}>
                <div className="p-col-18 p-md-5 p-pt-2">
                  <div className="p-w-100 ">
                    <div className="custom-inputs">
                      <label htmlFor="hsnCode">
                        HSN Code <span style={{ color: "red" }}>*</span>:{" "}
                      </label>
                      <InputText
                        className="p-w-100 p-mt-2"
                        id="hsnCode"
                        type="text"
                        placeholder="HSN Code"
                        value={hsnCode}
                        autoFocus={true}
                        onChange={(e) => handleChange(e, "hsnCode")}
                        maxLength={30}
                      />
                    </div>
                    <span className="error-msg" style={{ color: "red" }}>
                      {hsnCodeErr ? hsnCodeErrText : null}
                    </span>
                  </div>
                </div>

                <div className="p-col-18 p-md-5 p-pt-2">
                  <div className="p-w-100 ">
                    <div className="custom-inputs">
                      <label htmlFor="name">
                        Name <span style={{ color: "red" }}>*</span>:{" "}
                      </label>
                      <InputText
                        className="p-w-100 p-mt-2"
                        id="name"
                        type="text"
                        placeholder="Name"
                        value={name}
                        autoFocus={true}
                        onChange={(e) => handleChange(e, "name")}
                        maxLength={30}
                      />
                    </div>
                    <span className="error-msg" style={{ color: "red" }}>
                      {nameErr ? nameErrText : null}
                    </span>
                  </div>
                </div>

                <div className="p-col-18 p-md-5 p-pt-2">
                  <div className="p-w-100 ">
                    <div className="custom-inputs">
                      <label htmlFor="customName">
                        Custom name <span style={{ color: "red" }}>*</span>:{" "}
                      </label>
                      <InputText
                        className="p-w-100 p-mt-2"
                        id="customName"
                        type="text"
                        placeholder="Custom name"
                        value={customName}
                        autoFocus={true}
                        keyfilter={/^\d*\.?\d*$/}
                        onChange={(e) => handleChange(e, "customName")}
                        maxLength={30}
                      />
                    </div>
                    <span className="error-msg" style={{ color: "red" }}>
                      {customNameErr ? customNameErrText : null}
                    </span>
                  </div>
                </div>

                <div className="p-col-18 p-md-5 p-pt-2">
                  <div className="p-w-100 ">
                    <div className="custom-inputs">
                      <label htmlFor="gst">
                        GST <span style={{ color: "red" }}>*</span>:{" "}
                      </label>
                      <InputText
                        className="p-w-100 p-mt-2"
                        id="gst"
                        type="text"
                        placeholder="GST"
                        value={gst}
                        autoFocus={true}
                        keyfilter={/^\d*\.?\d*$/}
                        onChange={(e) => handleChange(e, "gst")}
                        maxLength={30}
                      />
                    </div>
                    <span className="error-msg" style={{ color: "red" }}>
                      {gstErr ? gstErrText : null}
                    </span>
                  </div>
                </div>
                <div className="card-footer">
                  <Button
                    label="Submit"
                    severity="secondary"
                    raised
                    onClick={(e) => AddUpdateAdminServiceMaster(e)}
                  />
                  <button
                    className="btn-dialog-cancel"
                    onClick={(e) => handleClose("AddServiceMaster")}
                  >
                    {" "}
                    cancel{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>

      {/* Delete Confirm Modal */}
      <Dialog
        header="Delete  Service Master"
        visible={isDeleteServiceMasterModal}
        className="dialog-popup"
        onHide={(e) => handleClose("DeleteServiceMaster")}
        draggable={false}
        closable={false}
        position="top"
      >
        <span>Are you sure want to delete the Service Master ?</span>
        <div className="dialog-footer">
          <button
            className="btn-dialog-cancel"
            onClick={(e) => handleClose("DeleteServiceMaster")}
          >
            {" "}
            cancel{" "}
          </button>
          <button
            className="btn-dialog-delete"
            onClick={() => DeleteAdminServiceMaster()}
          >
            {" "}
            Delete{" "}
          </button>
        </div>
      </Dialog>
    </div>
  );
};

export default Item;
