import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import CommonConfig from "../../utils/constant";
import Toast from "../../components/Shared/Toast/Toast";
import Loader from "../../components/Shared/Loader/Loader";
import APIConstant from "../../utils/pathConstants";
import api from "../../utils/apiClient";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { useLocation } from "react-router-dom";

const CreatePlan = (props) => {
  const location = useLocation();
  const [securityUserID, setSecurityUserID] = useState("");
  const [planID, setPlanID] = useState("");

  const [planName, setPlanName] = useState("");
  const [planNameErr, setPlanNameErr] = useState(false);
  const [planNameErrText, setPlanNameErrText] = useState("");

  const [discountPrice, setDiscountPrice] = useState("");
  const [discountPriceErr, setDiscountPriceErr] = useState(false);
  const [discountPriceErrText, setDiscountPriceErrText] = useState("");

  const [planDuration, setPlanDuration] = useState("");
  const [planDurationErr, setPlanDurationErr] = useState(false);
  const [planDurationErrText, setPlanDurationErrText] = useState("");

  const [totalPrice, setTotalPrice] = useState("");
  const [totalPriceErr, setTotalPriceErr] = useState(false);
  const [totalPriceErrText, setTotalPriceErrText] = useState("");

  const [planDescription, setPlanDescription] = useState("");
  const [planDescriptionErr, setPlanDescriptionErr] = useState(false);
  const [planDescriptionErrText, setPlanDescriptionErrText] = useState("");

  const [userType, setUserType] = useState("");
  const [userTypeErr, setUserTypeErr] = useState(false);
  const [userTypeErrText, setUserTypeErrText] = useState("");
  const [userTypeList, setUserTypeList] = useState([]);
  const [planDurationList, setPlanDurationList] = useState([]);

  const [accountType, setAccountType] = useState("");
  const [accountTypeErr, setAccountTypeErr] = useState(false);
  const [accountTypeErrText, setAccountTypeErrText] = useState("");
  const [accountTypeList, setAccountTypeList] = useState([]);

  useEffect(() => {
    const loginData = CommonConfig.loginData();
    setSecurityUserID(loginData?.SecurityUserID);
    GetPlanDurationList();
    GetUserRoleList();
    getAccountType();

    if (location?.state?.PlanID) {
      getPlanById(location?.state?.PlanID);
    }
  }, []);

  const GetPlanDurationList = async (ID) => {
    try {
      let data = {
        type: "user_plan",
      };
      await api
        .post(APIConstant.path.GetStringMap, data)
        .then(async (response) => {
          let res = response;
          if (res.success) {
            setPlanDurationList(res.data.map(data => ({...data, value: data._id})));
            Loader.hide();
          }
        })
        .catch((err) => {
          Loader.hide();
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const GetUserRoleList = async () => {
    try {
      await api
        .post(APIConstant.path.GetUserRole)
        .then(async (response) => {
          let res = response;
          if (res.success) {
            setUserTypeList(res.data);
            Loader.hide();
          }
        })
        .catch((err) => {
          Loader.hide();
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const getPlanById = async (ID) => {
    try {
      let data = {
        PlanId: ID,
      };
      await api
        .post(APIConstant.path.GetAdminPlanById, data)
        .then(async (response) => {
          let res = response;
          if (res.success) {
            Loader.hide();
            setPlanID(res?.data?.PlanId);
            setPlanName(res?.data?.PlanName);
            setDiscountPrice(res?.data?.PlanDiscPrice);
            setPlanDuration(res?.data?.PlanDuration);
            setTotalPrice(res?.data?.PlanTotalPrice);
            setPlanDescription(res?.data?.PlanDesc);
            setAccountType(res?.data?.AccountType);
            setUserType(res?.data?.UserRoleId);
          }
        })
        .catch((err) => {
          Loader.hide();
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const resetScreen = async () => {
    setSecurityUserID(null);

    setPlanName("");
    setPlanNameErr(false);
    setPlanNameErrText("");

    setDiscountPrice("");
    setDiscountPriceErr(false);
    setDiscountPriceErrText("");

    setPlanDuration("");
    setPlanDurationErr(false);
    setPlanDurationErrText("");

    setUserType("");
    setUserTypeErr(false);
    setUserTypeErrText("");

    setAccountType("");
    setAccountTypeErr(false);
    setAccountTypeErrText("");

    setTotalPrice("");
    setTotalPriceErr(false);
    setTotalPriceErrText("");

    setPlanDescription("");
    setPlanDescriptionErr(false);
    setPlanDescriptionErrText("");
  };

  const getAccountType = async (ID) => {
    try {
      let data = {
        type: "account_type",
      };
      await api
        .post(APIConstant.path.GetStringMap, data)
        .then(async (response) => {
          let res = response;
          if (res.success) {
            setAccountTypeList(res.data.map(data => ({...data, value: data._id})));
            Loader.hide();
          }
        })
        .catch((err) => {
          Loader.hide();
          console.log(err);
        });
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleChange = (e, type) => {
    if (type === "planName") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setPlanName("");
        setPlanNameErr(true);
        setPlanNameErrText("Plan Name is required");
      } else {
        setPlanName(e.target.value);
        setPlanNameErr(false);
        setPlanNameErrText("");
      }
    } else if (type === "discountPrice") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setDiscountPrice("");
        setDiscountPriceErr(true);
        setDiscountPriceErrText("Discount Price is required");
      } else {
        setDiscountPrice(e.target.value);
        setDiscountPriceErr(false);
        setDiscountPriceErrText("");
      }
    } else if (type === "planDescription") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setPlanDescription("");
        setPlanDescriptionErr(true);
        setPlanDescriptionErrText("Plan description is required");
      } else {
        setPlanDescription(e.target.value);
        setPlanDescriptionErr(false);
        setPlanDescriptionErrText("");
      }
    } else if (type === "totalPrice") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setTotalPrice("");
        setTotalPriceErr(true);
        setTotalPriceErrText("Total price is required");
      } else {
        setTotalPrice(e.target.value);
        setTotalPriceErr(false);
        setTotalPriceErrText("");
      }
    } else if (type === "accountType") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setAccountType("");
        setAccountTypeErr(true);
        setAccountTypeErrText("Account Type is required");
      } else {
        setAccountType(e.target.value);
        setAccountTypeErr(false);
        setAccountTypeErrText("");
      }
    } else if (type === "planDuration") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setPlanDuration("");
        setPlanDurationErr(true);
        setPlanDurationErrText("Plan duration is required");
      } else {
        setPlanDuration(e.target.value);
        setPlanDurationErr(false);
        setPlanDurationErrText("");
      }
    } else if (type === "userType") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setUserType("");
        setUserTypeErr(true);
        setUserTypeErrText("User type is required");
      } else {
        setUserType(e.target.value);
        setUserTypeErr(false);
        setUserTypeErrText("");
      }
    }
  };

  const validate = () => {
    let formIsValid = true;
    if (CommonConfig.isEmpty(planName)) {
      formIsValid = false;
      setPlanNameErr(true);
      setPlanNameErrText("Plan Name is required");
    } else {
      setPlanNameErr(false);
      setPlanNameErrText("");
    }

    if (CommonConfig.isEmpty(discountPrice)) {
      formIsValid = false;
      setDiscountPriceErr(true);
      setDiscountPriceErrText("Discount price is required");
    } else {
      setDiscountPriceErr(false);
      setDiscountPriceErrText("");
    }

    if (CommonConfig.isEmpty(planDuration)) {
      formIsValid = false;
      setPlanDurationErr(true);
      setPlanDurationErrText("Plan duration is required");
    } else {
      setPlanDurationErr(false);
      setPlanDurationErrText("");
    }

    if (CommonConfig.isEmpty(userType)) {
      formIsValid = false;
      setUserTypeErr(true);
      setUserTypeErrText("User Type is required");
    } else {
      setUserTypeErr(false);
      setUserTypeErrText("");
    }

    if (CommonConfig.isEmpty(accountType)) {
      formIsValid = false;
      setAccountTypeErr(true);
      setAccountTypeErrText("Account type is required");
    } else {
      setAccountTypeErr(false);
      setAccountTypeErrText("");
    }

    if (CommonConfig.isEmpty(totalPrice)) {
      formIsValid = false;
      setTotalPriceErr(true);
      setTotalPriceErrText("Total Price is required");
    } else {
      setTotalPriceErr(false);
      setTotalPriceErrText("");
    }

    if (CommonConfig.isEmpty(planDescription)) {
      formIsValid = false;
      setPlanDescriptionErr(true);
      setPlanDescriptionErrText("Plan dscription is required");
    } else {
      setPlanDescriptionErr(false);
      setPlanDescriptionErrText("");
    }

    return formIsValid;
  };

  const AddUpdateAdminPlan = async (e) => {
    if (validate(e)) {
      try {
        Loader.show();
        let data = {
          PlanId: planID,
          PlanName: planName,
          PlanDiscPrice: discountPrice,
          AccountType: accountType,
          PlanDuration: planDuration,
          PlanTotalPrice: totalPrice,
          PlanDesc: planDescription,
          UserRoleId: userType,
        };

        await api
          .post(APIConstant.path.AddUpdateAdminPlan, data)
          .then((response) => {
            let res = response;

            if (res.success) {
              Toast.success({ message: res.message });

              Loader.hide();
              props.navigate("/plan");
            } else {
              Toast.error({ message: res.message });
              Loader.hide();
            }
          })
          .catch((err) => {
            console.log(err);
            Loader.hide();
          });
      } catch (err) {
        console.log(err);
        Loader.hide();
      }
    }
  };

  return (
    <div className="user-container">
      <div className="p-d-lg-flex p-jc-between p-pb-2">
        <div className="heading-area">
          <i
            className="icon-chevron-down back-btn"
            onClick={() => props.navigate("/plan")}
          ></i>
          <h1>{props?.location?.state?.IsEdit ? "Edit Plan" : "Add Plan"}</h1>
        </div>
      </div>
      <div className="form-area p-d-flex p-flex-column p-jc-between">
        <div className={"p-grid"}>
          <div className="p-col-12 p-md-6 p-pt-2">
            <div className="p-w-100 ">
              <div className="custom-inputs">
                <label htmlFor="accountType">
                  Plan Name <span style={{ color: "red" }}>*</span>:{" "}
                </label>
                <InputText
                  className="p-w-100 p-mt-2"
                  id="planName"
                  type="text"
                  placeholder="Plan name"
                  value={planName}
                  autoFocus={true}
                  onChange={(e) => handleChange(e, "planName")}
                  // keyfilter={"alpha"}
                  maxLength={30}
                />
              </div>
              <span className="error-msg" style={{ color: "red" }}>
                {planNameErr ? planNameErrText : null}
              </span>
            </div>
          </div>

          <div className="p-col-12 p-md-6 p-pt-2">
            <div className="p-w-100 ">
              <div className="custom-inputs">
                <label htmlFor="accountType">
                  Account Type <span style={{ color: "red" }}>*</span>:{" "}
                </label>
                <Dropdown
                  className="p-w-100 p-mt-2"
                  optionLabel="name"
                  value={accountType}
                  options={accountTypeList}
                  onChange={(e) => handleChange(e, "accountType")}
                  filter
                  placeholder="Select Account Type"
                />
              </div>
              <span className="error-msg" style={{ color: "red" }}>
                {accountTypeErr ? accountTypeErrText : null}
              </span>
            </div>
          </div>

          <div className="p-col-12 p-md-4">
            <div className="p-w-100 ">
              <div className="custom-inputs">
                <label htmlFor="discountPrice">
                  Discount Price <span style={{ color: "red" }}>*</span>:{" "}
                </label>
                <InputText
                  className="p-w-100 p-mt-2"
                  id="discountPrice"
                  type="number"
                  placeholder="Discount Price"
                  value={discountPrice}
                  autoFocus={true}
                  onChange={(e) => handleChange(e, "discountPrice")}
                  keyfilter={"num"}
                  maxLength={30}
                />
              </div>
              <span className="error-msg" style={{ color: "red" }}>
                {discountPriceErr ? discountPriceErrText : null}
              </span>
            </div>
          </div>

          <div className="p-col-12 p-md-4">
            <div className="p-w-100 ">
              <div className="custom-inputs">
                <label htmlFor="planDuration">
                  Plan Duration <span style={{ color: "red" }}>*</span>:{" "}
                </label>
                <Dropdown
                  className="p-w-100 p-mt-2"
                  value={planDuration}
                  options={planDurationList}
                  filter
                  optionLabel="name"
                  onChange={(e) => handleChange(e, "planDuration")}
                  placeholder="Plan Duration"
                />
              </div>
              <span className="error-msg" style={{ color: "red" }}>
                {planDurationErr ? planDurationErrText : null}
              </span>
            </div>
          </div>

          <div className="p-col-12 p-md-4">
            <div className="p-w-100 ">
              <div className="custom-inputs">
                <label htmlFor="userType">
                  User Type<span style={{ color: "red" }}>*</span> :{" "}
                </label>
                <Dropdown
                  className="p-w-100 p-mt-2"
                  value={userType}
                  options={userTypeList}
                  onChange={(e) => handleChange(e, "userType")}
                  placeholder="User Type"
                  filter
                />
                {/* {userType && <p>You selected: {userType.label}</p>} */}
              </div>
              <span className="error-msg" style={{ color: "red" }}>
                {userTypeErr ? userTypeErrText : null}
              </span>
            </div>
          </div>
          <div className="p-col-12 p-md-4">
            <div className="p-w-100 ">
              <div className="custom-inputs">
                <label htmlFor="totalPrice">
                  Total Price <span style={{ color: "red" }}>*</span> :{" "}
                </label>
                <InputText
                  className="p-w-100 p-mt-2"
                  id="totalPrice"
                  type="number"
                  placeholder="Total Price"
                  value={totalPrice}
                  autoFocus={true}
                  onChange={(e) => handleChange(e, "totalPrice")}
                  keyfilter={"num"}
                  maxLength={30}
                />
              </div>
              <span className="error-msg" style={{ color: "red" }}>
                {totalPriceErr ? totalPriceErrText : null}
              </span>
            </div>
          </div>
          <div className="p-col-12 p-md-4">
            <div className="p-w-100 ">
              <div className="custom-inputs">
                <label htmlFor="planDescription">
                  Plan Description <span style={{ color: "red" }}>*</span>:
                </label>
                <InputTextarea
                  className="p-w-100 p-mt-2 p-h-100"
                  id="planDescription"
                  value={planDescription}
                  placeholder="Plan Description "
                  onChange={(e) => handleChange(e, "planDescription")}
                  rows={3}
                />
              </div>
              <span className="error-msg" style={{ color: "red" }}>
                {planDescriptionErr ? planDescriptionErrText : null}
              </span>
            </div>
          </div>
        </div>
        <div className="p-ml-auto p-jc-lg-end add-action">
          <a href="javascript:;" onClick={(e) => resetScreen()}>
            Reset
          </a>
          <Button
            type="button"
            className="add-btn submit"
            onClick={(e) => AddUpdateAdminPlan(e)}
          >
            SUBMIT
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CreatePlan;
