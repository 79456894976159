import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import api from "../../utils/apiClient";
import CommonConfig from "../../utils/constant";
import APIConstant from "../../utils/pathConstants";
import Toast from "../Shared/Toast/Toast";
import Loader from "../Shared/Loader/Loader";
import { Button } from "primereact/button";

const CompanyMaster = (props) => {
  const [userId, setUserId] = useState("");
  const [companyMasterId, setCompanyMasterId] = useState(null);
  const [globalFilter, setGlobalFilter] = useState("");
  const [companyMasterList, setCompanyMasterList] = useState([]);
  const [isDeleteCompanyModal, setDeleteCompanyModal] = useState(false);
  const [isViewCompanyModal, setViewCompanyModal] = useState(false);
  const [viewCompanyData, setViewCompanyData] = useState({});

  useEffect(() => {
    const loginData = CommonConfig.loginData();
    setUserId(loginData.UserId);
    getCompanyMaster(loginData.UserId);
  }, []);

  const resetScreen = async () => {
    const loginData = CommonConfig.loginData();
    setUserId(loginData.UserId);
    setCompanyMasterId(null);
    setCompanyMasterList([]);
    setDeleteCompanyModal(false);
    setViewCompanyModal(false);
    setViewCompanyData({});
    getCompanyMaster(loginData.UserId);
  };

  const handleOpen = async (item, type) => {
    console.log(item);
    if (type === "AddCompanyMaster") {
      props.navigate("/add-company-master", {
        state: { companyMasterId: null },
      });
    } else if (type === "EditCompanyMaster") {
      props.navigate("add-company-master", {
        state: { companyMasterId: item._id },
      });
    } else if (type === "DeleteCompanyMaster") {
      setCompanyMasterId(item._id);
      setDeleteCompanyModal(true);
    } else if (type === "ViewCompanyMaster") {
      setViewCompanyModal(true);
      setViewCompanyData(item);
    }
  };

  const handleClose = async (type) => {
    if (type === "DeleteCompanyMaster") {
      setCompanyMasterId(null);
      setDeleteCompanyModal(false);
    } else if (type === "ViewCompanyMaster") {
      setViewCompanyModal(false);
      setViewCompanyData({});
    }
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="table-icon">
        <Button
          className=""
          onClick={() => handleOpen(rowData, "ViewCompanyMaster")}
          tooltip={"View"}
          tooltipOptions={{ className: "bluegray-tooltip", position: "top" }}
        >
          <i className="icon-view"></i>
        </Button>
        <Button
          className=""
          onClick={() => handleOpen(rowData, "EditCompanyMaster")}
          tooltip={"Edit"}
          tooltipOptions={{ className: "bluegray-tooltip", position: "top" }}
        >
          <i className="icon-edit"></i>
        </Button>
        <Button
          className=""
          onClick={() => handleOpen(rowData, "DeleteCompanyMaster")}
          tooltip={"Delete"}
          tooltipOptions={{ className: "bluegray-tooltip", position: "top" }}
        >
          <i className="icon-delete"></i>
        </Button>
      </div>
    );
  };

  const actionBodyTemplateSrNo = (data, props) => {
    return props.rowIndex + 1;
  };

  const DeleteCompanyMaster = async () => {
    try {
      Loader.show();
      let data = {
        CompanyMasterId: companyMasterId,
      };
      await api
        .post(APIConstant.path.DeleteCompanyMaster, data)
        .then(async (res) => {
          let response = await res;
          if (response.success) {
            await resetScreen();
            Loader.hide();
          } else {
            Loader.hide();
          }
        })
        .catch((err) => {
          Loader.hide();
          Toast.error({ message: err });
        });
    } catch (err) {
      Loader.hide();
      Toast.error({ message: err });
    }
  };

  const getCompanyMaster = async (ID) => {
    try {
      let data = {
        UserId: ID,
      };
      await api
        .post(APIConstant.path.GetCompanyMaster, data)
        .then(async (response) => {
          let res = response;
          if (res.success) {
            setCompanyMasterList(res.data);
            Loader.hide();
          }
        })
        .catch((err) => {
          Loader.hide();
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="user-container">
      <div className="card-header">
        <div className="card-title p-d-lg-flex p-ai-center p-w-100">
          <h3 className="p-mb-3 p-mb-lg-0">Company Master</h3>
          <div className="p-ml-auto p-d-lg-flex p-ai-center">
            <span className="searchbar-area">
              <InputText
                type="search"
                onChange={(e) => setGlobalFilter(e.target.value)}
                placeholder="Global Search"
                size="30"
              />
              <i className="icon-search"></i>
            </span>
          </div>
          <div className="card-actions p-ml-2">
            <Button
              type="button"
              className="add-btn add-btn p-button p-component p-jc-center p-w-100"
              onClick={(e) => handleOpen(e, "AddCompanyMaster")}
            >
              <i className="icon-add"></i> Add Company Master
            </Button>
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="report-custom-table policy-custom-table">
          <DataTable
            globalFilter={globalFilter}
            paginator
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            rows={10}
            rowsPerPageOptions={[5, 10, 25, 50]}
            className="custom-table"
            value={companyMasterList}
            scrollable
            scrollHeight="calc(100vh - 203px)"
          >
            <Column
              field="Index"
              header="Sr.No"
              body={actionBodyTemplateSrNo}
              style={{ maxWidth: 150 }}
            ></Column>
            <Column field="GSTNo" header="GST No" sortable></Column>
            <Column field="PanNo" header="Pan No" sortable></Column>
            <Column field="CompanyName" header="Company Name" sortable></Column>
            <Column
              field="address.Locality.CountryName"
              header="Country"
              sortable
            ></Column>
            <Column field="PhoneNo" header="Phone No" sortable></Column>
            <Column field="address.Address" header="Address" sortable></Column>
            <Column field="address.City" header="City" sortable></Column>
            <Column field="address.State" header="State" sortable></Column>
            <Column field="address.Pincode" header="Pincode" sortable></Column>
            <Column field="WebsiteURL" header="Website URL" sortable></Column>
            <Column
              field="CompanyEmail"
              header="Company email"
              sortable
            ></Column>
            <Column
              field="RegistrationDate"
              header="Register date"
              body={(data) =>
                new Date(data.RegistrationDate)?.toLocaleDateString()
              }
              sortable
            ></Column>
            <Column header="Action" body={actionBodyTemplate}></Column>
          </DataTable>
        </div>
      </div>
      {/* Delete Confirm Modal */}
      <Dialog
        header="Delete User"
        visible={isDeleteCompanyModal}
        className="dialog-popup"
        onHide={(e) => handleClose("DeleteCompanyMaster")}
        draggable={false}
        closable={false}
        position="top"
      >
        <span>Are you sure want to delete Company Master ?</span>
        <div className="dialog-footer">
          <button
            className="btn-dialog-cancel"
            onClick={(e) => handleClose("DeleteCompanyMaster")}
          >
            cancel
          </button>
          <button
            className="btn-dialog-delete"
            onClick={() => DeleteCompanyMaster()}
          >
            Delete
          </button>
        </div>
      </Dialog>

      {/* View Confirm Modal */}
      <Dialog
        header="View Company Master"
        visible={isViewCompanyModal}
        className="dialog-popup"
        onHide={(e) => handleClose("ViewCompanyMaster")}
        draggable={false}
        closable={true}
        position="center"
        style={{ width: "50%", height: "500px" }}
      >
        <div className="modalBody">
          <div className={"p-grid"}>
            <div className="p-col-12 p-md-12 p-pl-0 p-py-0">
              <div className={"p-grid"}>
                <div className="p-col-12 p-md-6 p-pt-2">
                  <div className="p-w-100 ">
                    <span>
                      <b>GST No : </b>
                    </span>
                    <p>
                      {" "}
                      {!CommonConfig.isObjectEmpty(viewCompanyData) &&
                      !CommonConfig.isEmpty(viewCompanyData?.GSTNo)
                        ? viewCompanyData?.GSTNo
                        : "-"}
                    </p>
                  </div>
                </div>
                <div className="p-col-12 p-md-6 p-pt-2">
                  <div className="p-w-100 ">
                    <span>
                      <b>Pan No :</b>
                    </span>
                    <p>
                      {" "}
                      {!CommonConfig.isObjectEmpty(viewCompanyData) &&
                      !CommonConfig.isEmpty(viewCompanyData?.PanNo)
                        ? viewCompanyData?.PanNo
                        : "-"}
                    </p>
                  </div>
                </div>
                <div className="p-col-12 p-md-6 p-pt-2">
                  <div className="p-w-100 ">
                    <span>
                      <b>Company name :</b>
                    </span>
                    <p>
                      {" "}
                      {!CommonConfig.isObjectEmpty(viewCompanyData) &&
                      !CommonConfig.isEmpty(viewCompanyData?.CompanyName)
                        ? viewCompanyData?.CompanyName
                        : "-"}
                    </p>
                  </div>
                </div>
                <div className="p-col-12 p-md-6 p-pt-2">
                  <div className="p-w-100 ">
                    <span>
                      <b>Phone No :</b>
                    </span>
                    <p>
                      {" "}
                      {!CommonConfig.isObjectEmpty(viewCompanyData) &&
                      !CommonConfig.isEmpty(viewCompanyData?.PhoneNo)
                        ? viewCompanyData?.PhoneNo
                        : "-"}
                    </p>
                  </div>
                </div>
                <div className="p-col-12 p-md-6 p-pt-2">
                  <div className="p-w-100 ">
                    <span>
                      <b>Address :</b>
                    </span>
                    <p>
                      {" "}
                      {!CommonConfig.isObjectEmpty(viewCompanyData) &&
                      !CommonConfig.isEmpty(viewCompanyData?.address?.Address)
                        ? viewCompanyData?.address?.Address
                        : "-"}
                    </p>
                  </div>
                </div>
                <div className="p-col-12 p-md-6 p-pt-2">
                  <div className="p-w-100 ">
                    <span>
                      <b>Country :</b>
                    </span>
                    <p>
                      {" "}
                      {!CommonConfig.isObjectEmpty(viewCompanyData) &&
                      !CommonConfig.isEmpty(
                        viewCompanyData?.address?.Locality?.CountryName
                      )
                        ? viewCompanyData?.address?.Locality?.CountryName
                        : "-"}
                    </p>
                  </div>
                </div>
                <div className="p-col-12 p-md-6 p-pt-2">
                  <div className="p-w-100 ">
                    <span>
                      <b>City :</b>
                    </span>
                    <p>
                      {" "}
                      {!CommonConfig.isObjectEmpty(viewCompanyData) &&
                      !CommonConfig.isEmpty(viewCompanyData?.address?.City)
                        ? viewCompanyData?.address?.City
                        : "-"}
                    </p>
                  </div>
                </div>
                <div className="p-col-12 p-md-6 p-pt-2">
                  <div className="p-w-100 ">
                    <span>
                      <b>State :</b>
                    </span>
                    <p>
                      {" "}
                      {!CommonConfig.isObjectEmpty(viewCompanyData) &&
                      !CommonConfig.isEmpty(viewCompanyData?.address?.State)
                        ? viewCompanyData?.address?.State
                        : "-"}
                    </p>
                  </div>
                </div>
                <div className="p-col-12 p-md-6 p-pt-2">
                  <div className="p-w-100 ">
                    <span>
                      <b> Pincode :</b>
                    </span>
                    <p>
                      {" "}
                      {!CommonConfig.isObjectEmpty(viewCompanyData) &&
                      !CommonConfig.isEmpty(viewCompanyData?.address?.Pincode)
                        ? viewCompanyData?.address?.Pincode
                        : "-"}
                    </p>
                  </div>
                </div>
                <div className="p-col-12 p-md-6 p-pt-2">
                  <div className="p-w-100 ">
                    <span>
                      <b>Website URL :</b>
                    </span>
                    <p>
                      {" "}
                      {!CommonConfig.isObjectEmpty(viewCompanyData) &&
                      !CommonConfig.isEmpty(viewCompanyData?.WebsiteURL)
                        ? viewCompanyData?.WebsiteURL
                        : "-"}
                    </p>
                  </div>
                </div>
                <div className="p-col-12 p-md-6 p-pt-2">
                  <div className="p-w-100 ">
                    <span>
                      <b>Company Email :</b>
                    </span>
                    <p>
                      {" "}
                      {!CommonConfig.isObjectEmpty(viewCompanyData) &&
                      !CommonConfig.isEmpty(viewCompanyData?.CompanyEmail)
                        ? viewCompanyData?.CompanyEmail
                        : "-"}
                    </p>
                  </div>
                </div>
                <div className="p-col-12 p-md-6 p-pt-2">
                  <div className="p-w-100 ">
                    <span>
                      <b>Registration Date :</b>
                    </span>
                    <p>
                      {" "}
                      {!CommonConfig.isObjectEmpty(viewCompanyData) &&
                      !CommonConfig.isEmpty(viewCompanyData?.RegistrationDate)
                        ? new Date(
                            viewCompanyData?.RegistrationDate
                          ).toLocaleDateString()
                        : "-"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="dialog-footer">
          <button
            className="btn-dialog-cancel"
            onClick={(e) => handleClose("ViewCompanyMaster")}
          >
            cancel
          </button>
        </div>
      </Dialog>
    </div>
  );
};

export default CompanyMaster;
