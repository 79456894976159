import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import CommonConfig from "../../utils/constant";
import Toast from "../../components/Shared/Toast/Toast";
import Loader from "../../components/Shared/Loader/Loader";
import APIConstant from "../../utils/pathConstants";
import api from "../../utils/apiClient";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import { Checkbox } from "primereact/checkbox";
import { useLocation } from "react-router-dom";

const AddUser = (props) => {
  const location = useLocation();
  const [securityUserID, setSecurityUserID] = useState("");
  const [userID, setUserID] = useState("");

  const [userType, setUserType] = useState("");
  const [userTypeErr, setUserTypeErr] = useState(false);
  const [userTypeErrText, setUserTypeErrText] = useState("");
  const [userTypeList, setUserTypeList] = useState([]);

  const [companyId, setCompanyId] = useState("");
  const [companyIdErr, setCompanyIdErr] = useState(false);
  const [companyIdErrText, setCompanyIdErrText] = useState("");

  const [firstName, setFirstName] = useState("");
  const [firstNameErr, setFirstNameErr] = useState(false);
  const [firstNameErrText, setFirstNameErrText] = useState("");

  const [mobileNumber, setMobileNumber] = useState("");
  const [mobileNumberErr, setMobileNumberErr] = useState(false);
  const [mobileNumberErrText, setMobileNumberErrText] = useState("");

  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState(false);
  const [emailErrText, setEmailErrText] = useState("");

  const [password, setPassword] = useState("");
  const [passwordErr, setPasswordErr] = useState(false);
  const [passwordErrText, setPasswordErrText] = useState("");

  const [plan, setPlan] = useState("");
  const [planErr, setPlanErr] = useState(false);
  const [planErrText, setPlanErrText] = useState("");
  const [userPlanList, setUserPlanList] = useState([]);

  const [accountType, setAccountType] = useState("");
  const [accountTypeErr, setAccountTypeErr] = useState(false);
  const [accountTypeErrText, setAccountTypeErrText] = useState("");

  const [accountTypeMemberId, setAccountTypeMemberId] = useState("");

  const [accountTypeList, setAccountTypeList] = useState([]);

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loginData = CommonConfig.loginData();
    getAccountType();
    setSecurityUserID(loginData?.UserId);
    if (location?.state?.userID) {
      getUserById(location?.state?.userID);
    } else {
      GetUserRoleList();
      GetUserPlanList();
    }
  }, []);

  const getAccountType = async () => {
    try {
      let data = {
        type: "account_type",
      };
      await api
        .post(APIConstant.path.GetStringMap, data)
        .then(async (response) => {
          let res = response;
          if (res.success) {
            setAccountTypeList(
              res.data.map((data) => ({ label: data.name, value: data._id }))
            );
            setAccountType(
              res.data.find((data) => data.value === "NewCompany")._id
            );
            setAccountTypeMemberId(
              res.data.find((data) => data.value === "NewMember")._id
            );
            Loader.hide();
          }
        })
        .catch((err) => {
          Loader.hide();
          console.log(err);
        });
    } catch (err) {
      console.log("err", err);
    }
  };

  const getUserById = async (ID) => {
    try {
      let data = {
        UserId: ID,
      };
      await api
        .post(APIConstant.path.getUserById, data)
        .then(async (response) => {
          let res = await response;
          if (res.success) {
            await GetUserRoleList(res?.data?.AccountType);
            await GetUserPlanList(
              res?.data?.UserRoleId,
              res?.data?.AccountType
            );

            Loader.hide();
            setUserID(res?.data?.UserId);
            setUserType(res?.data?.UserRoleId);
            setFirstName(res?.data?.Name);
            setMobileNumber(res?.data?.PhoneNumber);
            setEmail(res?.data?.Email);
            setPassword(res?.data?.Password || "");
            setPlan(res?.data?.PlanId);
            setAccountType(res?.data?.AccountType);
            setCompanyId(res?.data?.CompanyId);
          }
        })
        .catch((err) => {
          Loader.hide();
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const GetUserRoleList = async (value) => {
    let params = {
      AccountType: value,
    };
    try {
      await api
        .post(APIConstant.path.getUserListByAccountType, params)
        .then(async (response) => {
          // await api.post(APIConstant.path.GetUserRole).then(async response => {
          let res = response;
          if (res.success) {
            setUserTypeList(
              res.data.map((role) => ({
                label: role.RoleName,
                value: role._id,
              }))
            );
            Loader.hide();
          }
        })
        .catch((err) => {
          Loader.hide();
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const GetUserPlanList = async (UserType, AccountType) => {
    try {
      var params = {
        AccountType: AccountType,
        UserType: UserType,
      };
      await api
        .post(APIConstant.path.getUserPlanByAccountAndUserType, params)
        .then((res) => {
          // await api.post(APIConstant.path.GetAdminPlan).then(async response => {
          // let res = response;
          if (res.success) {
            setUserPlanList(
              res.data.map((plan) => ({
                ...plan,
                label: plan.PlanName,
                value: plan._id,
              }))
            );
            Loader.hide();
          }
        })
        .catch((err) => {
          Loader.hide();
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const resetScreen = async () => {
    setSecurityUserID(null);

    setAccountType("");
    setAccountTypeErr(false);
    setAccountTypeErrText("");

    setUserType("");
    setUserTypeErr(false);
    setUserTypeErrText("");

    setFirstName("");
    setFirstNameErr(false);
    setFirstNameErrText("");

    setMobileNumber("");
    setMobileNumberErr(false);
    setMobileNumberErrText("");

    setEmail("");
    setEmailErr(false);
    setEmailErrText("");

    setPassword("");
    setPasswordErr(false);
    setPasswordErrText("");

    setPlan("");
    setPlanErr(false);
    setPlanErrText("");
  };

  const handleChange = (e, type) => {
    if (type === "accountType") {
      setAccountType(e.value);
      setUserType("");
      setPlan("");
      GetUserRoleList(e.value);
      GetUserPlanList(userType, e.value);

      setCompanyId("");
      setCompanyIdErr(false);
      setCompanyIdErrText("");
    } else if (type === "userType") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setUserType("");
        setUserTypeErr(true);
        setUserTypeErrText("User Type is required");
      } else {
        setUserType(e.target.value);
        GetUserPlanList(e.value, accountType);
        setUserTypeErr(false);
        setUserTypeErrText("");
      }
    } else if (type === "firstName") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setFirstName("");
        setFirstNameErr(true);
        setFirstNameErrText("First Name is required");
      } else {
        setFirstName(e.target.value);
        setFirstNameErr(false);
        setFirstNameErrText("");
      }
    } else if (type === "mobileNumber") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setMobileNumber("");
        setMobileNumberErr(true);
        setMobileNumberErrText("Mobile number is required");
      } else {
        setMobileNumber(e.target.value);
        setMobileNumberErr(false);
        setMobileNumberErrText("");
      }
    } else if (type === "email") {
      const value = e.target.value;
      setEmail(value);

      if (CommonConfig.isEmpty(value)) {
        setEmailErr(true);
        setEmailErrText("Email is required");
      } else if (!CommonConfig.RegExp.email.test(value)) {
        setEmailErr(true);
        setEmailErrText("Invalid email address");
      } else {
        setEmailErr(false);
        setEmailErrText("");
      }
    } else if (type === "password") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setPassword("");
        setPasswordErr(true);
        setPasswordErrText("Password is required");
      } else {
        setPassword(e.target.value);
        setPasswordErr(false);
        setPasswordErrText("");
      }
    } else if (type === "plan") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setPlan("");
        setPlanErr(true);
        setPlanErrText("Plan is required");
      } else {
        setPlan(e.target.value);
        setPlanErr(false);
        setPlanErrText("");
      }
    } else if (type == "AccountType") {
      setAccountType(e.value);
    } else if (type == "companyId") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setCompanyId("");
        setCompanyIdErr(true);
        setCompanyIdErrText("Company id is required");
      } else {
        setCompanyId(e.target.value);
        setCompanyIdErr(false);
        setCompanyIdErrText("");
      }
    }
  };

  const validate = () => {
    let formIsValid = true;
    if (CommonConfig.isEmpty(userType)) {
      formIsValid = false;
      setUserTypeErr(true);
      setUserTypeErrText("User type is required");
    } else {
      setUserTypeErr(false);
      setUserTypeErrText("");
    }
    if (CommonConfig.isEmpty(firstName)) {
      formIsValid = false;
      setFirstNameErr(true);
      setFirstNameErrText("Name is required");
    } else {
      setFirstNameErr(false);
      setFirstNameErrText("");
    }
    if (CommonConfig.isEmpty(mobileNumber)) {
      formIsValid = false;
      setMobileNumberErr(true);
      setMobileNumberErrText("Mobile number is required");
    } else {
      setMobileNumberErr(false);
      setMobileNumberErrText("");
    }
    if (CommonConfig.isEmpty(email)) {
      formIsValid = false;
      setEmailErr(true);
      setEmailErrText("Email is required");
    } else {
      setEmailErr(false);
      setEmailErrText("");
    }
    if (CommonConfig.isEmpty(props.location?.state?.userID)) {
      if (CommonConfig.isEmpty(password)) {
        formIsValid = false;
        setPasswordErr(true);
        setPasswordErrText("Password is required");
      } else {
        setPasswordErr(false);
        setPasswordErrText("");
      }
    }
    if (CommonConfig.isEmpty(plan)) {
      formIsValid = false;
      setPlanErr(true);
      setPlanErrText("Plan is required");
    } else {
      setPlanErr(false);
      setPlanErrText("");
    }
    if (CommonConfig.isEmpty(accountType)) {
      formIsValid = false;
      setAccountTypeErr(true);
      setAccountTypeErrText("Account type is required");
    } else {
      setAccountTypeErr(false);
      setAccountTypeErrText("");
    }
    if (accountType === accountTypeMemberId) {
      if (CommonConfig.isEmpty(companyId)) {
        formIsValid = false;
        setCompanyIdErr(true);
        setCompanyIdErrText("Company Id is required");
      } else {
        setCompanyIdErr(false);
        setCompanyIdErrText("");
      }
    }
    return formIsValid;
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const AddUpdateUser = async (e) => {
    if (validate(e)) {
      try {
        let data = {
          UserId: userID,
          Name: firstName,
          Email: email,
          Password: password,
          UserRole: userTypeList.find((x) => x.value === userType)?.label,
          UserRoleId: userType,
          PhoneNumber: mobileNumber,
          PlanId: plan,
          isMailVerified: 1,
          isPhoneVerified: 1,
          AccountType: accountType,
          CompanyId: companyId,
          // TempUserRollName:
          ProfilePic: "user.jpg",
          PurchaseAmount: userPlanList.find((plan) => plan.value === plan)
            ?.PlanTotalPrice,
        };
        Loader.show();
        setLoading(true);
        await api
          .post(APIConstant.path.AddUpdateUser, data)
          .then((response) => {
            let res = response;

            if (res.success) {
              setLoading(false);
              Toast.success({ message: res.message });
              Loader.hide();
              props.navigate("/users");
            } else {
              setLoading(false);

              Toast.error({ message: res.message });
              Loader.hide();
            }
          })
          .catch((err) => {
            setLoading(false);
          });
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <div className="user-container p-d-flex p-flex-column p-jc-between">
      <div>
        <div className="card-header">
          <div className="card-title p-d-lg-flex p-ai-center p-w-100">
            <div className="heading-area">
              <i
                className="icon-chevron-down back-btn"
                onClick={() => props.navigate("/users")}
              ></i>
            </div>
            <h3 className="p-mb-3 p-mb-lg-0">{userID ? "Edit" : "Add"} User</h3>
          </div>
        </div>
        <div className="card-body">
          <div className="form-area">
            <div className={"p-grid"}>
              <div className="p-col-12 p-pt-2">
                <div className="p-w-100 ">
                  <div className="custom-inputs">
                    <label htmlFor="accountType" className="p-d-block p-mb-3">
                      Select Account Type
                    </label>
                    <div className="p-d-flex p-ai-center p-jc-between">
                      {accountTypeList?.length &&
                        accountTypeList?.map((item) => {
                          return (
                            <div
                              id="accountType"
                              key={item.value}
                              className="p-d-flex p-ai-center p-jc-between"
                            >
                              <RadioButton
                                inputId={item.value}
                                name="accountType"
                                value={item.value}
                                onChange={(e) => handleChange(e, "accountType")}
                                checked={accountType === item.value}
                              />
                              <label htmlFor={item.value} className="p-ml-2">
                                {item.label}
                              </label>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                  <span className="error-msg">
                    {accountTypeErr ? accountTypeErrText : null}
                  </span>
                </div>
              </div>

              {accountType && accountType === accountTypeMemberId ? (
                <div className="p-col-12 p-md-4 p-pt-2">
                  <div className="p-w-100 ">
                    <div className="custom-inputs">
                      <label htmlFor="companyId">
                        Company Id <span style={{ color: "red" }}>*</span>:{" "}
                      </label>
                      <InputText
                        className="p-w-100 p-mt-2"
                        id="companyId"
                        type="text"
                        placeholder="Company Id"
                        value={companyId}
                        onChange={(e) => handleChange(e, "companyId")}
                      />
                    </div>
                    <span className="error-msg" style={{ color: "red" }}>
                      {companyIdErr ? companyIdErrText : null}
                    </span>
                  </div>
                </div>
              ) : null}

              <div className="p-col-12 p-md-4 p-pt-2">
                <div className="p-w-100 ">
                  <div className="custom-inputs">
                    <label htmlFor="userType">
                      User Type <span style={{ color: "red" }}>*</span>:{" "}
                    </label>
                    <Dropdown
                      className="p-w-100 p-mt-2"
                      optionLabel="label"
                      value={userType}
                      options={userTypeList}
                      onChange={(e) => handleChange(e, "userType")}
                      filter
                      placeholder="User type"
                    />
                  </div>
                  <span className="error-msg" style={{ color: "red" }}>
                    {userTypeErr ? userTypeErrText : null}
                  </span>
                </div>
              </div>
              <div className="p-col-12 p-md-4 p-pt-2">
                <div className="p-w-100 ">
                  <div className="custom-inputs">
                    <label htmlFor="firstName">
                      Full Name <span style={{ color: "red" }}>*</span>:
                    </label>
                    <InputText
                      className="p-w-100 p-mt-2"
                      id="firstName"
                      type="text"
                      placeholder="Full Name"
                      value={firstName}
                      autoFocus={true}
                      onChange={(e) => handleChange(e, "firstName")}
                    />
                  </div>
                  <span className="error-msg" style={{ color: "red" }}>
                    {firstNameErr ? firstNameErrText : null}
                  </span>
                </div>
              </div>

              <div className="p-col-12 p-md-4 p-pt-2">
                <div className="p-w-100 ">
                  <div className="custom-inputs">
                    <label htmlFor="mobileNumber">
                      Mobile number <span style={{ color: "red" }}>*</span>:
                    </label>
                    <InputText
                      className="p-w-100 p-mt-2"
                      id="mobileNumber"
                      type="text"
                      placeholder="Mobile number"
                      value={mobileNumber}
                      autoFocus={true}
                      onChange={(e) => handleChange(e, "mobileNumber")}
                      maxLength={10}
                      keyfilter={"num"}
                    />
                  </div>
                  <span className="error-msg" style={{ color: "red" }}>
                    {mobileNumberErr ? mobileNumberErrText : null}
                  </span>
                </div>
              </div>
              <div className="p-col-12 p-md-4 p-pt-2">
                <div className="p-w-100 ">
                  <div className="custom-inputs">
                    <label htmlFor="email">
                      Email <span style={{ color: "red" }}>*</span>:
                    </label>
                    <InputText
                      className="p-w-100 p-mt-2"
                      id="email"
                      type="text"
                      placeholder="Email"
                      value={email}
                      autoFocus={true}
                      onChange={(e) => handleChange(e, "email")}
                      keyfilter={"email"}
                    />
                  </div>
                  <span className="error-msg" style={{ color: "red" }}>
                    {emailErr ? emailErrText : null}
                  </span>
                </div>
              </div>
              {!location?.state?.userID ? (
                <div className="p-col-12 p-md-4 p-pt-2">
                  <div className="p-w-100 ">
                    <div className="custom-inputs">
                      <label htmlFor="password">
                        {" "}
                        Password <span style={{ color: "red" }}>*</span>:
                      </label>
                      <div className="p-inputgroup flex-1">
                        <InputText
                          className="p-w-100 p-mt-2"
                          id="password"
                          type={passwordVisible ? "text" : "password"}
                          placeholder="Password"
                          value={password}
                          autoFocus={true}
                          onChange={(e) => handleChange(e, "password")}
                        />
                        <Button
                          className="password-toggle"
                          onClick={togglePasswordVisibility}
                        >
                          {passwordVisible ? (
                            <i className="icon-view-off tailer-icon"></i>
                          ) : (
                            <i className="icon-view tailer-icon"></i>
                          )}{" "}
                        </Button>
                      </div>
                    </div>
                    <span className="error-msg" style={{ color: "red" }}>
                      {passwordErr ? passwordErrText : null}
                    </span>
                  </div>
                </div>
              ) : null}

              <div className="p-col-12 p-md-4 p-pt-2">
                <div className="p-w-100 ">
                  <div className="custom-inputs">
                    <label htmlFor="plan">
                      Plan <span style={{ color: "red" }}>*</span>:{" "}
                    </label>
                    <Dropdown
                      className="p-w-100 p-mt-2"
                      optionLabel="label"
                      value={plan}
                      options={userPlanList}
                      onChange={(e) => handleChange(e, "plan")}
                      filter
                      placeholder="Plan"
                    />
                  </div>
                  <span className="error-msg" style={{ color: "red" }}>
                    {planErr ? planErrText : null}
                  </span>
                </div>
              </div>

              {location?.state?.userID ? (
                <div className="p-col-12 p-md-4 p-pt-2"></div>
              ) : null}
              <div className="card-footer">
                <Button
                  label="Submit"
                  severity="secondary"
                  raised
                  onClick={(e) => AddUpdateUser(e)}
                  disabled={loading}
                />
                <Button
                  label="Cancel"
                  severity="warning"
                  raised
                  onClick={() => resetScreen()}
                  disabled={loading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddUser;
