import React, { Component, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import api from "../../utils/apiClient";
import APIConstant from "../../utils/pathConstants";
import Toast from "../Shared/Toast/Toast";
import Loader from "../Shared/Loader/Loader";
import logo from "../../assets/svgs/portlink-logo.svg";
import { Link } from "react-router-dom";

const Login = (props) => {
  const [user, setUser] = useState({
    name: "",
    err: false,
    helperText: "",
  });
  const [password, setPassword] = useState({
    password: "",
    err: false,
    helperText: "",
    isSeen: false,
  });

  const handleCheck = (e, type) => {
    let value = e.target.value;
    if (type === "userName") {
      if (value === "" || value === null) {
        setUser({
          name: value,
          err: true,
          helperText: "Username is required",
        });
      } else {
        setUser({
          name: value,
          err: false,
          helperText: "",
        });
      }
    }

    if (type === "Password") {
      if (value === "" || value === null) {
        setPassword({
          password: value,
          err: true,
          helperText: "Password is required",
        });
      } else {
        setPassword({
          password: value,
          err: false,
          helperText: "",
        });
      }
    }
  };

  const validate = () => {
    let formIsValid = true;

    if (!user.name) {
      setUser((prevState) => ({
        ...prevState,
        err: true,
        helperText: "Username is required",
      }));
      formIsValid = false;
    } else {
      setUser((prevState) => ({
        ...prevState,
        err: false,
        helperText: "",
      }));
    }

    if (!password.password) {
      setPassword((prevState) => ({
        ...prevState,
        err: true,
        helperText: "Password is required",
      }));

      formIsValid = false;
    } else {
      setPassword((prevState) => ({
        ...prevState,
        err: false,
        helperText: "",
      }));
    }

    return formIsValid;
  };

  const handleLogin = (e) => {
    e.preventDefault();
    try {
      let data = {
        LoginID: user.name,
        Password: password.password,
        IsOTPLogin: false,
      };
      api
        .post(APIConstant.path.login, data)
        .then((res) => {
          // let res = response.Info.Login;
          if (res.success) {
            // localStorage.setItem('AccessToken', res.data?.accessToken);
            // localStorage.setItem('RefreshToken', res.data?.refreshToken);
            localStorage.setItem("loginData", JSON.stringify(res.data));
            localStorage.setItem("isAuthorized", res.Success);
            Loader.hide();
            Toast.success({ message: res.message });
            props.navigate("/dashboard");
          } else {
            Toast.error({ message: res.message });
            Loader.hide();
          }
        })
        .catch((err) => {
          Loader.hide();
        });
      Loader.show();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="auth-container">
      <div className="p-grid p-ai-center login-background p-h-100">
        <div className="p-col-12 p-md-7 p-h-100 p-p-0 p-d-none p-d-lg-block p-position-relative">
          <div className="login-bg-box">
            <div className="login-bg-text">
              <h1>The Future Of Maritime & Supply Chain</h1>
              <p>
                Sailing Towards an Automated and Efficient Future. Discover the
                Wave of Innovation in Maritime and Supply Chain.
              </p>
            </div>
          </div>
        </div>

        <div className="p-col-12 p-md-5">
          <div className="form-area p-col-12 p-md-9">
            <img
              src={logo}
              alt="logo"
              className="img-thumbnail rounded-circle mx-auto"
            />
            <p className="form-heading my-5">Sign in to your account</p>
            <form onSubmit={handleLogin}>
              <div className="p-w-100">
                <div className="custom-inputs">
                  <label htmlFor="emailAddress">Username</label>
                  <InputText
                    id="emailAddress"
                    placeholder="info@yourmail.com"
                    type="text"
                    className="p-w-100 p-mt-2"
                    value={user.name}
                    onChange={(e) => handleCheck(e, "userName")}
                  />
                </div>
                {user.err ? (
                  <small
                    id="username2-help"
                    className="p-error block p-d-block p-text-left p-pt-2"
                  >
                    {user.helperText}
                  </small>
                ) : null}
              </div>
              <div className="p-w-100 p-mt-4">
                <div className="custom-inputs password-field">
                  <label htmlFor="password">Password</label>
                  <InputText
                    id="password"
                    type={password.isSeen == true ? "text" : "password"}
                    placeholder="******"
                    className="p-w-100 p-mt-2"
                    value={password.password}
                    onChange={(e) => handleCheck(e, "Password")}
                  />
                  <i
                    className={
                      password.isSeen == true
                        ? "icon-view tailer-icon"
                        : "icon-view-off tailer-icon"
                    }
                    onClick={() =>
                      setPassword((prevState) => ({
                        ...prevState,
                        isSeen: !prevState.isSeen,
                      }))
                    }
                  ></i>
                </div>
                {password.err ? (
                  <small
                    id="username2-help"
                    className="p-error block p-d-block p-text-left p-pt-2"
                  >
                    {password.helperText}
                  </small>
                ) : null}
              </div>
              <div className="p-d-flex p-ai-center p-jc-between p-my-4">
                <Link to="/forgot-password" className="forgot-box">
                  Forgot Password?
                </Link>
              </div>
              <Button
                type="submit"
                className=" p-w-100 login-btn"
                label="Login"
                onClick={handleLogin}
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
