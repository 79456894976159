import React, { useCallback, useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";

const UserWise = (props) => {
  const [userWiseServiceList, setUserWiseServiceList] = useState([]);
  const {
    UserWiseServiceList,
    ActiveUserWiseAccess,
    HandleUserWiseParentMasterAccessChange,
    HandleUserWiseParentAccessChange,
    HandleUserWiseChildMasterAccessChange,
    HandleUserWiseChildAccessChange,
    UpdateAdminUserWiseServices,
    handleUserWiseChildActive,
  } = props;

  useEffect(() => {
    setUserWiseServiceList(UserWiseServiceList);
  }, [UserWiseServiceList]);

  return (
    <div className="pl-2">
      {userWiseServiceList.length != 0 ? (
        userWiseServiceList.map((x, idx) => {
          return (
            <div
              className="mb-3 border rounded-lg bg-gray-50 hover:bg-gray-100"
              key={idx}
            >
              <div className="relative cursor-pointer flex items-center p-2">
                <div className="absolute -left-3.5">
                  <div className="h-8 w-8 bg-white text-cyan-500 shadow border-cyan-200 border items-center justify-center flex rounded-full">
                    {x.SrNo}
                  </div>
                </div>
                <div
                  className="ml-7 flex items-center w-full justify-between"
                  onClick={() => ActiveUserWiseAccess(idx)}
                >
                  <div className="" id="Header-6">
                    {x.UserName}
                  </div>
                  <Button
                    label="Update Access"
                    severity="info"
                    className="!border-0 !bg-cyan-500 hover:!bg-cyan-600 h-9 !px-2 [&_.p-button-label]:text-sm"
                    onClick={() => UpdateAdminUserWiseServices(idx)}
                  />
                </div>
              </div>
              {x.IsActive == "1" ? (
                <div className=" border-t bg-white">
                  {x.ServiceList?.length &&
                    x?.ServiceList.map((serviceItem, indx) => {
                      return (
                        <div key={indx}>
                          <div className="" key={indx}>
                            <div className="border border-l-2 border-white grid grid-cols-4">
                              <div className="col-span-1 border border-l-0 border-t-0 p-3">
                                <div className="flex justify-between items-center">
                                  <div className="flex items-center space-x-2">
                                    <Checkbox
                                      className="[&_.p-checkbox-box]:hover:!border-cyan-400 [&_.p-highlight]:!border-cyan-400 [&_.p-highlight]:!bg-cyan-50 [&_svg]:!text-cyan-500"
                                      inputId={`${
                                        serviceItem.name + serviceItem._id
                                      }`}
                                      name={`${serviceItem.name}`}
                                      value={serviceItem.isChecked}
                                      checked={
                                        serviceItem.isChecked == 1
                                          ? true
                                          : false
                                      }
                                      onChange={(e) =>
                                        HandleUserWiseParentMasterAccessChange(
                                          e,
                                          idx,
                                          indx
                                        )
                                      }
                                    />
                                    <label
                                      htmlFor={`${
                                        serviceItem.name + serviceItem._id
                                      }`}
                                      className="ml-2"
                                    ></label>
                                    {serviceItem.name}
                                  </div>
                                  {serviceItem.subServices?.length ? (
                                    <Button
                                      icon={
                                        serviceItem.IsChildActive == 1
                                          ? "pi pi-chevron-down"
                                          : "pi pi-chevron-right"
                                      }
                                      className="hover:!bg-cyan-50 !text-cyan-500 !h-6 !w-6 [&_.p-button-icon]:!text-sm"
                                      rounded
                                      text
                                      aria-label="ChevronRight"
                                      onClick={(e) =>
                                        handleUserWiseChildActive(e, idx, indx)
                                      }
                                    />
                                  ) : null}
                                </div>
                              </div>
                              <div className="col-span-3 border border-l-0 border-t-0 p-3">
                                <div className="flex flex-wrap gap-4">
                                  {serviceItem.ServiceAccess?.map(
                                    (serviceAccessMasterItem, indxItem) => {
                                      return (
                                        <div
                                          className="flex items-center space-x-2"
                                          key={indxItem}
                                        >
                                          <Checkbox
                                            className="[&_.p-checkbox-box]:hover:!border-cyan-400 [&_.p-highlight]:!border-cyan-400 [&_.p-highlight]:!bg-cyan-50 [&_svg]:!text-cyan-500"
                                            inputId={`${
                                              serviceAccessMasterItem.name +
                                              serviceAccessMasterItem._id
                                            }`}
                                            name={`${serviceAccessMasterItem.name}`}
                                            value={
                                              serviceAccessMasterItem.isChecked
                                            }
                                            checked={
                                              serviceAccessMasterItem.isChecked
                                            }
                                            onChange={(e) =>
                                              HandleUserWiseParentAccessChange(
                                                e,
                                                idx,
                                                indx,
                                                indxItem
                                              )
                                            }
                                          />
                                          <label
                                            htmlFor={`${
                                              serviceAccessMasterItem.name +
                                              serviceAccessMasterItem._id
                                            }`}
                                            className="ml-2"
                                          ></label>
                                          {serviceAccessMasterItem.name}
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              </div>
                            </div>
                            {serviceItem.IsChildActive == 1 && (
                              <div className="border border-l-2 border-t-0 border-b-0 border-r-0 border-l-cyan-500 bg-slate-50">
                                {serviceItem.subServices?.map(
                                  (serviceItemChild, indxItemChild) => {
                                    return (
                                      <div
                                        className="grid grid-cols-4"
                                        key={indxItemChild}
                                      >
                                        <div className="col-span-1 border border-l-0 border-t-0 border-b-0 p-3">
                                          <div className="flex items-center space-x-2">
                                            <Checkbox
                                              className="[&_.p-checkbox-box]:hover:!border-cyan-400 [&_.p-highlight]:!border-cyan-400 [&_.p-highlight]:!bg-cyan-50 [&_svg]:!text-cyan-500"
                                              inputId={`${
                                                serviceItemChild.name +
                                                serviceItemChild._id
                                              }`}
                                              name={`${serviceItemChild.name}`}
                                              value={serviceItemChild.isChecked}
                                              checked={
                                                serviceItemChild.isChecked
                                              }
                                              onChange={(e) =>
                                                HandleUserWiseChildMasterAccessChange(
                                                  e,
                                                  idx,
                                                  indx,
                                                  indxItemChild
                                                )
                                              }
                                            />
                                            <label
                                              htmlFor={`${
                                                serviceItemChild.name +
                                                serviceItemChild._id
                                              }`}
                                              className="ml-2"
                                            ></label>
                                            {serviceItemChild.name}
                                          </div>
                                        </div>
                                        <div className="col-span-3 p-3">
                                          <div className="flex flex-wrap gap-4">
                                            {serviceItemChild.ServiceAccess?.map(
                                              (
                                                serviceAccessMasterItemChild,
                                                indxMasterItemChild
                                              ) => {
                                                return (
                                                  <div
                                                    className="flex items-center space-x-2"
                                                    key={indxMasterItemChild}
                                                  >
                                                    <Checkbox
                                                      className="[&_.p-checkbox-box]:hover:!border-cyan-400 [&_.p-highlight]:!border-cyan-400 [&_.p-highlight]:!bg-cyan-50 [&_svg]:!text-cyan-500"
                                                      inputId={`${
                                                        serviceAccessMasterItemChild.name +
                                                        serviceAccessMasterItemChild._id
                                                      }`}
                                                      name={`${serviceAccessMasterItemChild.name}`}
                                                      value={
                                                        serviceAccessMasterItemChild.isChecked
                                                      }
                                                      checked={
                                                        serviceAccessMasterItemChild.isChecked
                                                      }
                                                      onChange={(e) =>
                                                        HandleUserWiseChildAccessChange(
                                                          e,
                                                          idx,

                                                          indx,
                                                          indxItemChild,
                                                          indxMasterItemChild
                                                        )
                                                      }
                                                    />
                                                    <label
                                                      htmlFor={`${
                                                        serviceAccessMasterItemChild.name +
                                                        serviceAccessMasterItemChild._id
                                                      }`}
                                                      className="ml-2"
                                                    ></label>
                                                    {
                                                      serviceAccessMasterItemChild.name
                                                    }
                                                  </div>
                                                );
                                              }
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                </div>
              ) : null}
            </div>
          );
        })
      ) : (
        <div>No Records Available</div>
      )}
    </div>
  );
};

export default UserWise;
