import React, { useEffect, useState } from "react";
import CommonConfig from "../../utils/constant";
import Toast from "../Shared/Toast/Toast";
import Loader from "../Shared/Loader/Loader";
import APIConstant from "../../utils/pathConstants";
import api from "../../utils/apiClient";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";

const SubService = (props) => {
  const [securityUserID, setSecurityUserID] = useState(null);
  const [serviceID, setServiceID] = useState(props?.location?.state?.ServiceId);
  const [subServiceID, setSubServiceID] = useState("");

  const [serviceName, setServiceName] = useState("");
  const [serviceNameErr, setServiceNameErr] = useState(false);
  const [serviceNameErrText, setServiceNameErrText] = useState("");

  const [serviceDescription, setServiceDescription] = useState("");
  const [serviceDescriptionErr, setServiceDescriptionErr] = useState(false);
  const [serviceDescriptionErrText, setServiceDescriptionErrText] =
    useState("");

  const [servicePrice, setServicePrice] = useState("");
  const [servicePriceErr, setServicePriceErr] = useState(false);
  const [servicePriceErrText, setServicePriceErrText] = useState("");

  const [globalFilter, setGlobalFilter] = useState("");
  const [isViewService, setIsViewService] = useState(false);
  const [isDeleteSubService, setIsDeleteSubService] = useState(false);
  const [viewServiceData, setViewSubServiceData] = useState({});
  const [addSubService, setAddSubService] = useState(false);

  const [servicesList, setServicesList] = useState([]);

  useEffect(() => {
    const loginData = CommonConfig.loginData();
    setSecurityUserID(loginData?.SecurityUserID);
    GetSubServices(serviceID);

    // console.log(props?.location?.state?.serviceID)
  }, []);

  const resetScreen = async () => {
    setSecurityUserID(null);
    setAddSubService(false);
    setIsDeleteSubService(false);

    setServiceName("");
    setServiceNameErr(false);
    setServiceNameErrText("");

    setServiceDescription("");
    setServiceDescriptionErr(false);
    setServiceDescriptionErrText("");

    setServicePrice("");
    setServicePriceErr(false);
    setServicePriceErrText("");
    // props.navigate("/lead");
  };

  const handleChange = (e, type) => {
    if (type === "serviceName") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setServiceName("");
        setServiceNameErr(true);
        setServiceNameErrText("Service name is required");
      } else {
        setServiceName(e.target.value);
        setServiceNameErr(false);
        setServiceNameErrText("");
      }
    } else if (type === "serviceDescription") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setServiceDescription("");
        setServiceDescriptionErr(true);
        setServiceDescriptionErrText("Service description is required");
      } else {
        setServiceDescription(e.target.value);
        setServiceDescriptionErr(false);
        setServiceDescriptionErrText("");
      }
    } else if (type === "servicePrice") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setServicePrice("");
        setServicePriceErr(true);
        setServicePriceErrText("Service price is required");
      } else {
        setServicePrice(e.target.value);
        setServicePriceErr(false);
        setServicePriceErrText("");
      }
    }
  };

  const AddUpdateSubService = async (e) => {
    if (validate(e)) {
      try {
        let data = {
          ServiceId: serviceID,
          SubServiceId: subServiceID,
          ServiceName: serviceName,
          ServiceDesc: serviceDescription,
          ServicePrice: servicePrice,
        };

        Loader.show();
        await api
          .post(APIConstant.path.AddUpdateAdminSubService, data)
          .then((response) => {
            let res = response;

            if (res.success) {
              Toast.success({ message: res.message });
              resetScreen();
              GetSubServices(serviceID);
              Loader.hide();
            } else {
              Toast.error({ message: res.message });
              Loader.hide();
            }
          })
          .catch((err) => {});
      } catch (err) {
        console.log(err);
      }
    }
  };

  const GetSubServices = async (ID) => {
    try {
      let data = {
        ServiceId: ID,
      };
      await api
        .post(APIConstant.path.GetAdminSubServices, data)
        .then(async (response) => {
          let res = response;
          if (res.success) {
            setServicesList(res.data);
            Loader.hide();
          }
        })
        .catch((err) => {
          Loader.hide();
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const DeleteAdminSubService = async () => {
    try {
      Loader.show();
      let data = {
        SubServiceId: subServiceID,
        ServiceId: serviceID,
      };
      await api
        .post(APIConstant.path.DeleteAdminSubService, data)
        .then((response) => {
          if (response.success) {
            handleClose("DeleteSubService");
            GetSubServices(serviceID);
            Loader.hide();
          } else {
            Loader.hide();
          }
        })
        .catch((err) => {
          Loader.hide();
          Toast.error({ message: err });
        });
    } catch (err) {
      Loader.hide();
      Toast.error({ message: err });
    }
  };

  const validate = () => {
    let formIsValid = true;
    if (CommonConfig.isEmpty(serviceName)) {
      formIsValid = false;
      setServiceNameErr(true);
      setServiceNameErrText("Service name is required");
    } else {
      setServiceNameErr(false);
      setServiceNameErrText("");
    }

    if (CommonConfig.isEmpty(serviceDescription)) {
      formIsValid = false;
      setServiceDescriptionErr(true);
      setServiceDescriptionErrText("Service description is required");
    } else {
      setServiceDescriptionErr(false);
      setServiceDescriptionErrText("");
    }

    if (CommonConfig.isEmpty(servicePrice)) {
      formIsValid = false;
      setServicePriceErr(true);
      setServicePriceErrText("Service price is required");
    } else {
      setServicePriceErr(false);
      setServicePriceErrText("");
    }
    return formIsValid;
  };

  const handleClose = async (type) => {
    if (type === "AddSubService") {
      setAddSubService(false);
    } else if (type === "EditSubService") {
    } else if (type === "DeleteSubService") {
      setSubServiceID(null);
      setIsDeleteSubService(false);
    } else if (type === "ViewSubService") {
      setIsViewService(false);
      setViewSubServiceData({});
    }
  };

  const handleOpen = async (item, type) => {
    if (type === "AddSubService") {
      setAddSubService(true);
    } else if (type === "EditSubService") {
      setAddSubService(true);
      setSubServiceID(item._id);
      setServiceName(item.ServiceName);
      setServicePrice(item.ServicePrice);
      setServiceDescription(item.ServiceDesc);
    } else if (type === "DeleteSubService") {
      setSubServiceID(item._id);
      setIsDeleteSubService(true);
    } else if (type === "ViewSubService") {
      setIsViewService(true);
      setViewSubServiceData(item);
    } else if (type === "SubAccessMaster") {
      props.navigate("/subAccessMaster", {
        state: { ServiceId: serviceID, SubServiceId: item._id },
      });
    }
  };

  const actionBodyTemplateSrNo = (data, props) => {
    return props.rowIndex + 1;
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="table-icon">
        <Button
          className=""
          onClick={() => handleOpen(rowData, "ViewSubService")}
          tooltip={"View"}
          tooltipOptions={{ className: "bluegray-tooltip", position: "top" }}
        >
          <i className="icon-view"></i>
        </Button>

        <Button
          className=""
          onClick={() => handleOpen(rowData, "EditSubService")}
          tooltip={"Edit"}
          tooltipOptions={{ className: "bluegray-tooltip", position: "top" }}
        >
          <i className="icon-edit"></i>
        </Button>

        <Button
          className=""
          onClick={() => handleOpen(rowData, "DeleteSubService")}
          tooltip={"Delete"}
          tooltipOptions={{ className: "bluegray-tooltip", position: "top" }}
        >
          <i className="icon-delete"></i>
        </Button>
        <Button
          type="button"
          tooltip={"SubAccessMaster"}
          tooltipOptions={{ className: "bluegray-tooltip", position: "top" }}
          onClick={(e) => handleOpen(rowData, "SubAccessMaster")}
        >
          <i className="pi pi-database"></i>
        </Button>
      </div>
    );
  };

  return (
    <div className="user-container p-d-flex p-flex-column p-jc-between">
      <div>
        <div className="user-container">
          <div className="card-header">
            <div className="card-title p-d-lg-flex p-ai-center p-w-100">
              <div className="card-actions p-ml-2">
                <div className="heading-area">
                  <i
                    className="icon-chevron-down back-btn"
                    onClick={() => props.navigate("/service")}
                  ></i>
                </div>
              </div>
              <h3 className="p-mb-3 p-mb-lg-0">View Sub Service</h3>
              <div className="p-ml-auto p-d-lg-flex p-ai-center">
                <span className="searchbar-area">
                  <InputText
                    type="search"
                    onChange={(e) => setGlobalFilter(e.target.value)}
                    placeholder="Global Search"
                    size="30"
                  />
                  <i className="icon-search"></i>
                </span>
              </div>
              <div className="card-actions p-ml-2">
                <Button
                  type="button"
                  className="add-btn add-btn p-button p-component p-jc-center p-w-100"
                  onClick={(e) => handleOpen(e, "AddSubService")}
                >
                  <i className="icon-add"></i> Add Service
                </Button>
              </div>
            </div>
          </div>
          <div className="card">
            <DataTable
              value={servicesList}
              paginator
              rows={5}
              rowsPerPageOptions={[5, 10, 25, 50]}
            >
              <Column
                field="Index"
                header="Sr.No"
                body={actionBodyTemplateSrNo}
                style={{ maxWidth: 150 }}
              ></Column>
              <Column
                field="ServiceName"
                header="Service Name"
                style={{ width: "25%" }}
              ></Column>
              <Column
                field="ServicePrice"
                header="Service Price"
                style={{ width: "25%" }}
              ></Column>
              <Column header="Action" body={actionBodyTemplate}></Column>
            </DataTable>
          </div>
        </div>

        {/* Add access master */}
        <Dialog
          header={serviceID ? "Edit Sub Service" : "Add Sub Service"}
          visible={addSubService}
          className="dialog-popup"
          onHide={handleClose}
          // onHide={(e) => handleClose('AddSubService')}
          draggable={false}
          closable={false}
          position="top"
        >
          <div>
            <div className="card-header">
              <div className="card-title p-d-lg-flex p-ai-center p-w-100">
                <h3 className="p-mb-3 p-mb-lg-0">
                  {serviceID ? "Edit" : "Add"} Sub Service
                </h3>
              </div>
            </div>
            <div className="card-body">
              <div className="form-area">
                <div className={"p-grid"}>
                  <div className="p-col-12 p-md-4 p-pt-2">
                    <div className="p-w-100 ">
                      <div className="custom-inputs">
                        <label htmlFor="serviceName">
                          Service name <span style={{ color: "red" }}>*</span>:
                        </label>
                        <InputText
                          className="p-w-100 p-mt-2"
                          id="serviceName"
                          type="text"
                          placeholder="Service name"
                          value={serviceName}
                          autoFocus={true}
                          onChange={(e) => handleChange(e, "serviceName")}
                          maxLength={30}
                        />
                      </div>
                      <span className="error-msg" style={{ color: "red" }}>
                        {serviceNameErr ? serviceNameErrText : null}
                      </span>
                    </div>
                  </div>

                  <div className="p-col-12 p-md-4 p-pt-2">
                    <div className="p-w-100 ">
                      <div className="custom-inputs">
                        <label htmlFor="serviceDescription">
                          Service description{" "}
                          <span style={{ color: "red" }}>*</span>:
                        </label>
                        <InputTextarea
                          className="p-w-100 p-mt-2"
                          id="serviceDescription"
                          value={serviceDescription}
                          placeholder="Service description "
                          onChange={(e) =>
                            handleChange(e, "serviceDescription")
                          }
                          rows={1}
                          cols={30}
                        />
                      </div>
                      <span className="error-msg" style={{ color: "red" }}>
                        {serviceDescriptionErr
                          ? serviceDescriptionErrText
                          : null}
                      </span>
                    </div>
                  </div>

                  <div className="p-col-12 p-md-4 p-pt-2">
                    <div className="p-w-100 ">
                      <div className="custom-inputs">
                        <label htmlFor="servicePrice">
                          Service price <span style={{ color: "red" }}>*</span>:{" "}
                        </label>
                        <InputText
                          className="p-w-100 p-mt-2"
                          id="servicePrice"
                          type="text"
                          placeholder="Service price"
                          value={servicePrice}
                          autoFocus={true}
                          onChange={(e) => handleChange(e, "servicePrice")}
                          maxLength={30}
                        />
                      </div>
                      <span className="error-msg" style={{ color: "red" }}>
                        {servicePriceErr ? servicePriceErrText : null}
                      </span>
                    </div>
                  </div>
                  <div className="card-footer">
                    <Button
                      label="Submit"
                      severity="secondary"
                      raised
                      onClick={(e) => AddUpdateSubService(e)}
                    />
                    <Button
                      label="Cancel"
                      severity="warning"
                      raised
                      onClick={() => handleClose("AddSubService")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Dialog>

        {/* Delete Confirm Modal */}
        <Dialog
          header="Delete Service"
          visible={isDeleteSubService}
          className="dialog-popup"
          onHide={(e) => handleClose("DeleteService")}
          draggable={false}
          closable={false}
          position="top"
        >
          <span>Are you sure want to delete the Service record ?</span>
          <div className="dialog-footer">
            <button
              className="btn-dialog-cancel"
              onClick={(e) => handleClose("DeleteSubService")}
            >
              cancel
            </button>
            <button
              className="btn-dialog-delete"
              onClick={() => DeleteAdminSubService()}
            >
              Delete
            </button>
          </div>
        </Dialog>

        <Dialog
          header="View Sub Service"
          visible={isViewService}
          className="dialog-popup"
          onHide={(e) => handleClose("AddService")}
          draggable={false}
          closable={false}
          position="center"
        >
          <div className="modalBody">
            <div className={"p-grid"}>
              <div className="p-col-12 p-md-12 p-pl-0 p-py-0">
                <div className={"p-grid"}>
                  <div className="p-col-12 p-md-6 p-pt-2">
                    <div className="p-w-100 ">
                      <span>
                        <b>Service Name : </b>
                      </span>
                      <p>
                        {" "}
                        {!CommonConfig.isObjectEmpty(viewServiceData) &&
                        !CommonConfig.isEmpty(viewServiceData?.ServiceName)
                          ? viewServiceData?.ServiceName
                          : "-"}
                      </p>
                    </div>
                  </div>
                  <div className="p-col-12 p-md-6 p-pt-2">
                    <div className="p-w-100 ">
                      <span>
                        <b>Service description : </b>
                      </span>
                      <p>
                        {" "}
                        {!CommonConfig.isObjectEmpty(viewServiceData) &&
                        !CommonConfig.isEmpty(viewServiceData?.ServiceDesc)
                          ? viewServiceData?.ServiceDesc
                          : "-"}
                      </p>
                    </div>
                  </div>
                  <div className="p-col-12 p-md-6 p-pt-2">
                    <div className="p-w-100 ">
                      <span>
                        <b>Service Price : </b>
                      </span>
                      <p>
                        {" "}
                        {!CommonConfig.isObjectEmpty(viewServiceData) &&
                        !CommonConfig.isEmpty(viewServiceData?.ServicePrice)
                          ? viewServiceData?.ServicePrice
                          : "-"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="dialog-footer">
            <button
              className="btn-dialog-cancel"
              onClick={(e) => handleClose("ViewSubService")}
            >
              cancel
            </button>
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default SubService;
