import React, { Component } from "react";
import CommonConfig from "../../utils/constant";
import Toast from "../Shared/Toast/Toast";
import userImage from "../../assets/images/UserImage.png";
import { default as routes } from "../../routes";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import api from "../../utils/apiClient";
import APIConstant from "../../utils/pathConstants";
import Loader from "../Shared/Loader/Loader";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { OverlayPanel } from "primereact/overlaypanel";
// import { Toast } from 'primereact/toast';

const initialState = {
  mainTitle: "",
  breadCrumb: "",
  Name: "",
  Email: "",
  UserType: "",
  LastLogin: "",
  changePassword: false,
  selectProject: false,

  oldPassword: "",
  oldPasswordErr: "",
  oldPasswordErrText: "",

  newPassword: "",
  newPasswordErr: "",
  newPasswordErrText: "",
  project: localStorage.getItem("ProjectID"),
  confirmPassword: "",
  confirmPasswordErr: "",
  confirmPasswordErrText: "",
  projectList: [],
  profilePath: "",
  loaded: false,
  notificationList: [],
  permissionList: [],
  notificationType: "",
  UserID: "",
  openNotification: null,
  openRequestNotification: null,

  notificationCount: 0,
};

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;

    this.openGeneralInfo = React.createRef();
  }

  getPermissionList = async (ID) => {
    try {
      let data = {
        UserId: ID,
      };
      await api
        .post(APIConstant.path.GetNotificationPermission, data)
        .then(async (response) => {
          let res = await response;
          if (res.success) {
            Loader.hide();
            let all = [
              {
                name: "All",
                code: "All",
              },
            ];
            let originalArray = res?.data;
            let data = [...all, ...originalArray];
            this.setState({ permissionList: data });
          } else {
            Loader.hide();
            this.setState({ permissionList: [] });
          }
        })
        .catch((err) => {
          Loader.hide();
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  getNotificationList = async (ID, Type) => {
    try {
      let data = {
        UserId: ID,
        Type: Type?.name ? Type?.name : "ALL",
      };
      await api
        .post(APIConstant.path.GetNotificationList, data)
        .then(async (response) => {
          let res = await response;
          if (res.success) {
            Loader.hide();

            this.setState({
              notificationList: res?.data,
              notificationCount: res?.data?.length,
            });
          } else {
            Loader.hide();
            this.setState({ notificationList: [], notificationCount: 0 });
          }
        })
        .catch((err) => {
          Loader.hide();
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  handleClickNotification = (event, type) => {
    if (type === "notification") {
      if (
        this.state.openNotification &&
        this.state.openNotification.contains(event.target)
      ) {
        this.setState({ openNotification: null });
      } else {
        this.setState({ openNotification: event.currentTarget });
      }
    } else {
      if (
        this.state.openRequestNotification &&
        this.state.openRequestNotification.contains(event.target)
      ) {
        this.setState({ openRequestNotification: null });
      } else {
        this.setState({ openRequestNotification: event.currentTarget });
      }
    }
  };

  handleCloseNotification = (event, type) => {
    if (type === "notification") {
      this.setState({
        openNotification: null,
        notificationList: [],
        notificationType: "",
        notificationCount: 0,
      });
    } else {
      this.setState({ openRequestNotification: null });
    }
  };

  getNotificationCountDetail = async (type) => {
    try {
      const params = {
        UserId: localStorage.getItem("Infodata"),
        Type: type && type.code ? type.code : "Purchase",
        token: localStorage.getItem("Token"),
      };

      const response = await api.post(
        "/ajax/player.php?action=getNotificationDetail",
        params
      );

      if (response.Success === 1) {
        const notificationDetail =
          response.Info.NotificationDetail !== true
            ? response.Info.NotificationDetail
            : [];
        const count = notificationDetail.filter(
          (obj) => obj.IsNew === "1"
        ).length;

        this.setState({
          notificationDetailArray: notificationDetail,
          notificationCount: count,
        });
      } else if (response.Success === -1) {
        // Handle your logout logic here
        localStorage.clear();
        const { history } = this.props;
        history.push("/login");
      }
    } catch (err) {
      console.error(err);
    }
  };

  toggleOverlay = (e) => {
    this.openGeneralInfo.current.toggle(e);
  };

  handleLogout = () => {
    this.props.navigate("/login");
    localStorage.clear();
    Toast.success({ message: "Logged out success" });
  };

  manageSidebarWidth = () => {
    document.getElementById("sidebar-area").classList.toggle("sidebar-mini");
    document
      .getElementById("dashboard-area")
      .classList.toggle("large-dashboard-area");
  };

  componentDidMount = () => {
    // this.getNotificationList(CommonConfig.loginData().UserId);
    // this.getPermissionList(CommonConfig.loginData().UserId);
    this.setState({
      UserID: CommonConfig.loginData().UserId,
      Email: CommonConfig.loginData().Email,
      Name: CommonConfig.loginData().Name,
      UserType: CommonConfig.loginData().UserType,
      LastLogin: CommonConfig.loginData().LastLogin,
    });
    let userModuleAccess =
      CommonConfig.getUserAccess() && CommonConfig.getUserAccess().length
        ? CommonConfig.getUserAccess()
        : [];
    if (userModuleAccess.length) {
      let routeDetails = userModuleAccess?.filter(
        (x) =>
          x.Path == this.props.location?.pathname ||
          (x.SubMenus?.filter((child) =>
            child.Path.includes(this.props.location.pathname)
          ).length &&
            x.SubMenus?.filter((child) =>
              child.Path.includes(this.props.location.pathname)
            )[0].Path == this.props.location.pathname)
      )[0];
      if (routeDetails) {
        if (routeDetails.Path) {
          this.setState({
            breadCrumb: routeDetails.MenuName,
            mainTitle: routeDetails.MenuName,
          });
        } else if (routeDetails.SubMenus.length) {
          let childRouteDetails =
            routeDetails.SubMenus.filter((child) =>
              child.Path.includes(this.props.location.pathname)
            ).length &&
            routeDetails.SubMenus.filter(
              (child) => child.Path == this.props.location.pathname
            )[0];
          this.setState({
            breadCrumb:
              routeDetails.MenuName + " / " + childRouteDetails.MenuName,
            mainTitle: childRouteDetails.MenuName,
          });
        }
      } else {
        if (this.props.location) {
          if (
            routes.length &&
            routes.filter((x) => x.path == this.props.location.pathname).length
          ) {
            let breadCrumbDetails =
              // (routes.filter(x=>x.path == this.props.location.pathname)[0].parentName ? routes.filter(x=>x.path == this.props.location.pathname)[0].parentName+ " / " : '') +
              routes.filter((x) => x.path == this.props.location.pathname)[0]
                .breadCrumb;
            let mainTitleDetails = routes.filter(
              (x) => x.path == this.props.location.pathname
            )[0].meta.title;
            this.setState({
              breadCrumb: breadCrumbDetails,
              mainTitle: mainTitleDetails,
            });
          }
        }
      }
    }
    // this.setState({ sidebarMenus: userModuleAccess })
  };

  handleCheck = (e, type) => {
    if (type == "project") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          project: e.target.value,
          projectErr: true,
          projectErrText: "Project is required",
        });
      } else {
        this.setState({
          project: e.target.value,
          projectErr: false,
          projectErrText: "",
        });
      }
    }
    if (type == "oldPassword") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          oldPassword: e.target.value,
          oldPasswordErr: true,
          oldPasswordErrText: "Old password is required",
        });
      } else {
        this.setState({
          oldPassword: e.target.value,
          oldPasswordErr: false,
          oldPasswordErrText: "",
        });
      }
    }

    if (type == "newPassword") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          newPassword: e.target.value,
          newPasswordErr: true,
          newPasswordErrText: "New password is required",
        });
      } else {
        this.setState({
          newPassword: e.target.value,
          newPasswordErr: false,
          newPasswordErrText: "",
        });
      }
    }

    if (type == "confirmPassword") {
      if (CommonConfig.isEmpty(e.target.value)) {
        this.setState({
          confirmPassword: e.target.value,
          confirmPasswordErr: true,
          confirmPasswordErrText: "Confirm password is required",
        });
      } else if (this.state.newPassword !== e.target.value) {
        this.setState({
          confirmPassword: e.target.value,
          confirmPasswordErr: true,
          confirmPasswordErrText: "  Confirm password does not match ",
        });
      } else {
        this.setState({
          confirmPassword: e.target.value,
          confirmPasswordErr: false,
          confirmPasswordErrText: "",
        });
      }
    }
  };
  validate = (e) => {
    let isValid = true;
    // if (this.state.oldPassword === '' || this.state.oldPassword === null) {
    //     this.setState({ oldPasswordErr: true, oldPasswordErrText: 'Please enter old password' });
    //     isValid = false;
    // }
    // else {
    //     this.setState({ oldPasswordErr: false, oldPasswordErrText: '' });
    // }

    if (CommonConfig.isEmpty(this.state.newPassword)) {
      this.setState({
        newPasswordErr: true,
        newPasswordErrText: "Please enter new password",
      });
      isValid = false;
    } else {
      this.setState({ newPasswordErr: false, newPasswordErrText: "" });
    }
    if (CommonConfig.isEmpty(this.state.confirmPassword)) {
      this.setState({
        confirmPasswordErr: true,
        confirmPasswordErrText: "Please enter new password",
      });
      isValid = false;
    } else {
      this.setState({ confirmPasswordErr: false, confirmPasswordErrText: "" });
    }
    if (this.state.newPassword !== this.state.confirmPassword) {
      this.setState({
        confirmPasswordErr: true,
        confirmPasswordErrText: "Confirm password does not match",
      });
      isValid = false;
    } else {
      this.setState({ confirmPasswordErr: false, confirmPasswordErrText: "" });
    }
    return isValid;
  };

  changePassword = (e) => {
    e.preventDefault();
    if (this.validate()) {
      try {
        let data = {
          UserId: CommonConfig.loginData().UserId,
          NewPassword: this.state.newPassword,
        };
        api.post(APIConstant.path.ChangePassword, data).then((response) => {
          if (response.success) {
            this.setState(initialState);
            this.props.navigate("/dashboard");
            Loader.hide();
            Toast.success({ message: response.message });
          } else {
            Toast.error({ message: response.message });
            Loader.hide();
          }
        });
      } catch (err) {}
    }
  };

  onError = () => {
    this.setState({
      profilePath: undefined,
    });
  };
  handleClick = (item, idx, type) => {
    if (type == "Location Notification")
      this.props.navigate("/location-request", {
        state: { ID: item?.EntityId },
      });
    if (type == "Truck Request")
      this.props.navigate("/truck-request", { state: { ID: item?.EntityId } });
    if (type == "Driver Request")
      this.props.navigate("/driver-request", { state: { ID: item?.EntityId } });
    if (type == "Toll Plaza")
      this.props.navigate("/toll-Plaza", { state: { ID: item?.EntityId } });
  };

  render() {
    const {
      Name,
      Email,
      LastLogin,
      UserType,
      changePassword,
      selectProject,
      oldPassword,
      newPassword,
      oldPasswordErr,
      oldPasswordErrText,
      newPasswordErr,
      newPasswordErrText,
      confirmPassword,
      confirmPasswordErr,
      confirmPasswordErrText,
      projectList,
      project,
      profilePath,
      notificationCount,
      permissionList,
      notificationType,
      notificationList,
    } = this.state;
    // console.log("this.state.project !== null ? this.state.project: response.data[0].value ", project)

    return (
      <div className="dashboard-header">
        <div className="p-d-lg-flex p-jc-between">
          <div className="p-w-100">
            <div className="p-d-flex header-sc-col">
              <div className="p-input-icon-left p-ml-6"></div>

              <div className="header-right-action p-ml-auto">
                <OverlayPanel
                  ref={(el) => (this.op = el)}
                  id="overlay_panel"
                  style={{ width: "450px" }}
                  className="profile-menu"
                >
                  <div className="profile-menu-container">
                    <ul className="menu-options">
                      <li
                        className="menu-link"
                        onClick={() =>
                          this.props.navigate({ pathname: "/edit-profile" })
                        }
                      >
                        <i className="icon-edit-profile"></i>
                        <span>Edit Profile</span>
                        <label className="list-dot"></label>
                      </li>
                      <li
                        className="menu-link"
                        onClick={() => {
                          this.setState({ changePassword: true });
                        }}
                      >
                        <i className="icon-change-password"></i>
                        <span>Change Password</span>
                        <label className="list-dot"></label>
                      </li>
                      <li
                        onClick={() => this.handleLogout()}
                        className="menu-link"
                      >
                        <i className="icon-logout"></i>
                        <span>Logout</span>
                        <label className="list-dot"></label>
                      </li>
                    </ul>
                  </div>
                </OverlayPanel>

                <div className="header-bar">
                  <div className="right-nav-buttons p-d-flex">
                    <div>
                      <OverlayPanel
                        ref={(el) => (this.overlayPanel = el)}
                        showCloseIcon={false}
                        dismissable
                        onHide={(e) =>
                          this.handleCloseNotification(e, "notification")
                        }
                        className="notification-general-admin"
                      >
                        <div className="p-d-flex p-ai-center noti-drop-container">
                          <div className="notification-number">
                            {notificationCount}
                          </div>
                          <div className="noti-input">
                            <Dropdown
                              className="p-w-100"
                              value={notificationType}
                              options={permissionList}
                              onChange={(e) => {
                                this.setState({
                                  notificationType: e?.target.value,
                                });
                                this.getNotificationList(
                                  this.state.UserID,
                                  e?.target?.value
                                );
                              }}
                              optionLabel="name"
                              placeholder="Select notification type"
                            />
                          </div>

                          <div
                            className="notification-info"
                            onClick={this.toggleOverlay}
                          >
                            <i className="icon-alert-info"></i>
                          </div>
                          <OverlayPanel
                            ref={this.openGeneralInfo}
                            dismissable
                            className="notification-general-admin"
                          >
                            <div className="noti-info-area">
                              <div className="noti-info-area">
                                <div className="p-d-flex p-ai-center p-py-1">
                                  <div className="noti-info-dot bg-orange p-mr-2"></div>
                                  <div>Plan</div>
                                </div>
                                <div className="p-d-flex p-ai-center p-py-1">
                                  <div className="noti-info-dot bg-green p-mr-2"></div>
                                  <div>API management</div>
                                </div>
                                <div className="p-d-flex p-ai-center p-py-1">
                                  <div className="noti-info-dot bg-red p-mr-2"></div>
                                  <div>Location</div>
                                </div>
                                <div className="p-d-flex p-ai-center p-py-1">
                                  <div className="noti-info-dot bg-blue p-mr-2"></div>
                                  <div>Vessel</div>
                                </div>
                                <div className="p-d-flex p-ai-center p-py-1">
                                  <div className="noti-info-dot bg-purple p-mr-2"></div>
                                  <div>Truck</div>
                                </div>
                                <div className="p-d-flex p-ai-center p-py-1">
                                  <div className="noti-info-dot bg-brown p-mr-2"></div>
                                  <div>Transporter</div>
                                </div>
                              </div>
                            </div>
                          </OverlayPanel>
                        </div>
                        <ul className="notification-area">
                          {notificationList?.length ? (
                            notificationList?.map((res, idx) => {
                              return (
                                <li
                                  className="notification-details"
                                  key={idx}
                                  style={{
                                    backgroundColor:
                                      res.IsNew === "1" ? "#F7F8Fa" : "#fff",
                                  }}
                                  onClick={(e) =>
                                    this.handleClick(
                                      res,
                                      idx,
                                      res.NotificationType
                                    )
                                  }
                                >
                                  <div className="p-d-flex p-ai-center">
                                    <div className="notification-each-icon">
                                      <i className={res.Icon}></i>
                                    </div>

                                    <div className="notification-each-text">
                                      {res.NotificationDescription}
                                    </div>
                                  </div>

                                  <div className="notification-each-date">
                                    {res.NotificationDate}
                                  </div>
                                </li>
                              );
                            })
                          ) : (
                            <div className="p-py-4 dark-blue-color">
                              No new notifcation
                            </div>
                          )}
                        </ul>
                      </OverlayPanel>
                      <Button
                        label={<i className="pi pi-bell"></i>}
                        onClick={(e) => this.overlayPanel.toggle(e)}
                        className="notification-btn"
                      />
                    </div>
                  </div>
                </div>

                <div className="user-details p-text-right">
                  <p className="user-name">{CommonConfig.loginData().Name}</p>
                  <p className="user-time">{CommonConfig.loginData().Email}</p>
                </div>

                <div className="profile-section">
                  <div
                    className="p-d-flex p-ai-center cursor-pointer"
                    onClick={(e) => this.op.toggle(e)}
                  >
                    <div className="profile-img">
                      <img
                        src={
                          CommonConfig.isEmpty(profilePath)
                            ? userImage
                            : profilePath
                        }
                        width="150px"
                        alt="userImage"
                        onError={this.onError}
                      />

                    </div>
                  </div>
                </div>

                <div className="icon-box" onClick={(e) => this.op.toggle(e)}>
                  <i className="icon-chevron-down"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Dialog
          header="Change Password"
          visible={changePassword}
          className="dialog-popup1"
          onHide={(e) => this.onHide(e, "changePassword")}
          draggable={false}
          closable={false}
          position="top"
        >
          <div style={{ marginTop: "14px" }} className="floating-field mt-3">
            <InputText
              placeholder=" "
              className={
                "p-w-100" + (newPasswordErr ? "p-invalid block" : null)
              }
              id="userName"
              type="password"
              value={newPassword}
              onChange={(e) => this.handleCheck(e, "newPassword")}
            />
            <span class="bar"></span>
            <label htmlFor="emailAddress">New Password</label>
            {newPasswordErr ? (
              <small className="p-error block">{newPasswordErrText}</small>
            ) : null}
            <span class="bar"></span>
          </div>
          <div style={{ marginTop: "14px" }} className="floating-field mt-3">
            <InputText
              placeholder=" "
              className={
                "p-w-100" + (confirmPasswordErr ? "p-invalid block" : null)
              }
              id="userName"
              type="password"
              value={confirmPassword}
              onChange={(e) => this.handleCheck(e, "confirmPassword")}
            />
            <span class="bar"></span>
            <label htmlFor="emailAddress">Re-type New Password</label>
            {confirmPasswordErr ? (
              <small className="p-error block">{confirmPasswordErrText}</small>
            ) : null}
            <span class="bar"></span>
          </div>
          <div className="dialog-footer">
            <button
              className="btn-dialog-cancel"
              onClick={() => {
                this.setState({ changePassword: false });
              }}
            >
              cancel
            </button>
            <button
              className="btn-text-action-primary p-ml-2"
              onClick={(e) => this.changePassword(e)}
            >
              submit
            </button>
          </div>
        </Dialog>
      </div>
    );
  }
}
