import React, { useEffect, useState } from 'react';
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from 'primereact/calendar';
import api from "../../utils/apiClient";
import CommonConfig from "../../utils/constant";
import APIConstant from "../../utils/pathConstants";
import Toast from '../../components/Shared/Toast/Toast';
import Loader from '../../components/Shared/Loader/Loader';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

const ViewUserPlan = (props) => {
    const [securityUserID, setSecurityUserID] = useState(null);
    const [users, setUsers] = useState('');
    const [plan, setPlans] = useState('');
    const [userPlanList, setUserPlanList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [planLogs, setPlanLogs] = useState([]);
    const [paymentStatus, setPaymentStatus] = useState('');
    const paymentStatusList = [
        { label: 'Paid', value: 'Paid' },
        { label: 'Unpaid', value: 'Unpaid' },
    ]
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [paymentDescription, setPaymentDescription] = useState('');
    const [globalFilter, setGlobalFilter] = useState('');

    useEffect(() => {
        const loginData = CommonConfig.loginData();
        setSecurityUserID(loginData?.UserId);
        GetUserPlanList();
        getUser();
        getUserPlanDetailById(props?.location?.state?.UserID);

    }, []);

    const getUser = async (ID) => {
        try {
          let data = {
            // UserID: ID,
          }
          await api.post(APIConstant.path.getUser, data).then(async response => {
            let res = response;
            if (res.success) {
              setUserList(res.data.map((user) => ({
                ...user,
                label: user.Name,
                value: user.UserId,
              })));
              Loader.hide();
            }
          }).catch(err => {
            Loader.hide();
            console.log(err);
          });
        } catch (err) { console.log(err); }
    
      };
    const GetUserPlanList = async (ID) => {
        try {
            let data = {
                PlanId: ID,
            }
            await api.post(APIConstant.path.GetAdminPlan).then(async response => {
                let res = response;
                if (res.success) {
                    setUserPlanList(res.data.map((plan) => ({
                        ...plan,
                        label: plan.PlanName,
                        value: plan.PlanId,
                      })));
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }
    }
    const actionBodyTemplateSrNo = (data, props) => {
        return props.rowIndex + 1;
    };
    const getUserPlanDetailById = async (ID) => {
        try {
            let data = {
                UserPlanId: ID,
            }
            await api.post(APIConstant.path.GetUserPlanDetailById, data).then(async response => {
                let res = response;
                if (res.success) {
                    setUsers(res?.data[0]?.UserId);
                    setPlans(res?.data[0]?.PlanId);
                    setPaymentStatus(res?.data[0]?.PaymentStatus);
                    setStartDate(new Date(res?.data[0]?.StartDate));
                    setEndDate(new Date(res?.data[0]?.EndDate));
                    setPaymentDescription(res?.data[0]?.PaymentDesc);
                    setPlanLogs(res?.data[0]?.PlanLogs)
                    Loader.hide();
                }
            }).catch(err => {
                Loader.hide();
                console.log(err);
            });
        } catch (err) { console.log(err); }

    };


    return (
        <div className="user-container p-d-flex p-flex-column p-jc-between">
            <div>
                <div className='card-header'>
                    <div className="heading-area">
                        <i className="icon-chevron-down back-btn" onClick={() => props.navigate('/userPlanDetails')}></i>
                        <h2 className="p-mb-3 p-mb-lg-0">View User Plan</h2>
                    </div>
                </div>
                <div className='card-body'>
                    <div className="form-area">
                        <div className={"p-grid"}>
                            <div className="p-col-12 p-md-4 p-pt-2">
                                <div className="p-w-100 ">
                                    <div className="custom-inputs">
                                        <label htmlFor="users">Users <span style={{ color: 'red' }}>*</span>: </label>
                                        <Dropdown
                                            className="p-w-100 p-mt-2"
                                            optionLabel="label"
                                            value={users}
                                            options={userList}
                                            filter
                                            placeholder="Users"
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="p-col-12 p-md-4 p-pt-2">
                                <div className="p-w-100 ">
                                    <div className="custom-inputs">
                                        <label htmlFor="plan">Plan <span style={{ color: 'red' }}>*</span>: </label>
                                        <Dropdown
                                            className="p-w-100 p-mt-2"
                                            value={plan}
                                            options={userPlanList}
                                            optionLabel="label"
                                            filter
                                            placeholder="Plan"
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="p-col-12 p-md-4 p-pt-2">
                                <div className="p-w-100 ">
                                    <div className="custom-inputs">
                                        <label htmlFor="paymentStatus">Payment status <span style={{ color: 'red' }}>*</span>: </label>
                                        <Dropdown
                                            className="p-w-100 p-mt-2"
                                            value={paymentStatus}
                                            options={paymentStatusList}
                                            optionLabel="label"
                                            filter
                                            placeholder="Payment status"
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="p-col-12 p-md-4 p-pt-2">
                                <div className="p-w-100 ">
                                    <div className="custom-inputs">
                                        <label htmlFor="startDate">Start date <span style={{ color: 'red' }}>*</span>: </label>
                                        <Calendar
                                            className="p-w-100 p-mt-2"
                                            id="startDate"
                                            type="date"
                                            value={startDate}
                                            dateFormat="dd/mm/yy"
                                            showIcon
                                            placeholder='Start date'
                                           disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="p-col-12 p-md-4 p-pt-2">
                                <div className="p-w-100 ">
                                    <div className="custom-inputs">
                                        <label htmlFor="endDate">End date <span style={{ color: 'red' }}>*</span>: </label>
                                        <Calendar
                                            className="p-w-100 p-mt-2"
                                            id="endDate"
                                            type="date"
                                            value={endDate}
                                            dateFormat="dd/mm/yy"
                                            showIcon
                                            placeholder='End date'
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="p-col-12 p-md-4 p-pt-2">
                                <div className="p-w-100 ">
                                    <div className="custom-inputs">

                                        <label htmlFor="paymentDescription">Payment description <span style={{ color: 'red' }}>*</span>:</label>
                                        <InputTextarea
                                            className="p-w-100 p-mt-2"
                                            id="paymentDescription"
                                            type="text"
                                            value={paymentDescription}
                                            rows={5} cols={30}
                                            placeholder="Payment description"
                                            autoFocus={true}
                                            keyfilter={"alphanum"}
                                            maxLength={200}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="heading-area">
                <h2 className="p-mb-3 p-mb-lg-0">Update Details Log</h2>
            </div>
            <div className="user-container p-d-flex p-flex-column p-jc-between">
                <div className='card-body'>
                    <div className='report-custom-table policy-custom-table'>
                        <DataTable globalFilter={globalFilter} paginator paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[5, 10, 25, 50]} className='custom-table' value={planLogs} scrollable scrollHeight="calc(100vh - 203px)">
                            <Column field="Index" header="Sr.No" body={actionBodyTemplateSrNo} style={{ maxWidth: 150 }}></Column>
                            <Column field="PlanName" header="Plan Name" sortable></Column>
                            <Column field="StartDate" header="Start Date" sortable></Column>
                            <Column field="EndDate" header="End Date" sortable></Column>
                            <Column field="LastUpdated" header="Last updated" sortable></Column>
                            <Column field="PaymentDesc" header="Payment Description" sortable></Column>
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ViewUserPlan

