import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import api from "../../utils/apiClient";
import CommonConfig from "../../utils/constant";
import APIConstant from "../../utils/pathConstants";
import Toast from "../Shared/Toast/Toast";
import Loader from "../Shared/Loader/Loader";
import { Button } from "primereact/button";

const CurrencyMaster = () => {
  const [currencyMasterId, setCurrencyMasterId] = useState(null);

  const [currencyName, setCurrencyName] = useState("");
  const [currencyNameErr, setCurrencyNameErr] = useState(false);
  const [currencyNameErrText, setCurrencyNameErrText] = useState("");

  const [currencySymbol, setCurrencySymbol] = useState("");
  const [currencySymbolErr, setCurrencySymbolErr] = useState(false);
  const [currencySymbolErrText, setCurrencySymbolErrText] = useState("");

  const [globalFilter, setGlobalFilter] = useState("");
  const [currencyMasterList, setCurrencyMasterList] = useState([]);
  const [addCurrencyMasterModal, setAddCurrencyMasterModal] = useState(false);
  const [isDeleteCurrencyMasterModal, setDeleteCurrencyMasterModal] =
    useState(false);

  useEffect(() => {
    const loginData = CommonConfig.loginData();
    getAdminCurrencyName(loginData.UserId);
  }, []);

  const resetScreen = async () => {
    setAddCurrencyMasterModal(false);

    setCurrencyName("");
    setCurrencyNameErr(false);
    setCurrencyNameErrText("");

    setCurrencySymbol("");
    setCurrencySymbolErr(false);
    setCurrencySymbolErrText("");
  };

  const handleChange = (e, type) => {
    if (type === "currencyName") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setCurrencyName("");
        setCurrencyNameErr(true);
        setCurrencyNameErrText("Currency Type is required");
      } else {
        setCurrencyName(e.target.value);
        setCurrencyNameErr(false);
        setCurrencyNameErrText("");
      }
    }
    if (type === "currencySymbol") {
      if (CommonConfig.isEmpty(e.target.value)) {
        setCurrencySymbol("");
        setCurrencySymbolErr(true);
        setCurrencySymbolErrText("Currency Symbol is required");
      } else {
        setCurrencySymbol(e.target.value);
        setCurrencySymbolErr(false);
        setCurrencySymbolErrText("");
      }
    }
  };

  const validate = () => {
    let formIsValid = true;

    if (CommonConfig.isEmpty(currencyName)) {
      formIsValid = false;
      setCurrencyNameErr(true);
      setCurrencyNameErrText("Currency Type is required");
    } else {
      setCurrencyNameErr(false);
      setCurrencyNameErrText("");
    }
    if (CommonConfig.isEmpty(currencySymbol)) {
      formIsValid = false;
      setCurrencySymbolErr(true);
      setCurrencySymbolErrText("Currency Symbol is required");
    } else {
      setCurrencySymbolErr(false);
      setCurrencySymbolErrText("");
    }
    return formIsValid;
  };

  const AddUpdateCurrencyName = async (e) => {
    if (validate(e)) {
      try {
        let data = {
          CurrencyMasterId: currencyMasterId,
          CurrencyName: currencyName,
          CurrencySymbol: currencySymbol,
          UserId: CommonConfig.loginData().UserId,
        };

        Loader.show();
        await api
          .post(APIConstant.path.AddUpdateAdminCurrencyMaster, data)
          .then((response) => {
            let res = response;

            if (res.success) {
              Toast.success({ message: res.message });
              resetScreen();
              handleClose("AddCurrencyMaster");
              getAdminCurrencyName();
              Loader.hide();
            } else {
              Toast.error({ message: res.message });
              Loader.hide();
            }
          })
          .catch((err) => {});
      } catch (err) {
        console.log(err);
      }
    }
  };

  const getAdminCurrencyName = async (ID) => {
    try {
      let data = {};
      await api
        .post(APIConstant.path.GetAdminCurrencyMaster, data)
        .then(async (response) => {
          let res = response;
          if (res.success) {
            setCurrencyMasterList(res.data);
            Loader.hide();
          }
        })
        .catch((err) => {
          Loader.hide();
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const DeleteAdminCurrencyName = async () => {
    try {
      Loader.show();
      let data = {
        CurrencyMasterId: currencyMasterId,
      };
      await api
        .post(APIConstant.path.DeleteAdminCurrencyMaster, data)
        .then(async (res) => {
          if (res.success) {
            handleClose("DeleteCurrencyMaster");
            getAdminCurrencyName();
            setCurrencyMasterId();
            Loader.hide();
          } else {
            Loader.hide();
          }
        })
        .catch((err) => {
          Loader.hide();
          Toast.error({ message: err });
        });
    } catch (err) {
      Loader.hide();
      Toast.error({ message: err });
    }
  };

  const handleClose = async (type) => {
    if (type === "AddCurrencyMaster") {
      setAddCurrencyMasterModal(false);
      setCurrencyMasterId("");

      setCurrencyName("");
      setCurrencyNameErr(false);
      setCurrencyNameErrText("");

      setCurrencySymbol("");
      setCurrencySymbolErr(false);
      setCurrencySymbolErrText("");
    } else if (type === "DeleteCurrencyMaster") {
      setCurrencyMasterId(null);
      setDeleteCurrencyMasterModal(false);
    }
  };

  const handleOpen = (item, type) => {
    if (type === "AddCurrencyMaster") {
      setAddCurrencyMasterModal(true);
    } else if (type === "EditCurrencyName") {
      setCurrencyMasterId(item._id);
      setCurrencyName(item.CurrencyName);
      setCurrencySymbol(item.CurrencySymbol);
      setAddCurrencyMasterModal(true);
    } else if (type === "DeleteCurrencyMaster") {
      setCurrencyMasterId(item._id);
      setDeleteCurrencyMasterModal(true);
    }
  };

  const actionBodyTemplateSrNo = (data, props) => {
    return props.rowIndex + 1;
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="table-icon">
        <Button
          className=""
          onClick={() => handleOpen(rowData, "EditCurrencyName")}
          tooltip={"Edit"}
          tooltipOptions={{ className: "bluegray-tooltip", position: "top" }}
        >
          <i className="icon-edit"></i>
        </Button>

        <Button
          className=""
          onClick={() => handleOpen(rowData, "DeleteCurrencyMaster")}
          tooltip={"Delete"}
          tooltipOptions={{ className: "bluegray-tooltip", position: "top" }}
        >
          <i className="icon-delete"></i>
        </Button>
      </div>
    );
  };

  return (
    <div className="user-container">
      <div className="card-header">
        <div className="card-title p-d-lg-flex p-ai-center p-w-100">
          <h3 className="p-mb-3 p-mb-lg-0">View Currency</h3>
          <div className="p-ml-auto p-d-lg-flex p-ai-center">
            <span className="searchbar-area">
              <InputText
                type="search"
                onChange={(e) => setGlobalFilter(e.target.value)}
                placeholder="Global Search"
                size="30"
              />
              <i className="icon-search"></i>
            </span>
          </div>
          <div className="card-actions p-ml-2">
            <Button
              type="button"
              className="add-btn add-btn p-button p-component p-jc-center p-w-100"
              onClick={(e) => handleOpen(e, "AddCurrencyMaster")}
            >
              <i className="icon-add"></i>Add Currency
            </Button>
          </div>
        </div>
      </div>
      <div className="card">
        <DataTable
          globalFilter={globalFilter}
          paginator
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          className="custom-table"
          value={currencyMasterList}
          scrollable
          scrollHeight="calc(100vh - 203px)"
        >
          <Column
            field="Index"
            header="Sr.No"
            body={actionBodyTemplateSrNo}
            style={{ maxWidth: 150 }}
          ></Column>
          <Column
            field="CurrencyName"
            header="Currency"
            style={{ width: "25%" }}
          ></Column>
          <Column
            field="CurrencySymbol"
            header="Currency Symbol"
            style={{ width: "25%" }}
          ></Column>
          <Column
            field="Status"
            header="Status"
            style={{ width: "25%" }}
          ></Column>
          <Column header="Action" body={actionBodyTemplate}></Column>
        </DataTable>
      </div>

      {/* Add Confirm Modal */}
      <Dialog
        header={currencyMasterId ? "Edit Currency " : "Add Currency "}
        visible={addCurrencyMasterModal}
        className="dialog-popup"
        onHide={(e) => handleClose("AddCurrencyMaster")}
        draggable={false}
        closable={false}
        position="top"
      >
        <div>
          <div className="card-body">
            <div className="form-area">
              <div className={"p-grid"}>
                <div className="p-col-12 p-md-4 p-pt-2">
                  <div className="p-w-100 ">
                    <div className="custom-inputs">
                      <label htmlFor="currencyName">
                        Currency name <span style={{ color: "red" }}>*</span>:{" "}
                      </label>
                      <InputText
                        className="p-w-100 p-mt-2"
                        id="currencyName"
                        type="text"
                        placeholder="Currency name"
                        value={currencyName}
                        autoFocus={true}
                        onChange={(e) => handleChange(e, "currencyName")}
                        maxLength={30}
                      />
                    </div>
                    <span className="error-msg" style={{ color: "red" }}>
                      {currencyNameErr ? currencyNameErrText : null}
                    </span>
                  </div>
                </div>

                <div className="p-col-12 p-md-4 p-pt-2">
                  <div className="p-w-100 ">
                    <div className="custom-inputs">
                      <label htmlFor="currencySymbol">
                        Currency Symbol<span style={{ color: "red" }}>*</span>:{" "}
                      </label>
                      <InputText
                        className="p-w-100 p-mt-2"
                        id="currencySymbol"
                        type="text"
                        placeholder="Currency Symbol"
                        value={currencySymbol}
                        autoFocus={true}
                        onChange={(e) => handleChange(e, "currencySymbol")}
                        maxLength={30}
                      />
                    </div>
                    <span className="error-msg" style={{ color: "red" }}>
                      {currencySymbolErr ? currencySymbolErrText : null}
                    </span>
                  </div>
                </div>

                <div className="card-footer">
                  <Button
                    label="Submit"
                    severity="secondary"
                    raised
                    onClick={(e) => AddUpdateCurrencyName(e)}
                  />
                  <button
                    className="btn-dialog-cancel"
                    onClick={(e) => handleClose("AddCurrencyMaster")}
                  >
                    {" "}
                    cancel{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>

      {/* Delete Confirm Modal */}
      <Dialog
        header="Delete  Currency"
        visible={isDeleteCurrencyMasterModal}
        className="dialog-popup"
        onHide={(e) => handleClose("DeleteCurrencyMaster")}
        draggable={false}
        closable={false}
        position="top"
      >
        <span>Are you sure want to delete the Currency ?</span>
        <div className="dialog-footer">
          <button
            className="btn-dialog-cancel"
            onClick={(e) => handleClose("DeleteCurrencyMaster")}
          >
            {" "}
            cancel{" "}
          </button>
          <button
            className="btn-dialog-delete"
            onClick={() => DeleteAdminCurrencyName()}
          >
            {" "}
            Delete{" "}
          </button>
        </div>
      </Dialog>
    </div>
  );
};

export default CurrencyMaster;
