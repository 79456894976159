import Login from "./components/Login/Login.js";
import Plan from "./components/Plan/Plan.js";
import CreatePlan from "./components/Plan/CreatePlan.js";
import Error404 from "./components/Shared/Error404/Error404.js";
import Dashboard from "./components/Dashboard/Dashboard.js";
import Users from "./components/Users/Users.js";
import AddUser from "./components/Users/AddUser.js";
import UserPlanDetails from "./components/UserPlanDetails/UserPlanDetails.js";
import ViewUserPlan from "./components/UserPlanDetails/ViewUserPlan.js";
import EditUserPlan from "./components/UserPlanDetails/EditUserPlan.js";
import UserType from "./components/Master/UserType.js";
import CompanyMaster from "./components/CompanyMaster/CompanyMaster.js";
import AddCompanyMaster from "./components/CompanyMaster/AddCompanyMaster.js";
import CountryMaster from "./components/Master/CountryMaster.js";
import CurrencyMaster from "./components/Master/CurrencyMaster.js";
import ManageAccess from "./components/ManageAccess/ManageAccess.js";
import Service from "./components/Service/Service.js";
import AccessMaster from "./components/Service/AccessMaster.js";
import SubAccessMaster from "./components/Service/SubAccessMaster.js";
import SubService from "./components/Service/SubService.js";
import Item from "./components/Master/Item.js";

var dashRoutes = [
  /** Add all authentication routing (not required session) here */
  {
    path: "/",
    name: "Login",
    component: Login,
    icon: "",
    invisible: false,
    meta: {
      title: "Portlink-Admin | Login",
    },
    isPreventedRoute: true,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    icon: "",
    invisible: false,
    meta: {
      title: "Portlink-Admin | Login",
    },
    isPreventedRoute: true,
  },
  /** Add all protected routing (requires session) here */
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    icon: "",
    invisible: false,
    meta: {
      title: "Portlink-Admin | Dashboard",
    },
    isPreventedRoute: false,
  },
  {
    path: "/users",
    breadCrumb: "Users",
    name: "Users",
    component: Users,
    icon: "",
    invisible: false,
    meta: {
      title: "Portlink-Admin | Users",
    },
    isPreventedRoute: false,
  },
  {
    path: "/add-user",
    breadCrumb: "AddUser",
    name: "AddUser",
    component: AddUser,
    icon: "",
    invisible: false,
    meta: {
      title: "Portlink-Admin | AddUser",
    },
    isPreventedRoute: false,
  },
  {
    path: "/plan",
    breadCrumb: "Plan",
    name: "Plan",
    component: Plan,
    icon: "",
    invisible: false,
    meta: {
      title: "Portlink-Admin | Plan",
    },
    isPreventedRoute: false,
  },
  {
    path: "/create-plan",
    breadCrumb: "CreatePlan",
    name: "CreatePlan",
    component: CreatePlan,
    icon: "",
    invisible: false,
    meta: {
      title: "Portlink-Admin | CreatePlan",
    },
    isPreventedRoute: false,
  },
  {
    path: "/userPlanDetails",
    name: "UserPlanDetails",
    component: UserPlanDetails,
    icon: "",
    invisible: false,
    meta: {
      title: "Portlink-Admin | UserPlanDetails",
    },
    isPreventedRoute: false,
  },
  {
    path: "/viewUserPlan",
    name: "ViewUserPlan",
    component: ViewUserPlan,
    icon: "",
    invisible: false,
    meta: {
      title: "Portlink-Admin | ViewUserPlan",
    },
    isPreventedRoute: false,
  },
  {
    path: "/editUserPlan",
    name: "EditUserPlan",
    component: EditUserPlan,
    icon: "",
    invisible: false,
    meta: {
      title: "Portlink-Admin | EditUserPlan",
    },
    isPreventedRoute: false,
  },
  {
    path: "/usertype",
    name: "UserType",
    component: UserType,
    icon: "",
    invisible: false,
    meta: {
      title: "Portlink-Admin | UserType",
    },
    isPreventedRoute: false,
  },
  {
    path: "/company-master",
    name: "CompanyMaster",
    component: CompanyMaster,
    icon: "",
    invisible: false,
    meta: {
      title: "Portlink-Admin | CompanyMaster",
    },
    isPreventedRoute: false,
  },
  {
    path: "/currency-master",
    name: "CurrencyMaster",
    component: CurrencyMaster,
    icon: "",
    invisible: false,
    meta: {
      title: "Portlink-Admin | CurrencyMaster",
    },
    isPreventedRoute: false,
  },
  {
    path: "/CountryMaster",
    name: "CountryMaster",
    component: CountryMaster,
    icon: "",
    invisible: false,
    meta: {
      title: "Portlink-Admin | CountryMaster",
    },
    isPreventedRoute: false,
  },
  {
    path: "/add-company-master",
    name: "AddCompanyMaster",
    component: AddCompanyMaster,
    icon: "",
    invisible: false,
    meta: {
      title: "Portlink-Admin | AddCompanyMaster",
    },
    isPreventedRoute: false,
  },
  {
    path: "/manage-access",
    breadCrumb: "Manage Access",
    name: "ManageAccess",
    component: ManageAccess,
    icon: "",
    invisible: false,
    meta: {
      title: "Portlink-Admin | Manage Access",
    },
    isPreventedRoute: false,
  },
  {
    path: "/404Error",
    name: "Error404",
    component: Error404,
    icon: "",
    invisible: false,
    meta: {
      title: "Page not found",
    },
    isShared: true,
  },
  {
    path: "/service",
    breadCrumb: "Service",
    name: "Service",
    component: Service,
    icon: "",
    invisible: false,
    meta: {
      title: "Portlink-Admin | Service",
    },
    isPreventedRoute: false,
  },
  {
    path: "/AccessMaster",
    breadCrumb: "AccessMaster",
    name: "AccessMaster",
    component: AccessMaster,
    icon: "",
    invisible: false,
    meta: {
      title: "Portlink-Admin | AccessMaster",
    },
    isPreventedRoute: false,
  },
  {
    path: "/subAccessMaster",
    breadCrumb: "SubAccessMaster",
    name: "SubAccessMaster",
    component: SubAccessMaster,
    icon: "",
    invisible: false,
    meta: {
      title: "Portlink-Admin | SubAccessMaster",
    },
    isPreventedRoute: false,
  },
  {
    path: "/subservice",
    breadCrumb: "SubService",
    name: "SubService",
    component: SubService,
    icon: "",
    invisible: false,
    meta: {
      title: "Portlink-Admin | SubService",
    },
    isPreventedRoute: false,
  },
  {
    path: "/item-and-goods",
    name: "Item & Goods",
    component: Item,
    icon: "",
    invisible: false,
    meta: {
      title: "Portlink-Admin | Item & Goods",
    },
    isPreventedRoute: false,
  },
];

export default dashRoutes;
