import React from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import FallbackComponent from './components/Shared/FallbackComponent/FallbackComponent';
import PreventedRoute from "./layouts/PreventedRoute";
import ProtectedRoute from "./layouts/ProtectedRoute";
import routes from "./routes";
import Error404 from "./components/Shared/Error404/Error404";
import Sidebar from "./components/Sidebar/Sidebar";
import Header from "./components/Header/Header";

const Router = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <React.Suspense fallback={<FallbackComponent />}>
      <Routes>
        {routes
          ?.filter((x) => !x.isShared)
          .map((x, idx) => {
            return (
              <Route
                key={idx}
                path={x.path}
                element={
                  x.isPreventedRoute ? (
                    <PreventedRoute meta={x.meta}>
                      <div id="main-section" className="main-section">
                        <x.component navigate={navigate} replace={true} />
                      </div>
                    </PreventedRoute>
                  ) : (
                    <ProtectedRoute meta={x.meta}>
                      <div id="main-section" className="dashboard-container">
                        <Sidebar {...props} navigate={navigate} />
                        <div
                          id="dashboard-area"
                          className="dashboard-right-side"
                        >
                          <Header {...props} navigate={navigate} />
                          <div className="dashboard-body">
                            <x.component
                              {...props}
                              navigate={navigate}
                              replace={true}
                              location={location}
                            />
                          </div>
                        </div>
                      </div>
                    </ProtectedRoute>
                  )
                }
              />
            );
          })}
        <Route path="*" element={<Error404 navigate={navigate} />} />
      </Routes>
    </React.Suspense>
  );
};

export default Router;
